//style
import "./TermsAndConditionsStyles.scss"
//packages
import React from "react";
import { connect } from "react-redux";
//utils
import { metadataTermsAndConditions } from "../../../utils/pagesMetadata/metadataTermsAndConditions";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT } from "../../../context/actions";
//components
import { Link } from "react-router-dom";
import { SeoHelmet } from "../../general/SeoHelmet";

export function TermsAndConditions(props) {
    function TermsContent() {

        const handleContactUsClick = () => {
            if (process.env.REACT_APP_ENV === 'production')
                window?.eventTriggerContactUs()
        }

        return (
            <div className="terms-content">
                <b>
                    Effective Date: {new Date().toISOString().split('T')[0].slice(-10).replace('-', '/')}
                </b>
                <p>
                    These Terms and Conditions ("Terms") govern your use of the Melodystats website ("Service") provided by Melodystats ("Company"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not access or use the Service.
                </p>

                <div className="heading">
                    1. Description of Service
                </div>
                <p>
                    Melodystats is a service that provides users with statistical information and analysis related to music on the Spotify platform. The Service collects and processes publicly available data from Spotify's APIs to generate insights and reports for users.
                </p>

                <div className="heading">
                    2. User Obligations
                </div>
                <p>
                    a. Eligibility: By using the Service, you represent and warrant that you are at least 13 years of age or have obtained parental consent to use the Service.
                </p>
                <p>
                    b. Account: You may be required to create an account to access certain features of the Service. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account. You agree to provide accurate and up-to-date information during the registration process.
                </p>
                <p>
                    c. Compliance with Laws: You agree to use the Service in compliance with all applicable laws, regulations, and third-party agreements.
                </p>

                <div className="heading">
                    3. Intellectual Property
                </div>
                <p>
                    a. Ownership: The Service, including its content, features, and functionality, is owned by Melodystats and protected by intellectual property laws. You acknowledge that all rights, title, and interest in the Service are and will remain the exclusive property of Melodystats.
                </p>
                <p>
                    b. Use of Service: Melodystats grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Service for personal, non-commercial purposes. You may not copy, modify, distribute, sell, lease, or reverse engineer any part of the Service without prior written consent from Melodystats.
                </p>
                <p>
                    c. Trademarks: The Melodystats name, logo, and any other trademarks or service marks displayed on the Service are the property of Melodystats. You may not use these trademarks without the prior written permission of Melodystats.
                </p>

                <div className="heading">
                    4. Privacy
                </div>
                <p>
                    Your privacy is important to us. Please review our <Link to='/privacy-policy'>Privacy Policy</Link> to understand how we collect, use, and protect your personal information.
                </p>

                <div className="heading">
                    5. Limitation of Liability
                </div>
                <p>
                    You acknowledge and agree that the use of the service is at your own risk. Melodystats shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of the Service. This includes, but is not limited to, any errors, omissions, or inaccuracies in the content or functionality of the Service.
                </p>

                <div className="heading">
                    6. Termination
                </div>
                <p>
                    Melodystats reserves the right to suspend or terminate your access to the Service at any time, without prior notice or liability, for any reason. Upon termination, all provisions of these Terms which by their nature should survive, including, without limitation, ownership provisions, warranty disclaimers, and limitations of liability, shall remain in effect.
                </p>

                <div className="heading">
                    7. Modifications
                </div>
                <p>
                    Melodystats may update or modify these Terms from time to time. Any changes will be effective upon posting the updated Terms on the Service. Your continued use of the Service after the posting of any modifications constitutes your acceptance of the revised Terms.
                </p>

                <div className="heading">
                    8. Governing Law
                </div>
                <p>
                    These Terms shall be governed by and construed in accordance with the laws of Ukraine. Any legal actions or proceedings arising out of or relating to these Terms shall be exclusively subject to the jurisdiction of the courts in Ukraine.
                </p>

                <div className="heading">
                    9. Contact Us
                </div>
                <p>
                    If you have any questions or concerns about these Terms, please contact us at <a onClick={() => handleContactUsClick()} href="mailto:contact@melodystats.me" target="_blank" rel="noopener noreferrer">contact@melodystats.me</a>.
                </p>
                <p>
                    By using the Melodystats Service, you acknowledge that you have read, understood, and agreed to be bound by these Terms and Conditions.
                </p>
                <b>
                    Last updated: {new Date('05/25/2023').toISOString().split('T')[0].slice(-10).replace('-', '/')}
                </b>
            </div>
        )
    }

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataTermsAndConditions

    return (
        <div className="terms-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            <div className="terms-block">
                <h1 className="page_title">Terms and Conditions</h1>

                <div>
                    {
                        props.windowWidth !== null ?
                            <div>
                                {
                                    props.windowWidth < 1000 ?
                                        <div className="mobile">
                                            <TermsContent />
                                        </div>
                                        :
                                        <div className="desktop">
                                            <TermsContent />
                                        </div>
                                }
                            </div>
                            :
                            null
                    }
                </div>

            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT };

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
