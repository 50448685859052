import React from 'react';
import { Helmet } from 'react-helmet';

export const SeoHelmet = ({ title, description, schema = null }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            {
                schema ?
                    <script type="application/ld+json">
                        {JSON.stringify(schema)}
                    </script>
                    : null
            }
        </Helmet>
    );
};