//style
import "./HomeStyles.scss"
//packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//utils
import { metadataHome } from "../../../utils/pagesMetadata/metadataHome";
import { handleErrorMessage } from "../../../utils/errorMessageHandler";
import { refreshPage, useNavigateHome } from "../../../utils/pageHandlers";
import { printData } from "../../../utils/consoleHelpers";
//components
import Loading from "../../common/Loading/Loading";
import Footer from "../../common/Footer/Footer";
import { Link } from "react-router-dom";
import LoginButton from "../../common/LoginButton/LoginButton";
import { SeoHelmet } from "../../general/SeoHelmet";
//services
import { getRandomVerict } from "../../../services/appService";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_USER } from "../../../context/actions";
//assets
import statsIcon from "../../../assets/emojis/32/stats.png"
import letterIcon from "../../../assets/emojis/64/incoming_letter.png"
import toolsIcon from "../../../assets/emojis/32/tools.png"
import { ProfileEmptyPhoto } from "../../../assets/special/profileEmptyPhoto";
import { hex } from "../Palette/Palette";
import melodystatsLogoPNG from "../../../assets/branding/melodystats-logo-round.png"
import melodystatsLogoWEBP from "../../../assets/branding/melodystats-logo-round.webp"
import { highestQualityImageFinder } from "../../../helpers/highestQualityImageFinder";
import { useProfile } from "./hooks";

function Home(props) {
    const navigateHome = useNavigateHome();

    const [verdict, setVerdict] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [isNotEnoughData, setNotEnoughData] = useState(false);

    useEffect(() => {
        if (props.authentication_key && !props.keyNeedToUpdate) {
            getRandomVerict(props.authentication_key)
                .then(response => {
                    printData("Home", response)
                    if (response?.body.result)
                        setVerdict(response)
                    else
                        setNotEnoughData(true)
                    setLoaded(true)
                }).catch((error) => {
                    error.message = handleErrorMessage(error)
                    switch (error.message) {
                        case 401: {
                            props.KEY_NEED_TO_UPDATE();
                            break;
                        }
                        case 403: {
                            props.LOGOUT();
                            break;
                        }
                        case 429: {
                            refreshPage()
                            break;
                        }
                        default: {
                            navigateHome()
                            break;
                        }
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.authentication_key, props.term])

    const { profile, profileLoaded } = useProfile(props)
    const [greetingIndex, setGreetingIndex] = useState(null);
    useEffect(() => {
        if (profile && profileLoaded)
            setGreetingIndex(Math.ceil(Math.random() * 5) - 1)
    }, [profile, profileLoaded])

    const Verdict = () => {

        const routes = {
            'FESTIVAL_POSTER': '/festival-poster',
            'AGES': '/ages-of-playlist',
            'GENRES': '/genres',
            'MOOD_COLOR': '/mood-palette',
            'NO_RESULTS': '/',
        }

        const routeDecscription = {
            'FESTIVAL_POSTER': 'Music festival poster',
            'AGES': 'Ages of your playlist',
            'GENRES': 'Most popular genres',
            'MOOD_COLOR': 'Mood palette',
            'NO_RESULTS': '',
        }

        const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataHome

        return (
            <>
                <SeoHelmet
                    title={seoTitle}
                    description={seoDescription}
                    schema={seoSchema}
                />
                {
                    !isNotEnoughData ?
                        verdict?.type && verdict.type !== 'NO_RESULTS' ?
                            <>
                                <div className="verdict-content">
                                    {
                                        verdict.type === 'FESTIVAL_POSTER' && verdict.body.result ?
                                            <p>
                                                <span>
                                                    {/* {verdict?.body?.headliners?.join(', ')} */}
                                                    {
                                                        verdict?.body?.headliners?.map((e, i) => {
                                                            return (
                                                                <a
                                                                    href={`${e?.external_urls?.spotify}?si`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    key={e.id}
                                                                >
                                                                    {e.name}{i + 1 < verdict?.body?.headliners.length ? ',' : ''}&nbsp;
                                                                </a>
                                                            )
                                                        })
                                                    }
                                                </span>{verdict?.body?.headliners?.length > 1 ? 'are' : 'is'} headliner{verdict?.body?.headliners?.length > 1 ? 's' : ''} in your music festival poster.
                                                Navigate to see whole poster
                                            </p>
                                            : null
                                    }
                                    {
                                        verdict.type === 'AGES' ?
                                            <p>
                                                <span>{verdict?.body?.percentage}%</span> of your playlist is from {verdict?.body?.decade}'s.
                                                Navigate to see from which ages your playlist consist
                                            </p>
                                            : null
                                    }
                                    {
                                        verdict.type === 'GENRES' ?
                                            <p>
                                                It seems that your mood genre is <span>{verdict?.body?.genre}</span>, with primary influence from
                                                <a
                                                    href={`${verdict?.body?.verdict?.external_urls?.spotify}?si`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <span>&nbsp;{verdict?.body?.verdict?.name}</span>
                                                </a>.
                                                Navigate to find infografic of your genres
                                            </p>
                                            : null
                                    }
                                    {
                                        verdict.type === 'MOOD_COLOR' && verdict?.body?.color?.length ?
                                            <p>
                                                <span style={verdict?.body.color[0] ? { color: hex[verdict?.body.color[0]] } : {}}>{verdict?.body.color[0]}</span>{verdict?.body.color[1] ? ' and ' : ''}<span style={verdict?.body.color[1] ? { color: hex[verdict?.body.color[1]] } : {}}>{verdict?.body.color[1] ? verdict?.body.color[1] : ''}</span> {verdict?.body?.color?.length > 1 ? 'are' : 'is'} your mood colors.
                                                Navigate to discover what they mean
                                            </p>
                                            : null
                                    }
                                </div>
                                <Link to={routes[verdict.type] || '/'} className="verdict-link">
                                    <p>{routeDecscription[verdict.type] || '/'}</p>
                                </Link>
                            </>
                            :
                            null
                        :
                        null
                }
            </>
        )
    }

    const Greeting = () => {
        const greetings = [
            'Embrace the symphony like never before',
            'Experience the joy of personalized discovery',
            'Let the melodies of insights guide you',
            'Welcome to the beat',
            'Get ready to be amazed'
        ]

        return (
            <>
                {
                    greetingIndex !== null ?
                        <h1 className="page_title">{`${greetings[greetingIndex]}${profileLoaded && profile?.display_name ? `, ${profile.display_name}!` : '!'}`}</h1>
                        :
                        null
                }
            </>
        )
    }

    return (
        <div className="home-page">
            {
                props.authentication_key ?
                    <div className="home-block">
                        <Greeting />
                        <div className={`loading-able ${loaded && profileLoaded ? "" : "loading-able-blur"}`}>
                            {
                                props.windowWidth !== null && loaded ?
                                    <div>
                                        {
                                            props.windowWidth < 1000 ?
                                                <div className="mobile">
                                                    <div className="photo">
                                                        {
                                                            profile?.images?.length
                                                                ?
                                                                <img className="avatar" src={profile?.images ? highestQualityImageFinder(profile.images) : ''} alt="" />
                                                                :
                                                                <ProfileEmptyPhoto size={150} />
                                                        }
                                                    </div>

                                                    <Verdict />

                                                    <Link to='/your-melodystats' className="default-link">
                                                        <picture>
                                                            <source srcSet={melodystatsLogoWEBP} type="image/webp" />
                                                            <source srcSet={melodystatsLogoPNG} type="image/png" />
                                                            <img
                                                                src={melodystatsLogoPNG}
                                                                alt=""
                                                                width="40px"
                                                                height="40px"
                                                            />
                                                        </picture>
                                                        <p>See your Melodystats</p>
                                                    </Link>

                                                </div>
                                                :
                                                <div className="desktop">
                                                    <div className="photo">
                                                        {
                                                            profile?.images?.length
                                                                ?
                                                                <img className="avatar" src={profile?.images ? highestQualityImageFinder(profile.images) : ''} alt="" />
                                                                :
                                                                <ProfileEmptyPhoto size={300} />
                                                        }
                                                    </div>

                                                    <Verdict />


                                                    <Link to='/your-melodystats' className="default-link">
                                                        <picture>
                                                            <source srcSet={melodystatsLogoWEBP} type="image/webp" />
                                                            <source srcSet={melodystatsLogoPNG} type="image/png" />
                                                            <img
                                                                src={melodystatsLogoPNG}
                                                                alt=""
                                                                width="40px"
                                                                height="40px"
                                                            />
                                                        </picture>
                                                        <p>See your Melodystats</p>
                                                    </Link>
                                                </div>
                                        }
                                    </div>
                                    :
                                    <Loading />
                            }
                        </div>
                    </div>
                    :
                    <div className="home-login">
                        {
                            props.windowWidth !== null ?
                                <div>
                                    {
                                        props.windowWidth < 1000 ?
                                            <div className="mobile">
                                                <div className="text-part">
                                                    <h1>All Your Spotify statistics and insights in one place</h1>
                                                    <h2 className="cta">See Your Spotify Stats: Top songs, artists, genres & more. Craft festival poster & iceberg</h2>
                                                    <LoginButton />
                                                </div>
                                            </div>
                                            :
                                            <div className="desktop">
                                                <div className="text-part">
                                                    <h1>All Your Spotify statistics and insights in one place</h1>
                                                    <h2 className="cta">See Your Spotify Stats: Top songs, artists, genres & more. Craft festival poster & iceberg</h2>
                                                    <LoginButton />
                                                </div>
                                                <div className="features-list">
                                                    <div className="feature-card">
                                                        <div className="card-content">
                                                            <p className="card-heading">See your statistics</p>
                                                            <img
                                                                src={statsIcon}
                                                                alt=""
                                                                className="small_emoji"
                                                                width="42px"
                                                                height="42px"
                                                            />
                                                            <p className="card-description">Your top artists, tracks, genres and other</p>
                                                        </div>
                                                    </div>
                                                    <div className="feature-card">
                                                        <div className="card-content">
                                                            <p className="card-heading">Share your insights</p>
                                                            <img
                                                                src={letterIcon}
                                                                alt=""
                                                                className="big_emoji"
                                                                width="64px"
                                                                height="64px"
                                                            />
                                                            <p className="card-description">Spread your stats with friends!</p>
                                                        </div>
                                                    </div>
                                                    <div className="feature-card">
                                                        <div className="card-content">
                                                            <p className="card-heading">Try different tools</p>
                                                            <img
                                                                src={toolsIcon}
                                                                alt=""
                                                                className="small_emoji"
                                                                width="42px"
                                                                height="42px"
                                                            />
                                                            <p className="card-description">Look on your playlist otherwise</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                                :
                                null
                        }
                    </div>
            }
            {
                props.authentication_key
                    ?
                    loaded
                        ?
                        <Footer />
                        :
                        null
                    :
                    props.windowWidth !== null
                        ?
                        <Footer />
                        :
                        null
            }
        </div>
    );
}



const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        windowWidth: state.windowWidth,
        term: state.term,
        user: state.user,
        userUpdatedTime: state.userUpdatedTime
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_USER };

export default connect(mapStateToProps, mapDispatchToProps)(Home);