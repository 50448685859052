const freshmansList = [
    'Oleksandr Korchinsky',
    'Ivan Osipchyk',
]
const freshmanSubscription = {
    name: 'Freshman',
    color: '#1DB954',
    patrons: freshmansList
}


const producersList = [

]
const producerSubscription = {
    name: 'Producer',
    color: '#C40233',
    patrons: producersList
}


const labelsList = [

]
const labelSubscription = {
    name: 'Label',
    color: '#563FAC',
    patrons: labelsList
}




export const patreonSubscriptions = [
    freshmanSubscription,
    /* producerSubscription,
    labelSubscription, */
]