import axios from "axios";

const apiUrlSpotify = "https://api.spotify.com/v1"

const reqBody = (methodType, authentication_key) => {
    return { method: methodType, headers: { "Authorization": `Bearer ${authentication_key}` } }
}


export async function GET_USER(authentication_key) {
    const promise = axios.get(`${apiUrlSpotify}/me`, reqBody("GET", authentication_key));
    const dataPromise = promise.then((response) => response);
    return dataPromise;
}


export async function GET_ARTISTS(authentication_key, term, limit) {
    const promise = axios.get(`${apiUrlSpotify}/me/top/artists?limit=${limit}&time_range=${term}`, reqBody("GET", authentication_key));
    const dataPromise = promise.then((response) => response);
    return dataPromise;
}


export async function GET_TRACKS(authentication_key, term, limit) {
    const promise = axios.get(`${apiUrlSpotify}/me/top/tracks?limit=${limit}&time_range=${term}`, reqBody("GET", authentication_key));
    const dataPromise = promise.then((response) => response);
    return dataPromise;
}


export async function GET_AUDIO_FEATURES(authentication_key, ids) {
    const promise = axios.get(`${apiUrlSpotify}/audio-features?ids=${ids}`, reqBody("GET", authentication_key));
    const dataPromise = promise.then((response) => response);
    return dataPromise;
}


export async function GET_RECOMMENDATIONS(authentication_key, limit, query) {
    if (query.result) {
        let url = `${apiUrlSpotify}/recommendations?limit=${limit}&seed_tracks=${query.seed_tracks}`;

        if (query.seed_artists) {
            url += "&seed_artists=" + query.seed_artists;
        }
        if (query.seed_genres) {
            url += "&seed_genres=" + query.seed_genres;
        }
        const promise = axios.get(url, reqBody("GET", authentication_key));
        const dataPromise = promise.then((response) => response);
        return dataPromise;
    } else {
        return {
            status: 200,
            recommendationEmpty: true
        }
    }
}


export async function GET_RECENTLY_PLAYED(authentication_key, limit) {
    const promise = axios.get(`${apiUrlSpotify}/me/player/recently-played?limit=${limit}`, reqBody("GET", authentication_key));
    const dataPromise = promise.then((response) => response);
    return dataPromise;
}


export async function GET_QUEUE(authentication_key) {
    const promise = axios.get(`${apiUrlSpotify}/me/player/queue`, reqBody("GET", authentication_key));
    const dataPromise = promise.then((response) => response);
    return dataPromise;
}