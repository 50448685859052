//style
import "./ArtistsStyle.scss"
//packages
import React from "react";
import { connect } from "react-redux";
//utils
import { metadataArtists } from "../../../utils/pagesMetadata/metadataArtists";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS } from "../../../context/actions";
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import TermSwitcher from "../../common/TermSwitcher/TermSwitcher";
import Loading from "../../common/Loading/Loading";
import NotEnoughData from "../../common/NotEnoughData/NotEnoughData";
import { SeoHelmet } from "../../general/SeoHelmet";
import { useArtists } from "./hooks";
//assets
import ArtistsList from "../../components/ArtistsList/ArtistsList";

export function Artists(props) {
    const { artistsArray: artists, loaded, isNotEnoughData } = useArtists(props)
    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataArtists

    return (
        <div className="artists-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="artists-block">
                        <h1 className="page_title">Your top artists</h1>
                        <TermSwitcher />
                        {
                            loaded ?
                                <div className={`loading-able ${loaded ? "" : "loading-able-blur"}`}>
                                    {
                                        !isNotEnoughData ?
                                            <ArtistsList artists={artists} />
                                            :
                                            <NotEnoughData />
                                    }
                                </div>
                                :
                                <Loading />
                        }
                    </div>
                    :
                    <LoginWindow />
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth,
        artists: state.artists,
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS };

export default connect(mapStateToProps, mapDispatchToProps)(Artists);
