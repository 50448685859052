export const termList = [
    {
        termValue: "short_term",
        termName: "Last 4 weeks"
    },

    {
        termValue: "medium_term",
        termName: "Last 6 months"
    },

    {
        termValue: "long_term",
        termName: "All time"
    }
];