//style
import "./ProfileStyles.scss"
//packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//utils
import { metadataProfile } from "../../../utils/pagesMetadata/metadataProfile";
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import Loading from "../../common/Loading/Loading";
import Footer from "../../common/Footer/Footer";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_USER } from "../../../context/actions";
//assets 
import { ProfileEmptyPhoto } from "../../../assets/special/profileEmptyPhoto";
import { highestQualityImageFinder } from "../../../helpers/highestQualityImageFinder";
import { SeoHelmet } from "../../general/SeoHelmet";
import { useProfile } from "./hooks";

function Profile(props) {

    const { profile, loaded } = useProfile(props)

    const handleLogout = () => {
        props.LOGOUT();
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerLogout();
    };

    const Snackbar = ({ message, onClose }) => {
        useEffect(() => {
            if (message) {

                const timer = setTimeout(() => {
                    onClose && onClose();
                }, 2000);

                return () => clearTimeout(timer);
            }
        }, [message, onClose]);

        const handleClose = () => {
            onClose && onClose();
        };

        return (
            <>
                {
                    message && (
                        <div className='snackbar' onClick={handleClose}>
                            <p>&nbsp;email copied to clipboard!</p>
                        </div>
                    )
                }
            </>
        );
    };

    const [message, setMessage] = useState(false);

    const handleEmailCopyClick = (e) => {
        e.preventDefault()
        navigator.clipboard.writeText(profile?.email)
        setMessage(true);
    };

    const handleCloseSnackbar = () => {
        setMessage(false);
    };

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataProfile

    const handleReportTheProblem = () => {
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerReportIssue()
    }

    return (
        <div className="profile-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="profile-block">
                        <h1 className="page_title">Your Profile</h1>
                        {
                            loaded ?
                                <div className={`loading-able ${loaded ? "" : "loading-able-blur"}`}>
                                    {
                                        props.windowWidth !== null && profile ?
                                            <>
                                                {
                                                    props.windowWidth < 1000 ?
                                                        <div className="mobile">
                                                            <div className="main-content">
                                                                <div className="photo">
                                                                    {
                                                                        profile?.images?.length
                                                                            ?
                                                                            <img className="avatar" src={profile?.images ? highestQualityImageFinder(profile?.images) : ''} alt="" />
                                                                            :
                                                                            <ProfileEmptyPhoto size={150} />
                                                                    }
                                                                </div>
                                                                <div className="name_zone">
                                                                    <p className="name">{profile?.display_name}</p>
                                                                    <p className="email" onClick={(e) => { handleEmailCopyClick(e) }}>{profile?.email}</p>
                                                                </div>
                                                                <div className="stats_zone">
                                                                    <p className="followers">You have <span>{profile?.followers.total}</span> followers</p>
                                                                    <p className="premium"><span>{profile?.product}</span> is your subscription type</p>
                                                                </div>
                                                            </div>
                                                            <div className="logout" onClick={() => { handleLogout() }}>
                                                                <p>Logout</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="desktop">
                                                            <div className="photo">
                                                                {
                                                                    profile?.images?.length
                                                                        ?
                                                                        <img className="avatar" src={profile?.images ? highestQualityImageFinder(profile?.images) : ''} alt="" />
                                                                        :
                                                                        <ProfileEmptyPhoto size={300} />
                                                                }
                                                            </div>
                                                            <div className="main-content">
                                                                <div className="name_zone">
                                                                    <p className="name">{profile?.display_name}</p>
                                                                    <p className="email" onClick={(e) => { handleEmailCopyClick(e) }}>{profile?.email}</p>
                                                                </div>
                                                                <div className="stats_zone">
                                                                    <p className="followers">You have <span>{profile?.followers.total}</span> followers</p>
                                                                    <p className="premium"><span>{profile?.product}</span> is your subscription type</p>
                                                                </div>
                                                                <div className="logout" onClick={() => { handleLogout() }}>
                                                                    <p>Logout</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                                <div className="bottom-zone">
                                                    <div className="report_bug">
                                                        <p>Faced with issue on our website? <a onClick={() => handleReportTheProblem()} href="mailto:contact@melodystats.me?subject=Problem%20Report" target="_blank" rel="noopener noreferrer">Report the problem!</a></p>
                                                    </div>
                                                </div>
                                                <Snackbar message={message} onClose={handleCloseSnackbar} />
                                            </>
                                            :
                                            <Loading />
                                    }
                                </div>
                                :
                                <Loading />
                        }
                    </div>
                    :
                    <LoginWindow />
            }
            {
                props.authentication_key
                    ?
                    loaded
                        ?
                        <Footer />
                        :
                        null
                    :
                    props.windowWidth !== null
                        ?
                        <Footer />
                        :
                        null
            }
        </div>
    );
}



const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        windowWidth: state.windowWidth,
        user: state.user,
        userUpdatedTime: state.userUpdatedTime
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_USER };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
