//style
import "./AudioFeaturesStyles.scss"
//packages
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
//utils
import { metadataAudioFeatures } from "../../../utils/pagesMetadata/metadataAudioFeatures";
import { audioFeaturesIcons } from "../../../utils/variables/audioFeaturesIcons"
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_AUDIO_FEATURES } from "../../../context/actions";
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import TermSwitcher from "../../common/TermSwitcher/TermSwitcher";
import Loading from "../../common/Loading/Loading";
import NotEnoughData from "../../common/NotEnoughData/NotEnoughData";
import { MelodystatsBrand } from "../../common/MelodystatsBrand";
import Share from "../../common/Share/Share";
import { InfoButton } from "../../common/InfoButton";
import { SeoHelmet } from "../../general/SeoHelmet";
import { useAudioFeatures } from "./hooks";
import { SpotifyBrand } from "../../common/SpotifyBrand";

function AudioFetureCard({ feature, loaded, fontsLoaded, setPopupContent, setPopupType }) {
    const shareRef = useRef(null)

    const [imageIsLoaded, setImageIsLoaded] = useState(false);
    const [readyToBeShared, setReadyToBeShared] = useState(false);
    useEffect(() => {
        if (loaded && fontsLoaded && imageIsLoaded)
            setReadyToBeShared(true)
    }, [loaded, fontsLoaded, imageIsLoaded])


    function getPercentage(feature) {
        if (Math.abs(feature.max) > Math.abs(feature.min))
            return Math.floor(Math.abs(feature.val) / Math.abs(feature.max) * 200)
        else
            return Math.floor(Math.abs(feature.val) / Math.abs(feature.min) * 200)
    }

    const modeDefiner = (val) => {
        if (val > 0.5)
            return "Major"
        if (val === 0.5)
            return "Both"
        if (val < 0.5)
            return "Minor"
    }

    return (
        <div className="audio_features-card" key={feature.name}>
            {feature.name !== "Tempo" && feature.name !== "Mode" ?
                <div className="audio_features-level_feature">
                    <div className="audio_features-level_feature-body">
                        <div className="image_zone">
                            <picture>
                                <source srcSet={audioFeaturesIcons[feature.name]?.webp} type="image/webp" />
                                <source srcSet={audioFeaturesIcons[feature.name]?.png} type="image/png" />
                                <img
                                    src={audioFeaturesIcons[feature.name]?.png}
                                    alt=""
                                    width="64px"
                                    height="64px"
                                />
                            </picture>
                            <p className="feature-value">{feature.val}%</p>
                        </div>
                        <div className="audio_features-level_feature-body-full">
                            <div className="audio_features-level_feature-body-filled" style={{ height: getPercentage(feature) }} ></div>
                        </div>
                    </div>
                </div>
                :
                <div className="audio_features-number_feature">
                    <picture>
                        <source srcSet={audioFeaturesIcons[feature.name === "Tempo" ? feature.name : modeDefiner(feature.val)]?.webp} type="image/webp" />
                        <source srcSet={audioFeaturesIcons[feature.name === "Tempo" ? feature.name : modeDefiner(feature.val)]?.png} type="image/png" />
                        <img
                            src={audioFeaturesIcons[feature.name === "Tempo" ? feature.name : modeDefiner(feature.val)]?.png}
                            alt=""
                            width="64px"
                            height="64px"
                        />
                    </picture>
                    <p className="feature-value">{feature.name === "Tempo" ? feature.val : modeDefiner(feature.val)}</p>
                </div>
            }
            <p className="feature-name">{feature.name}</p>
            <p className="feature-interval">
                {
                    feature.name !== "Tempo" && feature.name !== "Mode"
                        ?
                        <>&nbsp;</>
                        :
                        feature.name === "Tempo"
                            ?
                            "BPM"
                            :
                            "Major or Minor"
                }
            </p>
            <div className="hidden_for_share">
                <div
                    className="audio_features-card sharable_content"
                    key={feature.name}
                    ref={shareRef}
                >
                    <SpotifyBrand />
                    {feature.name !== "Tempo" && feature.name !== "Mode" ?
                        <div className="audio_features-level_feature">
                            <div className="audio_features-level_feature-body">
                                <div className="image_zone">
                                    <img
                                        src={audioFeaturesIcons[feature.name]?.png}
                                        alt=""
                                        width="64px"
                                        height="64px"
                                        onLoad={() => setImageIsLoaded(true)}
                                    />
                                    <p className="feature-value">{feature.val}%</p>
                                </div>
                                <div className="audio_features-level_feature-body-full">
                                    <div className="audio_features-level_feature-body-filled" style={{ height: getPercentage(feature) }} ></div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="audio_features-number_feature">
                            <img
                                src={audioFeaturesIcons[feature.name === "Tempo" ? feature.name : modeDefiner(feature.val)]?.png}
                                alt=""
                                width="64px"
                                height="64px"
                                onLoad={() => setImageIsLoaded(true)}
                            />
                            <p className="feature-value">{feature.name === "Tempo" ? feature.val : modeDefiner(feature.val)}</p>
                        </div>
                    }
                    <p className="feature-name">{feature.name}</p>
                    <p className="feature-interval">
                        {
                            feature.name !== "Tempo" && feature.name !== "Mode"
                                ?
                                <>&nbsp;</>
                                :
                                feature.name === "Tempo"
                                    ?
                                    "BPM"
                                    :
                                    "Major or Minor"
                        }
                    </p>
                    <MelodystatsBrand />
                </div>
            </div>
            <div className="download-block ">
                <Share
                    shareRef={shareRef}
                    location={`Audio_Feature${feature?.name ? `_${feature?.name}` : ''}`}
                    ready={readyToBeShared}
                    promotionPopupAfterEnabled={true}
                    setPopupContent={setPopupContent}
                    setPopupType={setPopupType}
                    eventName='share_audio_features'
                />
            </div>
        </div>
    )
}

function AudioFeturesComponent({ isNotEnoughData, audioFeatures, fontsLoaded, loaded, setPopupContent, setPopupType }) {
    return (
        <>
            {
                !isNotEnoughData ?
                    audioFeatures ?
                        <div className="audio_features-list">
                            {audioFeatures.map((feature, i) => {
                                return (
                                    <AudioFetureCard
                                        feature={feature}
                                        fontsLoaded={fontsLoaded}
                                        loaded={loaded}
                                        key={i}
                                        setPopupContent={setPopupContent}
                                        setPopupType={setPopupType}
                                    />
                                );
                            })}
                        </div>
                        :
                        <Loading />
                    :
                    <NotEnoughData />
            }
        </>
    )
}

export function AudioFeatures(props) {
    const { audioFeaturesArray: audioFeatures, loaded, isNotEnoughData } = useAudioFeatures(props)

    const [fontsLoaded, setFontsLoaded] = useState(false);
    useEffect(() => {
        const checkFonts = async () => {
            await document.fonts.ready;
            setFontsLoaded(true);
        };
        checkFonts();
    }, []);


    const InfoDescriptionContent = () => {
        return (
            <>
                <p>Analyze various musical indicators of the tracks you've listened to, providing valuable insights into your music collection.</p>
                <p>For example, one of the analyzed features is tempo, which measures the beats per minute (BPM) of a track. By calculating the average tempo across all the tracks you've listened to within a specific period, this feature gives you an understanding of your tempo preferences during that time. It helps you better understand the rhythmic qualities in your music collection and provides a valuable glimpse into your unique musical taste and preferences.</p>
            </>
        )
    }

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataAudioFeatures

    return (
        <div className="audio_features-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="audio_features-block">
                        <h1 className="page_title">Your track's average audio features <InfoButton visible={loaded && !isNotEnoughData} popupContent={<InfoDescriptionContent />} setPopupContent={props.setPopupContent} setPopupType={props.setPopupType} /></h1>
                        <TermSwitcher />
                        {
                            loaded ?
                                <div className={`loading-able ${loaded ? "" : "loading-able-blur"}`}>
                                    {
                                        !isNotEnoughData ?
                                            props.windowWidth !== null && audioFeatures ?
                                                <div>
                                                    {
                                                        props.windowWidth < 1000 ?
                                                            <div className="mobile">
                                                                <AudioFeturesComponent
                                                                    isNotEnoughData={isNotEnoughData}
                                                                    audioFeatures={audioFeatures}
                                                                    fontsLoaded={fontsLoaded}
                                                                    loaded={loaded}
                                                                    setPopupContent={props.setPopupContent}
                                                                    setPopupType={props.setPopupType}
                                                                />
                                                            </div>
                                                            :
                                                            <div className="desktop">
                                                                <AudioFeturesComponent
                                                                    isNotEnoughData={isNotEnoughData}
                                                                    audioFeatures={audioFeatures}
                                                                    fontsLoaded={fontsLoaded}
                                                                    loaded={loaded}
                                                                    setPopupContent={props.setPopupContent}
                                                                    setPopupType={props.setPopupType}
                                                                />
                                                            </div>
                                                    }
                                                </div>
                                                :
                                                <Loading />
                                            :
                                            <NotEnoughData />
                                    }
                                </div>
                                :
                                <Loading />
                        }
                    </div>
                    :
                    <LoginWindow />
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth,
        audioFeatures: state.audioFeatures,
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_AUDIO_FEATURES };

export default connect(mapStateToProps, mapDispatchToProps)(AudioFeatures);
