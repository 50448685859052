import { useEffect, useState } from 'react';
import { fileNameGeneratorForSharing } from '../../../utils/shareHelpers/shareHelpers';
import { domToPng } from 'modern-screenshot'

export const useShare = (shareRef, filename, ready) => {
    const [pngUrl, setPngUrl] = useState(null)
    useEffect(() => {
        if (shareRef && ready)
            domToPng(shareRef.current)
                .then((dataUrl) => {
                    setPngUrl(dataUrl)
                })
    }, [shareRef, ready])

    const [pngBlob, setPngBlob] = useState(null)
    useEffect(() => {
        if (pngUrl?.length) {
            var byteString;
            if (pngUrl.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(pngUrl.split(',')[1]);
            else
                byteString = unescape(pngUrl.split(',')[1]);
            var mimeString = pngUrl.split(',')[0].split(':')[1].split(';')[0];
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            setPngBlob(new Blob([ia], { type: mimeString }))
        }
    }, [pngUrl])

    const [pngFile, setPngFile] = useState(null)
    const [pngFileLoaded, setPngFileLoaded] = useState(false)
    useEffect(() => {
        if (pngBlob)
            setPngFile(
                new File([pngBlob], `${filename}.png`, {
                    type: "image/png",
                })
            )
        setPngFileLoaded(true)
    }, [filename, pngBlob])

    return {
        pngFile,
        pngFileLoaded
    }
}


export const useDataUrl = (shareRef, ready) => {
    const [pngUrl, setPngUrl] = useState(null)
    const [pngUrlLoaded, setPngUrlLoaded] = useState(false)

    useEffect(() => {
        if (shareRef && ready)
            domToPng(shareRef.current)
                .then((dataUrl) => {
                    setPngUrl(dataUrl)
                    setPngUrlLoaded(true)
                })
    }, [shareRef, ready])

    return {
        pngUrl,
        pngUrlLoaded
    }
}


export const useFilename = (location) => {
    const [filename, setFilename] = useState('')

    useEffect(() => {
        if (location?.length)
            setFilename(fileNameGeneratorForSharing(location))
    }, [location])

    return {
        filename
    }
}


export const useCanBeShared = (pngFile) => {
    const [canBeShared, setCanBeShared] = useState(false)

    useEffect(() => {
        if (pngFile) {
            const canSharePNG = () => {
                if (navigator.share) {
                    const data = {
                        files: [pngFile]
                    };
                    if (navigator.canShare && navigator.canShare(data))
                        return true
                }
                return false
            }
            setCanBeShared(canSharePNG(pngFile))
        }
    }, [pngFile])

    return {
        canBeShared
    }
}