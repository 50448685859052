//style
import "./PosterStyles.scss"
//packages
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
//utils
import { metadataFestivalPoster } from "../../../utils/pagesMetadata/metadataFestivalPoster";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS } from "../../../context/actions";
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import TermSwitcher from "../../common/TermSwitcher/TermSwitcher";
import Loading from "../../common/Loading/Loading";
import NotEnoughData from "../../common/NotEnoughData/NotEnoughData";
import { MelodystatsBrand } from "../../common/MelodystatsBrand";
import Share from "../../common/Share/Share";
import { InfoButton } from "../../common/InfoButton";
import { SeoHelmet } from "../../general/SeoHelmet";
import { usePoster } from "./hooks";
import { SpotifyBrand } from "../../common/SpotifyBrand";

export function Poster(props) {
    const { poster, loaded, isNotEnoughData } = usePoster(props)
    
    const artistLink = (url, name) => `<a href="${url}?si" target="_blank" rel="noopener noreferrer">${name}</a>`

    const formatArtists = (artists) => {
        let result = []
        let artistsCopy = [...artists];
        while (artistsCopy.length) {
            let currentRow = []
            let currentLinksRow = []
            while (((currentRow.length - 1) * 3 + currentRow.join('').length) < 38 && artistsCopy.length) {
                let tmpRow = [...currentRow, artistsCopy[0].name]

                if (((tmpRow.length - 1) * 3 + tmpRow.join('').length) <= 38) {
                    const shiftedArtist = artistsCopy.shift()
                    currentRow.push(shiftedArtist.name.toUpperCase())
                    currentLinksRow.push(artistLink(shiftedArtist?.external_urls?.spotify, shiftedArtist.name.toUpperCase()))
                } else {
                    if (!currentRow.length && artistsCopy[0].name.length > 38) {
                        const shiftedArtist = artistsCopy.shift()
                        currentRow.push(`${shiftedArtist.name.toUpperCase().slice(0, 16)}...`)
                        currentLinksRow.push(`${artistLink(shiftedArtist?.external_urls?.spotify, shiftedArtist.name.toUpperCase().slice(0, 16))}...}`)
                    } else {
                        break
                    }
                }
            }
            result.push(currentLinksRow)
        }
        result = result.map((e) => { return e.join(' • ') }).join("<br>")
        return result
    }

    function PosterContent() {
        return (
            <div className="poster-content">
                <div className="heading">
                    <p className="festname">Melodyfest</p>
                    <p className="cityname">IN YOUR CITY</p>
                </div>
                <div className="rows">
                    {
                        poster.rows.map((row) => {
                            return <div key={`row${row.headliner.id}`} className="row">
                                <p className="headliner">
                                    <a
                                        href={`${row.headliner?.external_urls?.spotify}?si`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {row.headliner.name}
                                    </a>
                                </p>
                                <p className="artists" dangerouslySetInnerHTML={{ __html: formatArtists(row.artists) }}></p>
                            </div>
                        })
                    }
                    <div className="row">
                        <p className="description">WITH SPECIAL GUEST</p>
                        <p className="headliner">
                            <a
                                href={`${poster.specialGuest?.external_urls?.spotify}?si`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {poster.specialGuest.name}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    const shareRef = useRef(null)

    const [fontsLoaded, setFontsLoaded] = useState(false);
    useEffect(() => {
        const checkFonts = async () => {
            await document.fonts.ready;
            setFontsLoaded(true);
        };
        checkFonts();
    }, []);

    const [readyToBeShared, setReadyToBeShared] = useState(false);
    useEffect(() => {
        if (loaded && fontsLoaded)
            setReadyToBeShared(true)
    }, [loaded, fontsLoaded])

    const InfoDescriptionContent = () => {
        return (
            <>
                <p>Presents a captivating visual representation of the artists you listen to, arranged in order of popularity. It emulates the style of festival posters, where the font size corresponds to the popularity of each artist. This feature allows you to easily identify and appreciate your favorite artists, simulating your own music festival.</p>
            </>
        )
    }

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataFestivalPoster

    return (
        <div className="poster-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="poster-block">
                        <h1 className="page_title">Festival poster <InfoButton visible={loaded && !isNotEnoughData} popupContent={<InfoDescriptionContent />} setPopupContent={props.setPopupContent} setPopupType={props.setPopupType} /></h1>
                        <TermSwitcher />
                        {
                            loaded ?
                                <div className={`loading-able ${loaded ? "" : "loading-able-blur"}`}>
                                    {
                                        !isNotEnoughData ?
                                            props.windowWidth !== null && poster ?
                                                <div className="poster-wrapper">
                                                    {
                                                        props.windowWidth < 1000 ?
                                                            <div className="mobile">
                                                                <PosterContent />
                                                            </div>
                                                            :
                                                            <div className="desktop">
                                                                <PosterContent />
                                                            </div>
                                                    }
                                                    <div className="hidden_for_share" style={{ letterSpacing: 'normal' }}>
                                                        {
                                                            props.windowWidth < 1000 ?
                                                                <div
                                                                    className="mobile sharable_content"
                                                                    ref={shareRef}
                                                                >
                                                                    <SpotifyBrand />
                                                                    <PosterContent />
                                                                    <MelodystatsBrand />
                                                                </div>
                                                                :
                                                                <div
                                                                    className="desktop sharable_content"
                                                                    ref={shareRef}
                                                                >
                                                                    <SpotifyBrand />
                                                                    <PosterContent />
                                                                    <MelodystatsBrand />
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="download-block ">
                                                        <Share
                                                            shareRef={shareRef}
                                                            location='Festival_Poster'
                                                            ready={readyToBeShared}
                                                            promotionPopupAfterEnabled={true}
                                                            setPopupContent={props.setPopupContent}
                                                            setPopupType={props.setPopupType}
                                                            eventName='share_poster'
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <Loading />
                                            :
                                            <NotEnoughData />
                                    }
                                </div>
                                :
                                <Loading />
                        }
                    </div>
                    :
                    <LoginWindow />
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth,
        artists: state.artists
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS };

export default connect(mapStateToProps, mapDispatchToProps)(Poster);
