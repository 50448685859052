//style
import "./IcebergStyles.scss"
//packages
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
//utils
import { metadataIceberg } from "../../../utils/pagesMetadata/metadataIceberg";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS } from "../../../context/actions";
//helpers
import { encodeElementToImage } from "../../../helpers/imageHelpers";
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import TermSwitcher from "../../common/TermSwitcher/TermSwitcher";
import Loading from "../../common/Loading/Loading";
import { IcebergDesktop } from "../../../assets/special/iceberg-desktop";
import { IcebergMobile } from "../../../assets/special/iceberg-mobile";
import NotEnoughData from "../../common/NotEnoughData/NotEnoughData";
import { MelodystatsBrand } from "../../common/MelodystatsBrand";
import Share from "../../common/Share/Share";
import { InfoButton } from "../../common/InfoButton";
import { SeoHelmet } from "../../general/SeoHelmet";
import { useIceberg } from "./hooks";
import { SpotifyBrand } from "../../common/SpotifyBrand";
//assets
import Spotify_Icon_RGB_White from "../../../assets/brands/Spotify_Icon_RGB_White.png"
import Spotify_Icon_RGB_Black from "../../../assets/brands/Spotify_Icon_RGB_Black.png"

export function Iceberg(props) {
    const { icebergDesktop, icebergMobile, loaded, isNotEnoughData } = useIceberg(props)

    const shareRef = useRef(null)

    const [loadedVisibleImages, setLoadedVisibleImages] = useState(0);
    const handleVisibleImageLoad = () => {
        setLoadedVisibleImages((prev) => prev + 1);
    };
    const [loadedInVisibleImages, setLoadedInVisibleImages] = useState(0);
    const handleInVisibleImageLoad = () => {
        setLoadedInVisibleImages((prev) => prev + 1);
    };
    const [imagesIsLoaded, setImagesIsLoaded] = useState(false);
    useEffect(() => {
        const imagesCount = icebergDesktop?.flat()?.filter(e => e.length)?.length | 0
        if (loadedVisibleImages === imagesCount && loadedInVisibleImages === imagesCount)
            setImagesIsLoaded(true)
    }, [icebergDesktop, icebergMobile, loadedVisibleImages, loadedInVisibleImages])

    const [fontsLoaded, setFontsLoaded] = useState(false);
    useEffect(() => {
        const checkFonts = async () => {
            await document.fonts.ready;
            setFontsLoaded(true);
        };
        checkFonts();
    }, []);

    const [readyToBeShared, setReadyToBeShared] = useState(false);
    useEffect(() => {
        if (imagesIsLoaded && fontsLoaded)
            setReadyToBeShared(true)
    }, [imagesIsLoaded, fontsLoaded])

    const InfoDescriptionContent = () => {
        return (
            <>
                <p>Presents a visual representation of the artists you listen to, arranged based on their popularity. It follows the concept of an iceberg, where the top 20% of the most popular artists are displayed notably while the remaining artists occupy the lower parts. This feature gives you a clear view of your favorite artists and their popularity in music world.</p>
            </>
        )
    }

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataIceberg

    return (
        <div className="iceberg-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="iceberg-block">
                        <h1 className="page_title">Your playlist's Iceberg <InfoButton visible={loaded && !isNotEnoughData} popupContent={<InfoDescriptionContent />} setPopupContent={props.setPopupContent} setPopupType={props.setPopupType} /></h1>
                        <TermSwitcher />
                        {
                            loaded ?
                                <div className={`loading-able ${loaded ? "" : "loading-able-blur"}`}>
                                    {
                                        !isNotEnoughData ?
                                            <>
                                                {
                                                    props.windowWidth !== null && icebergDesktop && icebergMobile ?
                                                        <div className="iceberg-wrapper">
                                                            {
                                                                props.windowWidth < 1000 ?
                                                                    <div className="mobile">
                                                                        <div
                                                                            className="iceberg-content"
                                                                            style={{ backgroundImage: `url(${encodeElementToImage(<IcebergMobile />)})` }}
                                                                        >
                                                                            {
                                                                                icebergMobile.map((ice, index) => {
                                                                                    return <div className="ice-row" key={`${index}-${ice.join('-')}`}>
                                                                                        {
                                                                                            ice.map((artist, i) => {
                                                                                                return (
                                                                                                    <div className="artist" key={`${i}${artist.id}`}>
                                                                                                        <img
                                                                                                            onLoad={handleVisibleImageLoad}
                                                                                                            src={artist?.images[0].url}
                                                                                                            className="artist-image"
                                                                                                            alt=""
                                                                                                            width="50px"
                                                                                                            height="50px"
                                                                                                        />
                                                                                                        <p className="artist-name">{artist.name}</p>
                                                                                                        <a
                                                                                                            href={`${artist?.external_urls?.spotify}?si`}
                                                                                                            target="_blank"
                                                                                                            rel="noopener noreferrer"
                                                                                                            key={artist.id}
                                                                                                        >
                                                                                                            <img
                                                                                                                src={index < 3 ? Spotify_Icon_RGB_Black : Spotify_Icon_RGB_White}
                                                                                                                alt=""
                                                                                                                width="25px"
                                                                                                                height="25px"
                                                                                                                className="artist-link"
                                                                                                            />
                                                                                                        </a>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="desktop">
                                                                        <div
                                                                            className="iceberg-content"
                                                                            style={{ backgroundImage: `url(${encodeElementToImage(<IcebergDesktop />)})` }}
                                                                        >
                                                                            {
                                                                                icebergDesktop.map((ice, index) => {
                                                                                    return <div className="ice-row" key={`${index}-${ice.join('-')}`}>
                                                                                        {
                                                                                            ice.map((artist, i) => {
                                                                                                return (
                                                                                                    <div className="artist" key={`${i}${artist.id}`}>
                                                                                                        <img
                                                                                                            onLoad={handleVisibleImageLoad}
                                                                                                            src={artist?.images[0].url}
                                                                                                            className="artist-image"
                                                                                                            alt=""
                                                                                                            width="50px"
                                                                                                            height="50px"
                                                                                                        />
                                                                                                        <p className="artist-name">{artist.name}</p>
                                                                                                        <a
                                                                                                            href={`${artist?.external_urls?.spotify}?si`}
                                                                                                            target="_blank"
                                                                                                            rel="noopener noreferrer"
                                                                                                            key={artist.id}
                                                                                                        >
                                                                                                            <img
                                                                                                                src={index < 3 ? Spotify_Icon_RGB_Black : Spotify_Icon_RGB_White}
                                                                                                                alt=""
                                                                                                                width="25px"
                                                                                                                height="25px"
                                                                                                                className="artist-link"
                                                                                                            />
                                                                                                        </a>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                            }
                                                            <div
                                                                className="hidden_for_share"
                                                            >
                                                                {
                                                                    props.windowWidth < 1000 ?
                                                                        <div
                                                                            className="mobile sharable_content"
                                                                            ref={shareRef}
                                                                        >
                                                                            <SpotifyBrand />
                                                                            <div
                                                                                className="iceberg-content"
                                                                                style={{ backgroundImage: `url(${encodeElementToImage(<IcebergMobile />)})` }}
                                                                            >
                                                                                {
                                                                                    icebergMobile.map((ice, index) => {
                                                                                        return <div className="ice-row" key={`${index}-${ice.join('-')}`}>
                                                                                            {
                                                                                                ice.map((artist, i) => {
                                                                                                    return (
                                                                                                        <div className="artist" key={`${i}${artist.id}`}>
                                                                                                            <img
                                                                                                                onLoad={handleInVisibleImageLoad}
                                                                                                                src={artist?.images[0].url}
                                                                                                                className="artist-image"
                                                                                                                alt=""
                                                                                                                width="50px"
                                                                                                                height="50px"
                                                                                                            />
                                                                                                            <p className="artist-name">{artist.name}</p>
                                                                                                            <a
                                                                                                                href={`${artist?.external_urls?.spotify}?si`}
                                                                                                                target="_blank"
                                                                                                                rel="noopener noreferrer"
                                                                                                                key={artist.id}
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={index < 3 ? Spotify_Icon_RGB_Black : Spotify_Icon_RGB_White}
                                                                                                                    alt=""
                                                                                                                    width="25px"
                                                                                                                    height="25px"
                                                                                                                    className="artist-link"
                                                                                                                />
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <MelodystatsBrand />
                                                                        </div>
                                                                        :
                                                                        <div
                                                                            className="desktop sharable_content"
                                                                            ref={shareRef}
                                                                        >
                                                                            <SpotifyBrand />
                                                                            <div
                                                                                className="iceberg-content"
                                                                                style={{ backgroundImage: `url(${encodeElementToImage(<IcebergDesktop />)})` }}
                                                                            >
                                                                                {
                                                                                    icebergDesktop.map((ice, index) => {
                                                                                        return <div className="ice-row" key={`${index}-${ice.join('-')}`}>
                                                                                            {
                                                                                                ice.map((artist, i) => {
                                                                                                    return (
                                                                                                        <div className="artist" key={`${i}${artist.id}`}>
                                                                                                            <img
                                                                                                                onLoad={handleInVisibleImageLoad}
                                                                                                                src={artist?.images[0].url}
                                                                                                                className="artist-image"
                                                                                                                alt=""
                                                                                                                width="50px"
                                                                                                                height="50px"
                                                                                                            />
                                                                                                            <p className="artist-name">{artist.name}</p>
                                                                                                            <a
                                                                                                                href={`${artist?.external_urls?.spotify}?si`}
                                                                                                                target="_blank"
                                                                                                                rel="noopener noreferrer"
                                                                                                                key={artist.id}
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={index < 3 ? Spotify_Icon_RGB_Black : Spotify_Icon_RGB_White}
                                                                                                                    alt=""
                                                                                                                    width="25px"
                                                                                                                    height="25px"
                                                                                                                    className="artist-link"
                                                                                                                />
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <MelodystatsBrand />
                                                                        </div>
                                                                }
                                                            </div>
                                                            <div className="download-block ">
                                                                <Share
                                                                    shareRef={shareRef}
                                                                    location='Iceberg'
                                                                    ready={readyToBeShared}
                                                                    promotionPopupAfterEnabled={true}
                                                                    setPopupContent={props.setPopupContent}
                                                                    setPopupType={props.setPopupType}
                                                                    eventName='share_iceberg'
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <Loading />
                                                }
                                            </>
                                            :
                                            <NotEnoughData />
                                    }
                                </div>
                                :
                                <Loading />
                        }

                    </div>
                    :
                    <LoginWindow />
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth,
        artists: state.artists
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS };

export default connect(mapStateToProps, mapDispatchToProps)(Iceberg);
