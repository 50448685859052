//style
import "./FooterStyles.scss"
//packages
import React from "react";
import { connect } from "react-redux";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT } from "../../../context/actions";
//components
import { Link } from "react-router-dom";


export function Footer(props) {

    const handleContactUsClick = () => {
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerContactUs()
    }

    const FooterContent = () => {
        return (
            <div className="footer-content">
                <div className="footer-terms_link">
                    <Link to="/patrons">
                        Our patrons
                    </Link>
                </div>
                <div className="footer-terms_link">
                    <Link to="/terms-and-conditions">
                        Terms and Conditions
                    </Link>
                </div>
                <div className="footer-terms_link">
                    <a onClick={() => { handleContactUsClick() }} href="mailto:contact@melodystats.me" target="_blank" rel="noopener noreferrer">
                        Contact Us
                    </a>
                </div>
            </div>
        )
    }

    return (
        <footer>
            <FooterContent />
        </footer>
    );
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.windowWidth
    };
};
const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT };
export default connect(mapStateToProps, mapDispatchToProps)(Footer);