//style
import "./NotEnoughDataStyles.scss"
//packages
import React from "react";
import { connect } from "react-redux";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT } from "../../../context/actions";

export function NotEnoughData(props) {

    return (
        <div className="not_enought_data">
            <div className="not_enought_data-content">
                <p className="header">
                    Not enough data
                </p>
                <p>
                    Oops.. We faced with problem of lacking data for this feature <br /> Listen to more music and come back to us later!
                </p>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.windowWidth
    };
};
const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT };
export default connect(mapStateToProps, mapDispatchToProps)(NotEnoughData);