//style
import "./AgesOfPlaylistStyles.scss"
//packages
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
//utils
import { metadataAgesOfPlaylist } from "../../../utils/pagesMetadata/metadataAgesOfPlaylist";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_TRACKS } from "../../../context/actions";
//assets
import { TicketDesktop } from "../../../assets/special/ticket-desktop"
import { TicketMobile } from "../../../assets/special/ticket-mobile"
import Spotify_Icon_RGB_White from "../../../assets/brands/Spotify_Icon_RGB_White.png"
//helpers
import { encodeElementToImage } from "../../../helpers/imageHelpers";
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import TermSwitcher from "../../common/TermSwitcher/TermSwitcher";
import Loading from "../../common/Loading/Loading";
import NotEnoughData from "../../common/NotEnoughData/NotEnoughData";
import { MelodystatsBrand } from "../../common/MelodystatsBrand";
import Share from "../../common/Share/Share";
import { InfoButton } from "../../common/InfoButton";
import { SeoHelmet } from "../../general/SeoHelmet";
import { useAgesOfPlaylist } from "./hooks";
import { SpotifyBrand } from "../../common/SpotifyBrand";

const ticketColors = (key) => {
    const colors = {
        green: "#1DB954",
        red: "#C40233",
        violet: "#563FAC",
        blue: "#0066FF",
        yellow: "#FFBF00"
    }
    while (key > 4) {
        key -= 5
    }
    return Object.values(colors)[key]
}

function MobileTicket({ e, i, loaded, fontsLoaded, setPopupContent, setPopupType }) {
    const shareRef = useRef(null)

    const [imageIsLoaded, setImageIsLoaded] = useState(false);
    const [readyToBeShared, setReadyToBeShared] = useState(false);
    useEffect(() => {
        if (loaded && fontsLoaded && imageIsLoaded)
            setReadyToBeShared(true)
    }, [loaded, fontsLoaded, imageIsLoaded])

    return (
        <div>
            <div
                className="ticket"
                style={{ backgroundImage: `url(${encodeElementToImage(<TicketMobile color={ticketColors(i)} />)})` }}
            >
                <div className="ticket-decade">
                    <p className="decade">{e.decade}'s</p>
                    <p className="percentage">{e.percentage}% of your playlist</p>
                </div>
                <div className="ticket-track">
                    <p className="description">Iconic track</p>
                    <div className="track">
                        <img
                            className="track-image"
                            src={e.track.album?.images[1]?.url || e.track.album?.images[0]?.url}
                            alt=""
                            width="100px"
                            height="100px"
                        />
                        <div className="track-info">
                            <p className="track-name">{e.track.name}</p>
                            <p className="track-artists">{e.track.artists.map((artist, i) => {
                                return (
                                    (i > 0) ? (", " + artist.name) : artist.name
                                );
                            })}</p>
                            <p className="track-year">{new Date(e.track.album.release_date).getFullYear()}</p>
                            <a
                                href={`${e.track?.external_urls?.spotify}?si`}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={e.track.id}
                            >
                                <img
                                    className="track-spotify_link"
                                    src={Spotify_Icon_RGB_White}
                                    alt=""
                                    width="25px"
                                    height="25px"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden_for_share">
                <div
                    ref={shareRef}
                    className="sharable_content"
                >
                    <SpotifyBrand />
                    <div
                        className="ticket"
                        style={{ backgroundImage: `url(${encodeElementToImage(<TicketMobile color={ticketColors(i)} />)})` }}
                    >
                        <div className="ticket-decade">
                            <p className="decade">{e.decade}'s</p>
                            <p className="percentage">{e.percentage}% of your playlist</p>
                        </div>
                        <div className="ticket-track">
                            <p className="description">Iconic track</p>
                            <div className="track">
                                <img
                                    className="track-image"
                                    src={e.track.album?.images[1]?.url || e.track.album?.images[0]?.url}
                                    alt=""
                                    width="100px"
                                    height="100px"
                                    onLoad={() => setImageIsLoaded(true)}
                                />
                                <div className="track-info">
                                    <p className="track-name">{e.track.name}</p>
                                    <p className="track-artists">{e.track.artists.map((artist, i) => {
                                        return (
                                            (i > 0) ? (", " + artist.name) : artist.name
                                        );
                                    })}</p>
                                    <p className="track-year">{new Date(e.track.album.release_date).getFullYear()}</p>
                                    <a
                                        href={`${e.track?.external_urls?.spotify}?si`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        key={e.track.id}
                                    >
                                        <img
                                            className="track-spotify_link"
                                            src={Spotify_Icon_RGB_White}
                                            alt=""
                                            width="25px"
                                            height="25px"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MelodystatsBrand />
                </div>
            </div>
            <div className="download-block ">
                <Share
                    shareRef={shareRef}
                    location='Ages_Of_Playlist'
                    ready={readyToBeShared}
                    promotionPopupAfterEnabled={true}
                    setPopupContent={setPopupContent}
                    setPopupType={setPopupType}
                    event='share_ages_of_playlist'
                />
            </div>
        </div>
    )
}

function DesktopTicket({ e, i, loaded, fontsLoaded, setPopupContent, setPopupType }) {
    const shareRef = useRef(null)

    const [imageIsLoaded, setImageIsLoaded] = useState(false);
    const [readyToBeShared, setReadyToBeShared] = useState(false);
    useEffect(() => {
        if (loaded && fontsLoaded && imageIsLoaded)
            setReadyToBeShared(true)
    }, [loaded, fontsLoaded, imageIsLoaded])

    return (
        <div>
            <div
                className="ticket"
                style={{ backgroundImage: `url(${encodeElementToImage(<TicketDesktop color={ticketColors(i)} />)})` }}
            >
                <div className="ticket-decade">
                    <p className="decade">{e.decade}'s</p>
                    <p className="percentage">{e.percentage}% of your playlist are from {e.decade}'s</p>
                </div>
                <div className="ticket-track">
                    <div className="track">
                        <img
                            className="track-image"
                            src={e.track.album?.images[1]?.url || e.track.album?.images[0]?.url}
                            alt=""
                            width="100px"
                            height="100px"
                        />
                        <div className="track-info">
                            <p className="track-name">{e.track.name}</p>
                            <p className="track-artists">{e.track.artists.map((artist, i) => {
                                return (
                                    (i > 0) ? (", " + artist.name) : artist.name
                                );
                            })}</p>
                            <p className="track-year">{new Date(e.track.album.release_date).getFullYear()}</p>
                            <a
                                href={`${e.track?.external_urls?.spotify}?si`}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={e.track.id}
                            >
                                <img
                                    className="track-spotify_link"
                                    src={Spotify_Icon_RGB_White}
                                    alt=""
                                    width="25px"
                                    height="25px"
                                />
                            </a>
                        </div>
                    </div>
                    <p className="description">Iconic track</p>
                </div>
            </div>
            <div className="hidden_for_share">
                <div
                    ref={shareRef}
                    className="sharable_content"
                >
                    <SpotifyBrand />
                    <div
                        className="ticket"
                        style={{ backgroundImage: `url(${encodeElementToImage(<TicketDesktop color={ticketColors(i)} />)})` }}
                    >
                        <div className="ticket-decade">
                            <p className="decade">{e.decade}'s</p>
                            <p className="percentage">{e.percentage}% of your playlist are from {e.decade}'s</p>
                        </div>
                        <div className="ticket-track">
                            <div className="track">
                                <img
                                    className="track-image"
                                    src={e.track.album?.images[1]?.url || e.track.album?.images[0]?.url}
                                    alt=""
                                    width="100px"
                                    height="100px"
                                    onLoad={() => setImageIsLoaded(true)}
                                />
                                <div className="track-info">
                                    <p className="track-name">{e.track.name}</p>
                                    <p className="track-artists">{e.track.artists.map((artist, i) => {
                                        return (
                                            (i > 0) ? (", " + artist.name) : artist.name
                                        );
                                    })}</p>
                                    <p className="track-year">{new Date(e.track.album.release_date).getFullYear()}</p>
                                    <a
                                        href={`${e.track?.external_urls?.spotify}?si`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        key={e.track.id}
                                    >
                                        <img
                                            className="track-spotify_link"
                                            src={Spotify_Icon_RGB_White}
                                            alt=""
                                            width="25px"
                                            height="25px"
                                        />
                                    </a>
                                </div>
                            </div>
                            <p className="description">Iconic track</p>
                        </div>
                    </div>
                    <MelodystatsBrand />
                </div>
            </div>
            <div className="download-block ">
                <Share
                    shareRef={shareRef}
                    location='Ages_Of_Playlist'
                    ready={readyToBeShared}
                    promotionPopupAfterEnabled={true}
                    setPopupContent={setPopupContent}
                    setPopupType={setPopupType}
                    eventName='share_ages_of_playlist'
                />
            </div>
        </div>
    )
}

export function AgesOfPlaylist(props) {
    const { agesOfPlaylist, loaded, isNotEnoughData } = useAgesOfPlaylist(props)

    const [fontsLoaded, setFontsLoaded] = useState(false);
    useEffect(() => {
        const checkFonts = async () => {
            await document.fonts.ready;
            setFontsLoaded(true);
        };
        checkFonts();
    }, []);

    const InfoDescriptionContent = () => {
        return (
            <>
                <p>Provides insights into your music listening habits across different periods. It showcases how much music you've listened to from specific decade and highlights the most popular track from each period*. This feature allows you to explore and rediscover music from different eras that have resonated with you.</p>
                <p>* Iconic Track means most popular track in charts for specific period.</p>
            </>
        )
    }

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataAgesOfPlaylist

    return (
        <div className="ages_of_playlist-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="ages_of_playlist-block">
                        <h1 className="page_title">Ages of playlist <InfoButton visible={loaded && !isNotEnoughData} popupContent={<InfoDescriptionContent />} setPopupContent={props.setPopupContent} setPopupType={props.setPopupType} /></h1>
                        <TermSwitcher />
                        {
                            loaded ?
                                <div className={`loading-able ${loaded ? "" : "loading-able-blur"}`}>
                                    {
                                        !isNotEnoughData ?
                                            props.windowWidth !== null && agesOfPlaylist ?
                                                <div>
                                                    {
                                                        props.windowWidth < 1000 ?
                                                            <div className="mobile">
                                                                {
                                                                    agesOfPlaylist.map((e, i) => {
                                                                        return (
                                                                            <MobileTicket
                                                                                e={e}
                                                                                i={i}
                                                                                fontsLoaded={fontsLoaded}
                                                                                loaded={loaded}
                                                                                key={`${e.decade}`}
                                                                                setPopupContent={props.setPopupContent}
                                                                                setPopupType={props.setPopupType}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            <div className="desktop">
                                                                {
                                                                    agesOfPlaylist.map((e, i) => {
                                                                        return (
                                                                            <DesktopTicket
                                                                                e={e}
                                                                                i={i}
                                                                                fontsLoaded={fontsLoaded}
                                                                                loaded={loaded}
                                                                                key={`${e.decade}`}
                                                                                setPopupContent={props.setPopupContent}
                                                                                setPopupType={props.setPopupType}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                                :
                                                <Loading />
                                            :
                                            <NotEnoughData />
                                    }
                                </div>
                                :
                                <Loading />
                        }
                    </div>
                    :
                    <LoginWindow />
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth,
        tracks: state.tracks
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_TRACKS };

export default connect(mapStateToProps, mapDispatchToProps)(AgesOfPlaylist);
