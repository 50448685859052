import { useCallback } from "react";
import { useNavigate } from 'react-router-dom';


export const refreshPage = () => {
    setTimeout(() => {
        window.location.reload();
    }, 100)
}

export function useNavigateHome() {
    const navigate = useNavigate();
    const redirectToLoginPage = useCallback(() => navigate('/', { replace: true }), [navigate]);

    return () => {
        redirectToLoginPage()
    }
}