//style
import "./RecommendationsStyles.scss"
//packages
import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
//utils
import { metadataRecommendations } from "../../../utils/pagesMetadata/metadataRecommendations";
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import Loading from "../../common/Loading/Loading";
import NotEnoughData from "../../common/NotEnoughData/NotEnoughData";
import { TracksTable } from "../../components/TracksTable";
import { SeoHelmet } from "../../general/SeoHelmet";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS, SET_TRACKS } from "../../../context/actions";
//assets
import Spotify_Icon_RGB_Green from "../../../assets/brands/Spotify_Icon_RGB_Green.png"
//hooks
import { useRecommendations } from "./hooks";


function Recommendations(props) {
    const { recommendations, loaded, isNotEnoughData } = useRecommendations(props)

    const scroll50Ref = useRef(false);
    const scroll100Ref = useRef(false);

    const handleScroll = useCallback(() => {
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY;
        const pageHeight = document.documentElement.scrollHeight;
        const scrollPercentage = (scrollY / (pageHeight - windowHeight)) * 100;
        if (scrollPercentage >= 50 && !scroll50Ref.current) {
            scroll50Ref.current = true;
            if (process.env.REACT_APP_ENV === 'production')
                window?.eventTriggerRecommendationsScroll50()
        } else if (scrollPercentage >= 100 && !scroll100Ref.current) {
            scroll100Ref.current = true;
            if (process.env.REACT_APP_ENV === 'production')
                window?.eventTriggerRecommendationsScroll100()
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    function RunningLine(props) {
        const text = Array.from({ length: 9 }, () => 'TRY IT NOW')
        return <div className="running_line">
            <div className="running_content" style={{ width: props.width }}>
                <div className="text">
                    {
                        text.map((e, i) => {
                            return <p key={`${i * 99}`}>{e}</p>
                        })
                    }
                </div>
                <div className="text">
                    {
                        text.map((e, i) => {
                            return <p key={`${i * 999}`}>{e}</p>
                        })
                    }
                </div>
            </div>
        </div>
    }

    const onClickRecommendation = () => {
        props.setPopupContent('Like this recommendation?')
        props.setPopupType('promotion_recommendation')
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerListenRecommendation()
    }

    function SpotifyLink(props) {
        const { link } = props

        return <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="spotify_link"
            onClick={() => onClickRecommendation()}
        >
            <img
                src={Spotify_Icon_RGB_Green}
                alt=""
                width="25px"
                height="25px"
            />
            <p style={{ letterSpacing: 'normal' }}>PLAY ON SPOTIFY</p>
        </a>
    }

    function TrackBasedOnArtist(props) {
        const { width } = props
        const { artist, track } = props.data
        return <div className="track_based_on_artist">
            <div className="recommendation-block">
                <p className="heading">You definitely like music from&nbsp;
                    <a
                        href={`${artist?.external_urls?.spotify}?si`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="artists-card"
                    >
                        <span>{artist.name}</span>
                    </a>
                </p>
                <div className="artist_image">
                    <img src={artist?.images[0].url} alt="" />
                </div>
                <div className="recommendation-content">
                    <p className="recommendation-heading">Why not to try one of popular tracks?</p>
                    <div className="track-big">
                        <div className="track-main_content">
                            <img src={track.album?.images[0].url} alt="" />
                            <div className="track-main_content-text">
                                <p className="track-name">{track.name}</p>
                                <p className="track-artists">
                                    {`${track.artists.map((artist, i) => {
                                        return (
                                            (i > 0) ? (" " + artist.name) : artist.name
                                        );
                                    })}`}
                                </p>
                            </div>
                        </div>
                    </div>
                    <SpotifyLink type='track' link={`${track.external_urls.spotify}?si`} />
                    <RunningLine width={`${width < 1000 ? 280 : 575}px`} />
                </div>
            </div>
        </div>
    }

    function AlbumBasedOnTrack(props) {
        const { width } = props
        const { album, track } = props.data
        return <div className="album_based_on_track">
            <div className="recommendation-block">
                <p className="heading">One of your favorite tracks is from&nbsp;
                    <a
                        href={`${track.artists[0]?.external_urls?.spotify}?si`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="artists-card"
                    >
                        <span>{track.artists[0].name}</span>
                    </a>
                </p>
                <TracksTable tracksArray={[track]} indexed={false} />
                <div className="recommendation-content">
                    <p className="recommendation-heading">We recommend listening to the album that this track is from</p>
                    <div className="album-recommendation">
                        <img className="cover" src={album?.images[0].url} alt="" />
                        <p className="name">{album.name}</p>
                    </div>
                    <SpotifyLink type='album' link={`${album.external_urls.spotify}?si`} />
                    <RunningLine width={`${width < 1000 ? 280 : 575}px`} />
                </div>
            </div>
        </div>
    }

    function TracksBasedOnTrack(props) {
        const { width } = props
        const { track, tracks } = props.data
        return <div className="tracks_based_on_track">
            <div className="recommendation-block">
                <p className="heading">Recently Played Track:</p>
                <TracksTable tracksArray={[track]} indexed={false} />
                <div className="recommendation-content">
                    <p className="recommendation-heading">There is few tracks like this</p>
                    {
                        width < 1000 ?
                            <div className="tracks-mobile">
                                {tracks.map((track, i) => {
                                    return (
                                        <div key={i} className="cell">
                                            <table>
                                                <tbody key={track.id}>
                                                    <tr>
                                                        <td className="image">
                                                            <img
                                                                src={track.album?.images[1]?.url || track.album?.images[0]?.url}
                                                                alt=""
                                                                width="45px"
                                                                height="45px"
                                                            />
                                                        </td>
                                                        <td className="credentials">
                                                            <p className="track-name">{track.name}</p>
                                                            <p className="artists-list">
                                                                {track.artists.map((artist, i) => {
                                                                    return (
                                                                        (i > 0) ? (", " + artist.name) : artist.name
                                                                    );
                                                                })}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <SpotifyLink type='tracks' link={`${track.external_urls.spotify}?si`} name={track.name} width='260' />
                                        </div>
                                    );
                                })}
                            </div>
                            :
                            <table className="tracks-desktop">
                                <tbody>
                                    {tracks.map((track) => {
                                        return (
                                            <tr key={track.id}>
                                                <td className="image">
                                                    <img
                                                        src={track.album?.images[1]?.url || track.album?.images[0]?.url}
                                                        alt=""
                                                        width="45px"
                                                        height="45px"
                                                    />
                                                </td>
                                                <td className="credentials">
                                                    <p className="track-name">{track.name}</p>
                                                    <p className="artists">
                                                        {track.artists.map((artist, i) => {
                                                            return (
                                                                (i > 0) ? (", " + artist.name) : artist.name
                                                            );
                                                        })}
                                                    </p>
                                                </td>
                                                <td className="link">
                                                    <SpotifyLink type='tracks' link={`${track.external_urls.spotify}?si`} name={track.name} />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                    }

                    <RunningLine width={`${width < 1000 ? 280 : 575}px`} />
                </div>
            </div>
        </div>
    }

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataRecommendations

    return (
        <div className="recommendations-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="recommendations-block">
                        <h1 className="page_title">Recommendations</h1>
                        {
                            loaded ?
                                <div className={`loading-able ${loaded ? "" : "loading-able-blur"}`}>
                                    {
                                        !isNotEnoughData ?
                                            props.windowWidth !== null && recommendations ?
                                                <div>
                                                    {
                                                        props.windowWidth < 1000 ?
                                                            <div className="mobile">
                                                                {
                                                                    recommendations?.trackBasedOnArtist?.result && <TrackBasedOnArtist data={recommendations.trackBasedOnArtist} width={props.windowWidth} />
                                                                }
                                                                {
                                                                    recommendations?.albumBasedOnTrack?.result && <AlbumBasedOnTrack data={recommendations.albumBasedOnTrack} width={props.windowWidth} />
                                                                }
                                                                {
                                                                    recommendations?.tracksBasedOnTrack?.result && <TracksBasedOnTrack data={recommendations.tracksBasedOnTrack} width={props.windowWidth} />
                                                                }
                                                            </div>
                                                            :
                                                            <div className="desktop">
                                                                {
                                                                    recommendations?.trackBasedOnArtist?.result && <TrackBasedOnArtist data={recommendations.trackBasedOnArtist} width={props.windowWidth} />
                                                                }
                                                                {
                                                                    recommendations?.albumBasedOnTrack?.result && <AlbumBasedOnTrack data={recommendations.albumBasedOnTrack} width={props.windowWidth} />
                                                                }
                                                                {
                                                                    recommendations?.tracksBasedOnTrack?.result && <TracksBasedOnTrack data={recommendations.tracksBasedOnTrack} width={props.windowWidth} />
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                                :
                                                <Loading />
                                            :
                                            <NotEnoughData />
                                    }
                                </div>
                                :
                                <Loading />
                        }
                    </div>
                    :
                    <LoginWindow />
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        windowWidth: state.windowWidth,
        artists: state.artists,
        tracks: state.tracks
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS, SET_TRACKS };

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations);
