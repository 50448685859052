export function ProfileEmptyPhoto({ size }) {
    return (
        <div
            className={`profile_empty-photo`}
            style={{
                width: size,
                height: size
            }}>
            <svg width={size / 2} height={size / 2} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 9.99999C7.7835 9.99999 5.98096 8.20599 5.98096 5.99999C5.98096 3.79399 7.7835 1.99999 10 1.99999C12.2165 1.99999 14.0191 3.79399 14.0191 5.99999C14.0191 8.20599 12.2165 9.99999 10 9.99999ZM13.7759 10.673C15.3705 9.39596 16.2999 7.33094 15.9582 5.06994C15.5614 2.44693 13.369 0.347977 10.7224 0.0419769C7.07013 -0.381023 3.97144 2.44899 3.97144 5.99999C3.97144 7.88999 4.85161 9.57397 6.22411 10.673C2.85213 11.934 0.390463 14.895 0.00463407 18.891C-0.051632 19.482 0.411567 20 1.0084 20C1.51982 20 1.95589 19.616 2.0011 19.109C2.40401 14.646 5.83728 12 10 12C14.1627 12 17.596 14.646 17.9989 19.109C18.0441 19.616 18.4802 20 18.9916 20C19.5885 20 20.0516 19.482 19.9954 18.891C19.6095 14.895 17.1479 11.934 13.7759 10.673Z" fill="#1DB954" />
            </svg>
        </div>
    )
}
