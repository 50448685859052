//packages
import { useEffect, useState } from "react";
//services
import { getArtists, getAudioFeaturesForMoodSongs, getCurrentPlayer, getTracks } from "../../../services/appService";
import { printData } from "../../../utils/consoleHelpers";
import { handleErrorMessage } from "../../../utils/errorMessageHandler";
import { refreshPage, useNavigateHome } from "../../../utils/pageHandlers";
import { formatGenres, formatLovedAlbum, formatMoodSongs, formatPlaylistDecade } from "../../../services/dataFormatters";

const term = "short_term"

export const useCurrentlPlayer = (props) => {
    const [currentPlayer, setCurrentPlayer] = useState(null);
    const [currentPlayerLoaded, setCurrentPlayerLoaded] = useState(false);
    const [isNotEnoughDataCurrentPlayer, setNotEnoughDataCurrentPlayer] = useState(false);

    useEffect(() => {
        if (props.authentication_key && !props.keyNeedToUpdate) {
            setCurrentPlayerLoaded(false)
            getCurrentPlayer(props.authentication_key)
                .then(response => {
                    printData("Your Melodystats: current player", response)
                    if (response.currentlyPlayingTrack || response.lastPlayedTrack)
                        setCurrentPlayer(response)
                    else
                        setNotEnoughDataCurrentPlayer(true)
                    setCurrentPlayerLoaded(true)
                }).catch((error) => {
                    setCurrentPlayer(null)
                    setCurrentPlayerLoaded(true)
                    error.message = handleErrorMessage(error)

                    switch (error.message) {
                        case 401: {
                            props.KEY_NEED_TO_UPDATE();
                            break;
                        }
                        case 403: {
                            setNotEnoughDataCurrentPlayer(true)
                            break;
                        }
                        case 429: {
                            refreshPage()
                            break;
                        }
                        default: {
                            props.navigateHome()
                            break;
                        }
                    }
                })
        }
        return () => {
            setCurrentPlayer(null)
            setCurrentPlayerLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { currentPlayer, currentPlayerLoaded, isNotEnoughDataCurrentPlayer }
}

export const usePlayslistDecade = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        tracks,
        SET_TRACKS
    } = props

    const [playlistDecade, setPlaylistDecade] = useState(null);
    const [playlistDecadeLoaded, setPlaylistDecadeLoaded] = useState(false);
    const [isNotEnoughDataPlaylistDecade, setNotEnoughDataPlaylistDecade] = useState(false);

    const [tracksArray, setTracksArray] = useState(null);
    const [tracksLoaded, setTracksLoaded] = useState(false);
    const navigateHome = useNavigateHome();

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = tracks[term]?.updated
            const recordValue = tracks[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setPlaylistDecadeLoaded(false)
                setTracksLoaded(false)
                getTracks(authentication_key, term, 50)
                    .then(response => {
                        printData("Your Melodystats: playlist decade", response)
                        if (response.items?.length) {
                            setTracksArray(response.items)
                            SET_TRACKS(response.items)
                        } else
                            setNotEnoughDataPlaylistDecade(true)
                        setTracksLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue?.length)
                    setTracksArray(recordValue);
                else
                    setNotEnoughDataPlaylistDecade(true)
                setTracksLoaded(true)
            }
        }
        return () => {
            setTracksArray(null)
            setTracksLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key])

    useEffect(() => {
        if (tracksLoaded) {
            if (tracksArray?.length) {
                const res = formatPlaylistDecade(tracksArray)
                if (res?.decade && res.result)
                    setPlaylistDecade(res.decade)
                else {
                    setNotEnoughDataPlaylistDecade(true)
                }
            } else {
                setNotEnoughDataPlaylistDecade(true)
            }
            setPlaylistDecadeLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracksArray, tracksLoaded])

    return { playlistDecade, playlistDecadeLoaded, isNotEnoughDataPlaylistDecade }
}


export const useLovedAlbum = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        tracks,
        SET_TRACKS
    } = props

    const [lovedAlbum, setLovedAlbum] = useState(null);
    const [lovedAlbumLoaded, setLovedAlbumLoaded] = useState(false);
    const [isNotEnoughDataLovedAlbum, setNotEnoughDataLovedAlbum] = useState(false);

    const [tracksArray, setTracksArray] = useState(null);
    const [tracksLoaded, setTracksLoaded] = useState(false);
    const navigateHome = useNavigateHome();

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = tracks[term]?.updated
            const recordValue = tracks[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setLovedAlbumLoaded(false)
                setTracksLoaded(false)
                getTracks(authentication_key, term, 50)
                    .then(response => {
                        printData("Your Melodystats: loved album", response)
                        if (response.items?.length) {
                            setTracksArray(response.items)
                            SET_TRACKS(response.items)
                        } else
                            setNotEnoughDataLovedAlbum(true)
                        setTracksLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue?.length)
                    setTracksArray(recordValue);
                else
                    setNotEnoughDataLovedAlbum(true)
                setTracksLoaded(true)
            }
        }
        return () => {
            setTracksArray(null)
            setTracksLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key])

    useEffect(() => {
        if (tracksLoaded) {
            if (tracksArray?.length) {
                const result = formatLovedAlbum(tracksArray)
                if (result?.album && result?.result)
                    setLovedAlbum(result.album)
                else
                    setNotEnoughDataLovedAlbum(true)
            } else {
                setLovedAlbum(null)
                setNotEnoughDataLovedAlbum(true)
            }
            setLovedAlbumLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracksArray, tracksLoaded])

    return { lovedAlbum, lovedAlbumLoaded, isNotEnoughDataLovedAlbum }
}


export const useMoodSongs = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        tracks,
        SET_TRACKS
    } = props

    const navigateHome = useNavigateHome();

    const [moodSongs, setMoodSongs] = useState(null);
    const [moodSongsLoaded, setMoodSongsLoaded] = useState(false);
    const [isNotEnoughDataMoodSongs, setNotEnoughDataMoodSongs] = useState(false);


    const [tracksArray, setTracksArray] = useState(null);
    const [tracksLoaded, setTracksLoaded] = useState(false);

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = tracks[term]?.updated
            const recordValue = tracks[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setTracksLoaded(false)
                getTracks(authentication_key, term, 50)
                    .then(response => {
                        printData("Tracks", response.items)
                        if (response.items?.length) {
                            setTracksArray(response.items)
                            SET_TRACKS(response.items)
                        } else
                            setNotEnoughDataMoodSongs(true)
                        setTracksLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue?.length)
                    setTracksArray(recordValue);
                else
                    setNotEnoughDataMoodSongs(true)
                setTracksLoaded(true)
            }
        }
        return () => {
            setTracksArray(null)
            setTracksLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key])


    const [audioFeaturesArray, setAudioFeaturesArray] = useState(null);
    const [audioFeaturesLoaded, setAudioFeaturesLoaded] = useState(false);

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate && tracksLoaded && tracksArray?.length) {
            setMoodSongsLoaded(false)
            setAudioFeaturesLoaded(false)
            getAudioFeaturesForMoodSongs(authentication_key, tracksArray)
                .then(response => {
                    printData("Your Melodystats: moodSongs.audioFeatures", response)
                    if (response?.length) {
                        setAudioFeaturesArray(response)
                    } else
                        setNotEnoughDataMoodSongs(true)
                    setAudioFeaturesLoaded(true)
                }).catch((error) => {
                    error.message = handleErrorMessage(error)
                    switch (error.message) {
                        case 401: {
                            KEY_NEED_TO_UPDATE();
                            break;
                        }
                        case 403: {
                            LOGOUT();
                            break;
                        }
                        case 429: {
                            refreshPage()
                            break;
                        }
                        default: {
                            //navigateHome()
                            break;
                        }
                    }
                })
        }
        return () => {
            setAudioFeaturesArray(null)
            setAudioFeaturesLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, tracksLoaded])

    useEffect(() => {
        if (audioFeaturesLoaded && tracksLoaded) {
            if (tracksArray?.length >= 2 && audioFeaturesArray?.length >= 2) {
                const result = formatMoodSongs(audioFeaturesArray, tracksArray)
                if (result.result)
                    setMoodSongs(result)
                else
                    setNotEnoughDataMoodSongs(true)
            }
            else
                setNotEnoughDataMoodSongs(true)
            setMoodSongsLoaded(true)
        }
        return () => {
            setMoodSongs(null)
            setMoodSongsLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioFeaturesLoaded, tracksLoaded])

    return { moodSongs, moodSongsLoaded, isNotEnoughDataMoodSongs }
}


export const usePrefferedGenre = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        artists,
        SET_ARTISTS
    } = props

    const [artistsArray, setArtistsArray] = useState(null);
    const [artistsLoaded, setArtistsLoaded] = useState(false);
    const [isNotEnoughDataPrefferedGenre, setNotEnoughDataPrefferedGenre] = useState(false);
    const navigateHome = useNavigateHome();

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = artists[term]?.updated
            const recordValue = artists[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setArtistsLoaded(false)
                getArtists(authentication_key, term, 50)
                    .then(response => {
                        printData("Your Melodystats: prefferedGenre.artists", response.items)
                        if (response.items?.length) {
                            setArtistsArray(response.items)
                            SET_ARTISTS(response.items)
                        } else
                            setNotEnoughDataPrefferedGenre(true)
                        setArtistsLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue?.length)
                    setArtistsArray(recordValue);
                else
                    setNotEnoughDataPrefferedGenre(true)
                setArtistsLoaded(true)
            }
        }
        return () => {
            setArtistsArray(null)
            setArtistsLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key])

    const [prefferedGenre, setPrefferedGenre] = useState(null);
    const [prefferedGenreLoaded, setPrefferedGenreLoaded] = useState(false);

    useEffect(() => {
        if (artistsLoaded) {
            if (artistsArray?.length) {
                setPrefferedGenre(formatGenres(artistsArray)[0]?.genre)
            }
            else {
                setNotEnoughDataPrefferedGenre(true)
            }
            setPrefferedGenreLoaded(true)
        }
        return () => {
            setPrefferedGenre(null)
            setPrefferedGenreLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [artistsArray, artistsLoaded])

    return { prefferedGenre, prefferedGenreLoaded, isNotEnoughDataPrefferedGenre }
}



export const useArtists = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        artists,
        SET_ARTISTS
    } = props

    const [artistsArray, setArtistsArray] = useState(null);
    const [artistsLoaded, setArtistsLoaded] = useState(false);
    const [isNotEnoughDataArtists, setNotEnoughDataArtists] = useState(false);
    const navigateHome = useNavigateHome();

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = artists[term]?.updated
            const recordValue = artists[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setArtistsLoaded(false)
                getArtists(authentication_key, "short_term", 50)
                    .then(response => {
                        printData("Your Melodystats: artists", response.items)
                        if (response.items?.length) {
                            setArtistsArray(response.items)
                            SET_ARTISTS(response.items)
                        } else
                            setNotEnoughDataArtists(true)
                        setArtistsLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue?.length)
                    setArtistsArray(JSON.parse(JSON.stringify(recordValue)));
                else
                    setNotEnoughDataArtists(true)
                setArtistsLoaded(true)
            }
        }
        return () => {
            setArtistsArray(null)
            setArtistsLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, term])

    return { artists: artistsArray?.slice(0, 3), artistsLoaded, isNotEnoughDataArtists }
}


export const useTracks = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        tracks,
        SET_TRACKS,
    } = props

    const [tracksArray, setTracksArray] = useState(null);
    const [tracksLoaded, setTracksLoaded] = useState(false);
    const [isNotEnoughDataTracks, setNotEnoughDataTracks] = useState(false);
    const navigateHome = useNavigateHome();

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = tracks[term]?.updated
            const recordValue = tracks[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setTracksLoaded(false)
                getTracks(authentication_key, term, 50)
                    .then(response => {
                        printData("Your Melodystats: tracks", response.items)
                        if (response.items?.length) {
                            setTracksArray(response.items)
                            SET_TRACKS(response.items)
                        } else
                            setNotEnoughDataTracks(true)
                        setTracksLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue?.length)
                    setTracksArray(recordValue);
                else
                    setNotEnoughDataTracks(true)
                setTracksLoaded(true)
            }
        }
        return () => {
            setTracksArray(null)
            setTracksLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, term])

    return { tracks: tracksArray?.slice(0, 5), tracksLoaded, isNotEnoughDataTracks }
}