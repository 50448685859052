//style
import "./YourMelodystatsStyles.scss"
//packages
import React, { useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//utils
import { metadataYourSpotify } from "../../../utils/pagesMetadata/metadataYourSpotify";
import { useNavigateHome } from "../../../utils/pageHandlers";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS, SET_TRACKS, SET_AUDIO_FEATURES, SET_USER } from "../../../context/actions";
//hooks
import { useArtists, useCurrentlPlayer, useLovedAlbum, useMoodSongs, usePlayslistDecade, usePrefferedGenre, useTracks } from "./hooks";
//assets
import Spotify_Icon_RGB_White from "../../../assets/brands/Spotify_Icon_RGB_White.png"
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import Loading from "../../common/Loading/Loading";
import { SeeMore } from "./components/SeeMore/SeeMore";
import { InfoButton } from "../../common/InfoButton";
import { SeoHelmet } from "../../general/SeoHelmet";
import { TracksTable } from "../../components/TracksTable";
import ArtistsList from "../../components/ArtistsList/ArtistsList";

export function YourMelodystats(props) {
    const navigateHome = useNavigateHome();

    const { artists, artistsLoaded, isNotEnoughDataArtists } = useArtists({ ...props, navigateHome });
    const { currentPlayer, currentPlayerLoaded, isNotEnoughDataCurrentPlayer } = useCurrentlPlayer({ ...props, navigateHome });
    const { tracks, tracksLoaded, isNotEnoughDataTracks } = useTracks({ ...props, navigateHome });
    const { lovedAlbum, lovedAlbumLoaded, isNotEnoughDataLovedAlbum } = useLovedAlbum({ ...props, navigateHome });
    const { prefferedGenre, prefferedGenreLoaded, isNotEnoughDataPrefferedGenre } = usePrefferedGenre({ ...props, navigateHome });
    const { moodSongs, moodSongsLoaded, isNotEnoughDataMoodSongs } = useMoodSongs({ ...props, navigateHome });
    const { playlistDecade, playlistDecadeLoaded, isNotEnoughDataPlaylistDecade } = usePlayslistDecade({ ...props, navigateHome });

    const scroll50Ref = useRef(false);
    const scroll100Ref = useRef(false);

    const handleScroll = useCallback(() => {
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY;
        const pageHeight = document.documentElement.scrollHeight;
        const scrollPercentage = (scrollY / (pageHeight - windowHeight)) * 100;
        if (scrollPercentage >= 50 && !scroll50Ref.current) {
            scroll50Ref.current = true;
            if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerYourMelodystatsScroll50()
        } else if (scrollPercentage >= 100 && !scroll100Ref.current) {
            scroll100Ref.current = true;
            if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerYourMelodystatsScroll100()
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);


    function Track(props) {
        const { song } = props;
        return (
            <>
                {
                    song?.external_urls && song.album?.images[0].url && song.name && song.artists.length && song.id &&
                    <div className="block">
                        <img
                            className="track-image"
                            src={song.album?.images[0].url}
                            alt=""
                            width="100px"
                            height="100px"
                        />
                        <div className="track-data">
                            <div className="text">
                                <p className="name">{song.name}</p>
                                <p className="artists-list">
                                    {
                                        song.artists.map((artist, i) => {
                                            return (
                                                (i > 0) ? (", " + artist.name) : artist.name
                                            );
                                        })
                                    }
                                </p>
                            </div>
                            <a
                                href={`${song?.external_urls?.spotify}?si`}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={lovedAlbum.id}
                                className="spotify_logo_link"
                            >
                                <img
                                    src={Spotify_Icon_RGB_White}
                                    alt=""
                                    width="25px"
                                    height="25px"
                                />
                            </a>
                        </div>
                    </div>
                }
            </>
        )
    }

    function Artists() {
        return (
            <>
                {
                    artistsLoaded && !isNotEnoughDataArtists &&
                    <div className="artists">
                        <p className="title">Favorite artists</p>
                        <ArtistsList artists={artists} />
                        <SeeMore link={'artists'} />
                    </div>
                }
            </>
        )
    }

    function Player() {
        return (
            <>
                {
                    currentPlayerLoaded && !isNotEnoughDataCurrentPlayer &&
                    <div className="current-player">
                        <p className="title">{currentPlayer?.currentlyPlayingTrack ? 'Now playing' : 'Recently played'}</p>
                        <div className="info">
                            <Track song={currentPlayer?.currentlyPlayingTrack ? currentPlayer?.currentlyPlayingTrack : currentPlayer?.lastPlayedTrack} />
                        </div>
                    </div>
                }
            </>
        )
    }

    function Tracks() {
        return (
            <>
                {
                    tracksLoaded && !isNotEnoughDataTracks &&
                    <div className="tracks">
                        <p className="title">Favorite tracks</p>
                        <TracksTable tracksArray={tracks} indexed={false} />
                        <SeeMore link={'tracks'} />
                    </div>
                }
            </>
        )
    }

    function MobileTracks() {
        return (
            <>
                {
                    tracksLoaded && !isNotEnoughDataTracks &&
                    <div className="mobile-tracks">
                        <p className="title">Favorite tracks</p>
                        <TracksTable tracksArray={tracks} indexed={false} />
                        <SeeMore link={'tracks'} />
                    </div>
                }
            </>
        )
    }


    function Album() {
        return (
            <>
                {
                    lovedAlbumLoaded && !isNotEnoughDataLovedAlbum &&
                    <div className="album">
                        <p className="title">Loved album</p>
                        <div className="info">
                            <img
                                className="album-image"
                                src={lovedAlbum?.images[0].url}
                                alt=""
                            />
                            <div className="text">
                                <p className="name">{lovedAlbum.name}</p>
                                <p className="artist-list">By {lovedAlbum.artists.map((artist, i) => {
                                    return (
                                        (i > 0) ? (", " + artist.name) : artist.name
                                    );
                                })}</p>
                            </div>
                            <a
                                href={`${lovedAlbum?.external_urls?.spotify}?si`}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={lovedAlbum.id}
                                className="spotify_logo_link"
                            >
                                <img
                                    src={Spotify_Icon_RGB_White}
                                    alt=""
                                    width="25px"
                                    height="25px"
                                />
                            </a>
                        </div>
                    </div>
                }
            </>
        )
    }

    function Genre() {
        return (
            <>
                {
                    prefferedGenreLoaded && !isNotEnoughDataPrefferedGenre &&
                    <div className="genre">
                        <p className="title">Preffered genre</p>
                        <div className="info">
                            <p>{prefferedGenre}</p>
                        </div>
                        <SeeMore link={'genres'} />
                    </div>
                }
            </>
        )
    }

    const InfoDescriptionContentHappiestSong = () => {
        return (
            <>
                <p>This feature is based on our <Link to="/audio-features">Audio Features</Link>, mainly on the valence metric. The happiest song is the track with the highest value that you have listened to recently.</p>
                <p>* Pay attention - sometimes result might not display the real emotional level of the song, but we are already working on improving the results you get.</p>
            </>
        )
    }

    const InfoDescriptionContentSaddestSong = () => {
        return (
            <>
                <p>This feature is based on our <Link to="/audio-features">Audio Features</Link>, mainly on the valence metric. The saddest song is the track with the lowest value that you have listened to recently.</p>
                <p>* Pay attention - sometimes result might not display the real emotional level of the song, but we are already working on improving the results you get.</p>
            </>
        )
    }

    function MoodSongs() {
        return (
            <>
                {
                    moodSongsLoaded && !isNotEnoughDataMoodSongs &&
                    <div className="mood-song">
                        <div className="info">
                            <div className="happy">
                                <p className="title">Happiest song <InfoButton popupContent={<InfoDescriptionContentHappiestSong />} setPopupContent={props.setPopupContent} setPopupType={props.setPopupType} /></p>
                                <Track song={moodSongs.happiestSong} />
                            </div>
                            <div className="sad">
                                <p className="title">Saddest song <InfoButton popupContent={<InfoDescriptionContentSaddestSong />} setPopupContent={props.setPopupContent} setPopupType={props.setPopupType} /></p>
                                <Track song={moodSongs.saddestSong} />
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

    function Decade() {
        return (
            <>
                {
                    playlistDecadeLoaded && !isNotEnoughDataPlaylistDecade &&
                    <div className="decade">
                        <p className="title">Playlist's decade</p>
                        <div className="info">
                            <p>{playlistDecade}'s</p>
                        </div>
                        <SeeMore link={'ages-of-playlist'} />
                    </div>
                }
            </>
        )
    }

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataYourSpotify

    return (
        <div className="your_spotify-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="your_spotify-block">
                        {
                            lovedAlbumLoaded && tracksLoaded && artistsLoaded && moodSongsLoaded && prefferedGenreLoaded && playlistDecadeLoaded && currentPlayerLoaded ?
                                <div className={`loading-able ${lovedAlbumLoaded && tracksLoaded && artistsLoaded && moodSongsLoaded && prefferedGenreLoaded && playlistDecadeLoaded && currentPlayerLoaded ? "" : "loading-able-blur"}`}>
                                    <h1 className="page_title your_melodystats-title">Your<br />Melodystats</h1>
                                    {
                                        props.windowWidth !== null ?
                                            <div>
                                                {
                                                    props.windowWidth < 1000 ?
                                                        <div className="mobile">
                                                            <div className="grid">
                                                                <Artists />
                                                                <Player />
                                                                <MobileTracks />
                                                                <Album />
                                                                <Genre />
                                                                <MoodSongs />
                                                                <Decade />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="desktop">
                                                            <div className="grid">
                                                                <Artists />
                                                                <Player />
                                                                <Tracks />
                                                                <Album />
                                                                <Genre />
                                                                <MoodSongs />
                                                                <Decade />
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            :
                                            <Loading />

                                    }
                                </div>
                                :
                                <Loading />
                        }
                    </div>
                    :
                    <LoginWindow />
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth,
        artists: state.artists,
        tracks: state.tracks,
        audioFeatures: state.audioFeatures,
        user: state.user
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS, SET_TRACKS, SET_AUDIO_FEATURES, SET_USER };

export default connect(mapStateToProps, mapDispatchToProps)(YourMelodystats);
