//style
import "./MobileMenuStyles.scss"
//packages
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//utils
import { navigationOptionsMobile } from "../../../utils/variables/navigationOptions";
//store
import { MOBILE_MENU_SWITCH } from "../../../context/actions";
//assets
import iconDesktopMenuMoreClosed from "../../../assets/icons/dropdown-menu-closed-icon.svg"
import iconDesktopMenuMoreOpened from "../../../assets/icons/dropdown-menu-opened-icon.svg"
import handshakeEmoji from '../../../assets/emojis/64/handshake.png'
//components
import { Footer } from "../Footer/Footer";

function MobileMenu(props) {

    const [openedOptions, setOpenedOptions] = useState({});

    function handleOpenedOptions(optionName) {
        let tmp = JSON.parse(JSON.stringify(openedOptions));
        if (tmp[optionName]) {
            if (tmp[optionName].open === true) {
                tmp[optionName].open = false;
            } else {
                tmp[optionName].open = true;
            }
        } else {
            tmp[optionName] = { open: true };
        }
        setOpenedOptions(tmp);
    }

    function switchMenuValue() {
        props.MOBILE_MENU_SWITCH();
    }

    const handleOnOptionLinkClick = (optionName) => {
        handleOpenedOptions(optionName);
        switchMenuValue();
    }

    const handleClickDonate = () => {
        switchMenuValue()
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerDonate()
    }

    return (
        <div className="mobile_menu">
            {navigationOptionsMobile.map((option, i) => {
                return option.isDropdown ?
                    <div className="dropdown" key={`${option.route + "" + i}`}>
                        <div className="content">
                            <div className="dropdown-name">
                                <div className="emoji">
                                    <picture>
                                        <source srcSet={option.emojiWEBP} type="image/webp" />
                                        <source srcSet={option.emojiPNG} type="image/png" />
                                        <img
                                            src={option.emojiPNG}
                                            alt=""
                                            width="32px"
                                            height="32px"
                                        />
                                    </picture>
                                </div>
                                <div className="moreOption" onClick={() => { handleOpenedOptions(option.name) }}>
                                    <p
                                        className={`${option.options.some((e) => e?.route === window.location.pathname) ? 'highlighted_nav_link' : ''}`}
                                    >
                                        {option.name}
                                    </p>
                                    <div className="moreIcon">
                                        <img
                                            src={option.name in openedOptions === true && openedOptions[option.name].open ? iconDesktopMenuMoreOpened : iconDesktopMenuMoreClosed}
                                            alt="more"
                                            width={option.name in openedOptions === true && openedOptions[option.name].open ? "12" : "11"}
                                            height={option.name in openedOptions === true && openedOptions[option.name].open ? "10" : "13"}
                                        />
                                    </div>
                                </div>
                            </div>

                            {
                                option.name in openedOptions === true && openedOptions[option.name].open &&
                                <ul className="dropdown-options">
                                    {option.options.map((miniOption, j) => {
                                        return (
                                            <li key={`${miniOption.route + "" + j}`} >
                                                <div>
                                                    <Link onClick={() => { handleOnOptionLinkClick(option.name) }} to={miniOption.route}>
                                                        <p className={`${window.location.pathname === miniOption.route ? 'highlighted_nav_link' : ''}`}>{miniOption.name}</p>
                                                    </Link>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            }
                        </div>

                    </div>
                    :
                    <Link
                        onClick={() => { switchMenuValue() }}
                        className="common"
                        key={`${option.route + "" + i}`}
                        to={option.route}
                    >
                        <div className="emoji">
                            <picture>
                                <source srcSet={option.emojiWEBP} type="image/webp" />
                                <source srcSet={option.emojiPNG} type="image/png" />
                                <img
                                    src={option.emojiPNG}
                                    alt=""
                                    width="32px"
                                    height="32px"
                                />
                            </picture>
                        </div>
                        <div className="name">
                            <p className={`${window.location.pathname === option.route ? 'highlighted_nav_link' : ''}`}>{option.name}</p>
                        </div>
                    </Link>
            })}
            <a
                onClick={() => { handleClickDonate() }}
                className="common"
                href="https://patreon.com/melodystats"
                target="_blank"
                rel="noreferrer"
                alt=""
            >
                <div className="emoji">
                    <img
                        src={handshakeEmoji}
                        alt=""
                        width="32px"
                        height="32px"
                    />
                </div>
                <div className="name">
                    <p>Donate us</p>
                </div>
            </a>
            {
                props.authentication_key
                    ?
                    <Footer />
                    :
                    props.windowWidth !== null
                        ?
                        <Footer />
                        :
                        null
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        mobileMenuOpen: state.mobileMenuOpen,
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        windowWidth: state.windowWidth
    };
};

const mapDispatchToProps = { MOBILE_MENU_SWITCH };

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);