//style
import "./ArtistsListStyles.scss"
//packages
import React from "react";
//assets
import Spotify_Icon_RGB_White from "../../../assets/brands/Spotify_Icon_RGB_White.png"

function ArtistsList({ artists }) {
    return (
        <div className="artists-list-wrapper">
            <div className="artists-list">
                {artists.map((artist) => {
                    return (
                        <div className="artists-card" key={artist.id}>
                            <img
                                src={artist?.images[0]?.url}
                                alt=""
                                className="artist-image"
                            />
                            <p className="artist-name">{artist.name}</p>
                            <a
                                href={`${artist?.external_urls?.spotify}?si`}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={artist.id}
                            >
                                <img
                                    src={Spotify_Icon_RGB_White}
                                    alt=""
                                    width="25px"
                                    height="25px"
                                    className="spotify_logo_link"
                                />
                            </a>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default ArtistsList