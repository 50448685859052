//packages
import { useEffect, useState } from "react";
//utils
import { printData } from "../../../utils/consoleHelpers";
import { handleErrorMessage } from "../../../utils/errorMessageHandler";
import { refreshPage, useNavigateHome } from "../../../utils/pageHandlers";
//services
import { getUserData } from "../../../services/appService";

export const useProfile = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        user,
        userUpdatedTime,
        SET_USER
    } = props

    const [profile, setProfile] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const navigateHome = useNavigateHome();

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = userUpdatedTime
            const recordValue = user
            const recordValid = recordValue ? true : false
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setLoaded(false)
                getUserData(authentication_key)
                    .then(response => {
                        printData("Profile", response)
                        setProfile(response)
                        SET_USER(response)
                        setLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue)
                    setProfile(recordValue)
                setLoaded(true)
            }
        }
        return () => {
            setLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key])

    return { profile, loaded }
}