export function IcebergMobile() {
    return (
        <svg width="280" height="1077" viewBox="0 0 280 1077" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.5 19.3016L15 0H126.75H238.5L269.5 27.4286L267.5 67.0476L275.5 78.2222L279 104.635L269.5 123.937L279 141.206L269.5 160H6.72289L3.36145 145.27L19 155.429L1.68072 137.905L0 130.54L6.72289 91.9365L12.5 69.0794L6.72289 37.0794L26.5 19.3016Z" fill="white" />
            <path d="M226 885H55.9999L53.5 848.5L44 840.5L35 803.5L30 791L20.25 772.5L23 762.5L32 755L21.5 759.5L18.5 768L12 750H250.762L247 762.5L255.5 750H269L255.5 772.5L251.5 784L241 797.5V837L226 856V885Z" fill="#0066FF" />
            <path d="M226 885H55.9999L53.5 848.5L44 840.5L35 803.5L30 791L20.25 772.5L23 762.5L32 755L21.5 759.5L18.5 768L12 750H250.762L247 762.5L255.5 750H269L255.5 772.5L251.5 784L241 797.5V837L226 856V885Z" fill="black" fillOpacity="0.4" />
            <path d="M140.5 1076.5L121 1044L112 1035.5L121 1029H105L96.5 1020L100 1003.5L89 976.5L76 953L73 929.5L99.5 904.5L69.5 918L56.9999 895H225.5L215.5 923.5L221 953L212 961H196L207 965.5L198 973.5L182.5 1016L149.5 1044L140.5 1076.5Z" fill="#0066FF" />
            <path d="M140.5 1076.5L121 1044L112 1035.5L121 1029H105L96.5 1020L100 1003.5L89 976.5L76 953L73 929.5L99.5 904.5L69.5 918L56.9999 895H225.5L215.5 923.5L221 953L212 961H196L207 965.5L198 973.5L182.5 1016L149.5 1044L140.5 1076.5Z" fill="black" fillOpacity="0.6" />
            <path d="M5 170H270L273.5 177.613V196.391L278.5 228.872L273.5 263.891V305H7.49988L3.74994 287.237L15.5 270.996L1.87497 278.355L0 269.474V234.455L9.5 225.32L7.49988 212.124L3.74994 199.69L1.87497 193.473L5.93748 190.618L10 187.763L0.5 189.286L0 187.256L5 170Z" fill="white" />
            <path d="M7.97162 315H272.029L278.505 341.5L272.029 345L278.505 352L275.516 397.125L280 426L267.046 425L280 438L276.662 450H0V409.5L1.99291 405.375L11.9573 407L5.97872 397.125L7.97162 393L2.98932 350.5L11.9573 341.5L7.97162 315Z" fill="white" />
            <path d="M0 460H277V478.5L280 503L277 512.5L280 552V595H0L4.5 576L0 542L12.9999 539.5L0 539V460Z" fill="#0066FF" />
            <path d="M272.028 740H140H97.153H7.97143L2.98932 683.5L8.96797 689.5L11.9573 703.5V688.5L2.98932 680.5V677L4.9822 621L-6.61612e-06 605H280V634L269.537 641L276.512 638.5H280V672L275.018 683L280 713.5L272.028 740Z" fill="#0066FF" />
            <path d="M272.028 740H140H97.153H7.97143L2.98932 683.5L8.96797 689.5L11.9573 703.5V688.5L2.98932 680.5V677L4.9822 621L-6.61612e-06 605H280V634L269.537 641L276.512 638.5H280V672L275.018 683L280 713.5L272.028 740Z" fill="black" fillOpacity="0.25" />
        </svg>

    )
}