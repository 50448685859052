export function handleErrorMessage(error) {
    if (error.message.match(/401/g)) {
        return 401
    } else if (error.message.match(/403/g)) {
        return 403
    } else if (error.message.match(/429/g)) {
        return 429
    } else {
        return "default"
    }
}