//style
import "./SeeMore.scss";
//packages
import React from "react";
import { Link } from "react-router-dom";

export function SeeMore({ link }) {
    const handleSeeMoreClick = () => {
        if (process.env.REACT_APP_ENV === 'production')
            switch (link) {
                case 'artists': {
                    window?.eventTriggerSeeMoreArtists()
                    break;
                }
                case 'tracks': {
                    window?.eventTriggerSeeMoreTracks()
                    break;
                }
                case 'genres': {
                    window?.eventTriggerSeeMoreGenres()
                    break;
                }
                case 'ages-of-playlist': {
                    window?.eventTriggerSeeMoreAgesOfPlaylist()
                    break;
                }
                default:
                    break;
            }
    }

    return (
        <Link
            className="see_more"
            to={`/${link}`}
            onClick={() => handleSeeMoreClick()}
        >
            See more →
        </Link>
    )
}