import batteryPNG from "../../assets/emojis/64/battery.png"
import hawkPNG from "../../assets/emojis/64/hawk.png"
import horsePNG from "../../assets/emojis/64/horse.png"
import microphone_classicPNG from "../../assets/emojis/64/microphone_classic.png"
import microphonePNG from "../../assets/emojis/64/microphone.png"
import racecarPNG from "../../assets/emojis/64/racecar.png"
import radioPNG from "../../assets/emojis/64/radio.png"
import rock_guitarPNG from "../../assets/emojis/64/rock_guitar.png"
import speaker_loudPNG from "../../assets/emojis/64/speaker_loud.png"
import theaterPNG from "../../assets/emojis/64/theater.png"
import whalePNG from "../../assets/emojis/64/whale.png"
import woman_dancingPNG from "../../assets/emojis/64/woman_dancing.png"
import batteryWEBP from "../../assets/emojis/64/battery.webp"
import hawkWEBP from "../../assets/emojis/64/hawk.webp"
import horseWEBP from "../../assets/emojis/64/horse.webp"
import microphone_classicWEBP from "../../assets/emojis/64/microphone_classic.webp"
import microphoneWEBP from "../../assets/emojis/64/microphone.webp"
import racecarWEBP from "../../assets/emojis/64/racecar.webp"
import radioWEBP from "../../assets/emojis/64/radio.webp"
import rock_guitarWEBP from "../../assets/emojis/64/rock_guitar.webp"
import speaker_loudWEBP from "../../assets/emojis/64/speaker_loud.webp"
import theaterWEBP from "../../assets/emojis/64/theater.webp"
import whaleWEBP from "../../assets/emojis/64/whale.webp"
import woman_dancingWEBP from "../../assets/emojis/64/woman_dancing.webp"

export const audioFeaturesIcons = {
    Acousticness: {
        png: radioPNG,
        webp: radioWEBP
    },
    Danceability: {
        png: woman_dancingPNG,
        webp: woman_dancingWEBP
    },
    Energy: {
        png: batteryPNG,
        webp: batteryWEBP
    },
    Instrumentalness: {
        png: rock_guitarPNG,
        webp: rock_guitarWEBP
    },
    Liveness: {
        png: microphonePNG,
        webp: microphoneWEBP
    },
    Loudness: {
        png: speaker_loudPNG,
        webp: speaker_loudWEBP
    },
    Major: {
        png: hawkPNG,
        webp: hawkWEBP
    },
    Minor: {
        png: whalePNG,
        webp: whaleWEBP
    },
    Both: {
        png: horsePNG,
        webp: horseWEBP
    },
    Speechiness: {
        png: microphone_classicPNG,
        webp: microphone_classicWEBP
    },
    Tempo: {
        png: racecarPNG,
        webp: racecarWEBP
    },
    Valence: {
        png: theaterPNG,
        webp: theaterWEBP
    },
}