//style
import "./LastPlayedTracksStyles.scss"
//packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//utils
import { metadataLastPlayedTracks } from "../../../utils/pagesMetadata/metadataLastPlayedTracks";
import { handleErrorMessage } from "../../../utils/errorMessageHandler";
import { refreshPage, useNavigateHome } from "../../../utils/pageHandlers";
import { printData } from "../../../utils/consoleHelpers";
//services
import { getLastPlayedTracks } from "../../../services/appService";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT } from "../../../context/actions";
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import Loading from "../../common/Loading/Loading";
import NotEnoughData from "../../common/NotEnoughData/NotEnoughData";
import { SeoHelmet } from "../../general/SeoHelmet";
import { TracksTable } from "../../components/TracksTable";

export function LastPlayedTracks(props) {
    const navigateHome = useNavigateHome();

    const [lastPlayedTracks, setLastPlayedTracks] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [isNotEnoughData, setNotEnoughData] = useState(false);

    useEffect(() => {
        if (props.authentication_key && !props.keyNeedToUpdate) {
            setLoaded(false)
            getLastPlayedTracks(props.authentication_key)
                .then(response => {
                    printData("Last played tracks", response)
                    if (response.length)
                        setLastPlayedTracks(response)
                    else
                        setNotEnoughData(true)
                    setLoaded(true)
                }).catch((error) => {

                    error.message = handleErrorMessage(error)
                    switch (error.message) {
                        case 401: {
                            props.KEY_NEED_TO_UPDATE();
                            break;
                        }
                        case 403: {
                            props.LOGOUT();
                            break;
                        }
                        case 429: {
                            refreshPage()
                            break;
                        }
                        default: {
                            navigateHome()
                            break;
                        }
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.authentication_key, props.term])

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataLastPlayedTracks

    return (
        <div className="last_played_tracks-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="last_played_tracks-block">
                        <h1 className="page_title">Last played tracks</h1>
                        {
                            loaded ?
                                <div className={`loading-able ${loaded ? "" : "loading-able-blur"}`}>
                                    {
                                        !isNotEnoughData ?
                                            props.windowWidth !== null && lastPlayedTracks ?
                                                <TracksTable tracksArray={lastPlayedTracks} />
                                                :
                                                <Loading />
                                            :
                                            <NotEnoughData />
                                    }
                                </div>
                                :
                                <Loading />
                        }
                    </div>
                    :
                    <LoginWindow />
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT };

export default connect(mapStateToProps, mapDispatchToProps)(LastPlayedTracks);
