//packages
import { Squash as Hamburger } from 'hamburger-react';
import React from 'react';
import { connect } from "react-redux";
import { MOBILE_MENU_SWITCH } from "../../../context/actions";

const BurgerButton = (props) => {
    const handleToggle = () => {
        props.MOBILE_MENU_SWITCH()
    };

    return (
        <Hamburger
            toggled={props.mobileMenuOpen}
            toggle={handleToggle}
            size={30}
            duration={0.4}
            color={'#1DB954'}
            distance="lg"
            rounded
        />
    );
};

const mapStateToProps = (state) => {
    return {
        mobileMenuOpen: state.mobileMenuOpen
    };
};

const mapDispatchToProps = { MOBILE_MENU_SWITCH };
export default connect(mapStateToProps, mapDispatchToProps)(BurgerButton);