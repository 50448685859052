export function IcebergDesktop() {
    return (
        <svg width="700" height="1089" viewBox="0 0 700 1089" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M204.5 34.6556L199.5 0.000330545L460.5 0L482.5 15.8792V26.5361L496.5 34.6556V68.656L514 89.9696L509.5 116.865L521.5 102.149L534 110.776L555.5 143.761L559.5 160H124L138.5 130.06L161 109.761L156.5 96.313L164.5 90.4771L176.5 96.313V82.8651L192 72.7157L184.5 59.0141L204.5 34.6556Z" fill="white" />
            <path d="M502.202 885H198.583L192.292 874L189.146 868.5L192.292 862.5L208 860.5L190.889 856.812L181.779 858.875L177.557 854.75L169.115 846.5L164 819.5L158.5 808.5L155.75 803L166.375 800L177 785.5L165 797.25L153 797.5L139.646 777.5L120 750H579L559 787L553 791.5L548.5 779.5L549.5 794L540.5 802.5L545 819.5L519 838L516.5 863.5L502.202 885Z" fill="#0066FF" />
            <path d="M502.202 885H198.583L192.292 874L189.146 868.5L192.292 862.5L208 860.5L190.889 856.812L181.779 858.875L177.557 854.75L169.115 846.5L164 819.5L158.5 808.5L155.75 803L166.375 800L177 785.5L165 797.25L153 797.5L139.646 777.5L120 750H579L559 787L553 791.5L548.5 779.5L549.5 794L540.5 802.5L545 819.5L519 838L516.5 863.5L502.202 885Z" fill="black" fillOpacity="0.4" />
            <path d="M383 1031L344.5 1088.5L317 1049L285 1037.5L273.5 1012L244 1005L262.5 979L273 964L258.375 974.188L254.25 969.375L246 959.75L229.5 940.5L203 895H498.5L481.5 918.5L493 940.5L473 952L466.25 946.25L442 942L459 949.5L463 957.75L453 963.5L438.5 1005L424.625 1011.5L410.75 990V1018L383 1031Z" fill="#0066FF" />
            <path d="M383 1031L344.5 1088.5L317 1049L285 1037.5L273.5 1012L244 1005L262.5 979L273 964L258.375 974.188L254.25 969.375L246 959.75L229.5 940.5L203 895H498.5L481.5 918.5L493 940.5L473 952L466.25 946.25L442 942L459 949.5L463 957.75L453 963.5L438.5 1005L424.625 1011.5L410.75 990V1018L383 1031Z" fill="black" fillOpacity="0.6" />
            <path d="M118.579 170H566.615L579.82 178L589.417 190L581.02 226L594.215 196L599.013 202L619.5 217L616 226L606 244.5L628.5 228L636.5 258.5L655 266L659 285L670 287.5L680 295V305H350.399H208.698H137.847H102.422L76 291.5L89 305H33V284.5L46.5 265L71.5 236.5L90.9886 245.5V227.75L99.9855 219L104.184 202L118.579 170Z" fill="white" />
            <path d="M33.5 315H680.071L687 326L677 342L696.263 352L697.198 363.5L662.5 372H697.198L694.411 398.5L700 424L694.411 430.5L700 450H5L0 409.5L19.9291 393L7.47331 350.5L22.5 358L19.9291 343L29.8932 341.5L33.5 315Z" fill="white" />
            <path d="M6 460H700L692.5 478.5L700 503L692.5 512.5L685.5 532.5L680.5 558.5L660.5 571L653.5 595H44L49.625 585.5L52.4375 580.75L71 587.5L55.5 575L41.5 571L32.125 559.25L23.5 545L9 542.5L4 536.5L0 521.5L18.1998 507.5L9 508.5L12.5 495.5L6 460Z" fill="#0066FF" />
            <path d="M584.973 740H576.431V712.5L567.148 740H557.864H539.298H507.531H358.999H116.5L104.5 728.5V717.5L95.25 715.5L74 691L62.363 659.75L112.5 634L56.5444 644.125L50.726 628.5L37.5 623L44 605H653L645.5 631.5L639 637L623.5 643.5L639 655.5L609.494 659.5L584.973 655.5L608.237 662.5L631.5 664.125V672L618 684.5V712.5L597 721.5L584.973 740Z" fill="#0066FF" />
            <path d="M584.973 740H576.431V712.5L567.148 740H557.864H539.298H507.531H358.999H116.5L104.5 728.5V717.5L95.25 715.5L74 691L62.363 659.75L112.5 634L56.5444 644.125L50.726 628.5L37.5 623L44 605H653L645.5 631.5L639 637L623.5 643.5L639 655.5L609.494 659.5L584.973 655.5L608.237 662.5L631.5 664.125V672L618 684.5V712.5L597 721.5L584.973 740Z" fill="black" fillOpacity="0.25" />
        </svg>
    )
}
