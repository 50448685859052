import { React } from 'react';
import './SpotifyBrandStyles.scss'
import Spotify_Logo_RGB_Green from "../../../assets/brands/Spotify_Logo_RGB_Green.png"

export function SpotifyBrand() {
    return (
        <img
            src={Spotify_Logo_RGB_Green}
            alt=""
            width="100px"
            height="auto"
            className="spotify_branding"
        />
    );
}