//style
import "./HeaderStyles.scss"
//packages
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
//utils
import { navigationOptionsDesktop } from "../../../utils/variables/navigationOptions";
import { closeBlurMain, openBlurMain } from "../../../utils/contentBlur/contentBlur";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, MOBILE_MENU_SWITCH, CLOSE_BLUR, OPEN_BLUR, SET_USER } from "../../../context/actions";
//assets
import melodystatsLogoPNG from "../../../assets/branding/melodystats-logo-round.png"
import melodystatsLogoWEBP from "../../../assets/branding/melodystats-logo-round.webp"
import iconDesktopMenuMoreClosed from "../../../assets/icons/dropdown-menu-closed-icon.svg"
import iconDesktopMenuMoreOpened from "../../../assets/icons/dropdown-menu-opened-icon.svg"
import { ProfileEmptyPhoto } from "../../../assets/special/profileEmptyPhoto";
import BurgerButton from "../BurgerButton/BurgerButton";
//import { useProfile } from "./hooks";

function Header(props) {
    /* const { profile, profileLoaded } = useProfile(props)
    const [profilePhoto, setProfilePhoto] = useState(null);
    useEffect(() => {
        if (profile && profileLoaded)
            if (profile?.images[0]?.url)
                setProfilePhoto(profile.images[0].url)
    }, [profile, profileLoaded]) */

    const [openedOptions, setOpenedOptions] = useState({});

    function handleOpenedOptions(optionName) {
        let tmp = JSON.parse(JSON.stringify(openedOptions));
        if (optionName === 'close') {
            closeBlurMain()
            Object.keys(tmp).forEach((e) => {
                tmp[e].open = false;
            })
        } else {
            if (tmp[optionName]) {
                if (tmp[optionName].open === true) {
                    tmp[optionName].open = false;
                    closeBlurMain()
                } else {
                    tmp[optionName].open = true;
                    openBlurMain()
                }
            } else {
                tmp[optionName] = { open: true };
                openBlurMain()
            }
        }
        setOpenedOptions(tmp);
    }

    const location = useLocation();
    useEffect(() => {
        setOpenedOptions({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                handleOpenedOptions('close')
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownRef]);

    useEffect(() => {
        const handleScrollOutside = () => {
            handleOpenedOptions('close')
        };
        window.addEventListener('scroll', handleScrollOutside);
        return () => {
            window.removeEventListener('scroll', handleScrollOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickDonate = () => {
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerDonate()
    }

    return (
        <nav className="navigation">
            {
                props.windowWidth < 1000 ?
                    <menu className="mobile">
                        <div className="branding">
                            <Link to={"/"}>
                                <div className="logo">
                                    <picture>
                                        <source srcSet={melodystatsLogoWEBP} type="image/webp" />
                                        <source srcSet={melodystatsLogoPNG} type="image/png" />
                                        <img
                                            src={melodystatsLogoPNG}
                                            alt=""
                                            width="40px"
                                            height="40px"
                                        />
                                    </picture>
                                </div>
                                <div className="naming">
                                    <span>Melodystats</span>
                                </div>
                            </Link>
                        </div>
                        <div className="burgerMenu" >
                            <BurgerButton />
                        </div>
                    </menu>
                    :
                    <menu className="desktop">
                        <div className="branding">
                            <Link to={"/"}>
                                <div className="logo">
                                    <picture>
                                        <source srcSet={melodystatsLogoWEBP} type="image/webp" />
                                        <source srcSet={melodystatsLogoPNG} type="image/png" />
                                        <img
                                            src={melodystatsLogoPNG}
                                            alt=""
                                            width="40px"
                                            height="40px"
                                        />
                                    </picture>
                                </div>
                                <div className="naming">
                                    <span>Melodystats</span>
                                </div>
                            </Link>
                        </div>
                        <div className="general_navigation">
                            {navigationOptionsDesktop.map((option, i) => {
                                return option.isDropdown ?
                                    <div key={`${option.route + "" + i}`} ref={dropdownRef}>
                                        <div className="moreOption" onClick={() => { handleOpenedOptions(option.name) }}>
                                            <p
                                                className={`${option.options.some((e) => e?.route === window.location.pathname) ? 'highlighted_nav_link' : ''} option_name`}
                                            >
                                                {option.name}</p>
                                            <div className="moreIcon">
                                                <img
                                                    src={option.name in openedOptions === true && openedOptions[option.name].open ? iconDesktopMenuMoreOpened : iconDesktopMenuMoreClosed}
                                                    alt="more"
                                                    width={option.name in openedOptions === true && openedOptions[option.name].open ? "12" : "11"}
                                                    height={option.name in openedOptions === true && openedOptions[option.name].open ? "10" : "13"}
                                                />
                                            </div>
                                        </div>
                                        {
                                            option.name in openedOptions === true && openedOptions[option.name].open &&
                                            <div className="dropdown_menu" style={{ "position": "absolute" }} onBlur={() => { handleOpenedOptions(option.name) }}>
                                                <ul className="dropdown_menu-content">
                                                    {option.options.map((miniOption, j) => {
                                                        return (
                                                            <Link
                                                                onClick={() => { handleOpenedOptions(option.name) }}
                                                                key={`${miniOption.route + "" + j}`}
                                                                to={miniOption.route}
                                                                className={`${window.location.pathname === miniOption.route ? 'highlighted_nav_link' : ''}`}
                                                            >
                                                                <li >{miniOption.name}</li>
                                                            </Link>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <Link
                                        key={`${option.route + "" + i}`}
                                        to={option.route}
                                        className={`${window.location.pathname === option.route ? 'highlighted_nav_link' : ''}`}
                                    >
                                        <p>{option.name}</p>
                                    </Link>
                            })}
                            <a onClick={() => handleClickDonate()} href="https://patreon.com/melodystats" target="_blank" rel="noreferrer" alt=""><p>Donate</p></a>
                        </div>
                        <div className="user_navigation">
                            {props.authentication_key ?
                                <Link to="/profile" className="profile">
                                    {/* {
                                        profilePhoto
                                            ?
                                            <img
                                                src={profilePhoto}
                                                alt=""
                                                width="40px"
                                                height="40px"
                                            />
                                            :
                                            <ProfileEmptyPhoto size={40} />
                                    } */}
                                    <ProfileEmptyPhoto size={40} />
                                </Link>
                                :
                                <Link to="/" className="login">
                                    <div>
                                        <p>Login</p>
                                    </div>
                                </Link>
                            }
                        </div>
                    </menu>
            }
        </nav>
    );
}

const mapStateToProps = (state) => {
    return {
        mobileMenuOpen: state.mobileMenuOpen,
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        windowWidth: state.windowWidth,
        user: state.user,
        userUpdatedTime: state.userUpdatedTime
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, MOBILE_MENU_SWITCH, CLOSE_BLUR, OPEN_BLUR, SET_USER };

export default connect(mapStateToProps, mapDispatchToProps)(Header);