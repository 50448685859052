//style
import "./TermSwitcherStyles.scss"
//packages
import React, { Fragment } from "react";
import { connect } from "react-redux";
//components
import { termList } from "../../../utils/variables/termList";
//store
import { SWITCH_TERM } from "../../../context/actions";


function TermSwitcher(props) {

    const switchTerm = (term) => {
        props.SWITCH_TERM(term);
    };

    return (
        <div className="term_switcher-page">
            <form>
                {termList.map((term, i) => {
                    return (
                        <Fragment key={"fragment" + term.termValue + i}>
                            <input key={"input" + term.termValue + i} id={i} type="radio" name="rad" value={i} defaultChecked={(term.termValue === props.term)} />
                            <label key={"label" + term.termValue + i} htmlFor={i} onClick={() => { switchTerm(term.termValue) }} >
                                {term.termName}
                            </label>
                        </Fragment>
                    );
                })}
            </form>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        term: state.term
    };
};

const mapDispatchToProps = {
    SWITCH_TERM
};

export default connect(mapStateToProps, mapDispatchToProps)(TermSwitcher);
