//style
import "./InfoButtonStyles.scss"
//packages
import React from "react";

export function InfoButton({ visible = true, popupContent, setPopupContent, setPopupType }) {
    const handleInfoClick = () => {
        //const elementToScrollTo = document.getElementById("targetElementInfoScrollTo");
        //elementToScrollTo.scrollIntoView({ behavior: "smooth" });
        if (visible) {
            setPopupContent(popupContent)
            setPopupType('info')
        }
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerInfo()
    }

    return (
        <span className="info-button" onClick={handleInfoClick}>ⓘ</span>
    );
}