import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Popup from "./components/common/Popup/Popup";
import Main from "./components/main/Main";

function App() {
  const [popupContent, setPopupContent] = useState(null)
  const [popupType, setPopupType] = useState('')

  if (process.env.REACT_APP_ENV === 'production') {
    console.log = function () { };
  }

  return (
    <div className="App">
      <Router>
        <Main setPopupContent={setPopupContent} setPopupType={setPopupType} />
        <Popup
          type={popupType}
          setPopupContent={setPopupContent}
          setPopupType={setPopupType}
        >
          {popupContent}
        </Popup>
      </Router>
    </div>
  );
}

export default App;