//style
import "./TracksTableStyles.scss"
//packages
import React from "react";
//assets
import Spotify_Icon_RGB_White from "../../../assets/brands/Spotify_Icon_RGB_White.png"

export function TracksTable(props) {
    const { tracksArray, indexed = true } = props

    return (
        <table className="tracks-table" border={0} cellSpacing={0} cellPadding={0}>
            <tbody>
                {tracksArray?.map((track, i) => {
                    return (
                        <tr key={`${track.id}-${i}`}>
                            {
                                indexed &&
                                <td className="number">
                                    <p>{i + 1}</p>
                                </td>
                            }
                            <td className="image">
                                <img
                                    src={track.album?.images[1]?.url || track.album?.images[0]?.url}
                                    alt=""
                                    width="45px"
                                    height="45px"
                                />
                            </td>
                            <td className="credentials">
                                <p className="credentials-track_name">{track.name}</p>
                                <p className="credentials-artists">
                                    {track.artists.map((artist, i) => {
                                        return (
                                            (i > 0) ? (", " + artist.name) : artist.name
                                        );
                                    })}
                                </p>
                            </td>
                            <td className="tracks-table-album_name">
                                <p>{track.album.name}</p>
                            </td>
                            {/* 
                            <td className="time">
                                <p>{Math.floor((track.duration_ms / 1000 / 60) % 60) + ":" + (Math.floor((track.duration_ms / 1000) % 60)).toString().padStart(2, "0")}</p>
                            </td>
                             */}
                            <td className="link">
                                <a
                                    href={`${track?.external_urls?.spotify}?si`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={track.id}
                                >
                                    <img
                                        src={Spotify_Icon_RGB_White}
                                        alt=""
                                        width="25px"
                                        height="25px"
                                    />
                                </a>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default TracksTable;
