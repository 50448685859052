export const highestQualityImageFinder = (profileImg) => {
    const images = JSON.parse(JSON.stringify(profileImg))
    if (images?.length) {
        function compareWidth(a, b) {
            if (a.width < b.width) {
                return 1;
            }
            if (a.width > b.width) {
                return -1;
            }
            return 0;
        }
        images.sort(compareWidth)
        return images[0]?.url
    } else {
        return null
    }
}