const blurElement = (elementClass, classToAdd) => {
    const element = document.querySelector(elementClass);
    element.classList.add(classToAdd);
}

const unBlurElement = (elementClass, classToAdd) => {
    const element = document.querySelector(elementClass);
    element.classList.remove(classToAdd);
}

export const openBlurMain = () => {
    blurElement('.main', 'blured-main')
}

export const closeBlurMain = () => {
    unBlurElement('.main', 'blured-main')
}

export const openBlurBody = () => {
    blurElement('.body', 'blured-body')
}

export const closeBlurBody = () => {
    unBlurElement('.body', 'blured-body')
}