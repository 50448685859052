import { GET_USER, GET_ARTISTS, GET_TRACKS, GET_AUDIO_FEATURES, GET_RECOMMENDATIONS, GET_RECENTLY_PLAYED, GET_QUEUE } from "./apiService";
import { formatAgeOfPlaylist, formatAudioFeatures, formatDataForRecommendationTrackBasedOnArtist, formatDataForRecommendationTracksBasedOnTrack, formatFestivalPoster, formatGenres, formatIceberg, formatLovedAlbum, formatMoodColor, formatMoodSongs, formatPlaylistDecade, formatRecommendationAlbumBasedOnTrack, formatRecommendationTrackBasedOnArtist, formatTracksToIds, formatVerdictAges, formatVerdictFestivalPoster, formatVerdictForGenres } from "./dataFormatters";
import { defaultApiErrorHandler } from "./errorHandlers";


export async function getUserData(authentication_key) {
    const response = await GET_USER(authentication_key);
    defaultApiErrorHandler(response);
    return response.data;
}


export async function getArtists(authentication_key, term = "short_term", limit = 50) {
    const response = await GET_ARTISTS(authentication_key, term, limit);
    defaultApiErrorHandler(response);
    return response.data;
}


export async function getTracks(authentication_key, term = "short_term", limit = 50) {
    const response = await GET_TRACKS(authentication_key, term, limit);
    defaultApiErrorHandler(response);
    return response.data;
}


export async function getAudioFeatures(authentication_key, term) {
    return GET_TRACKS(authentication_key, term, 50)
        .then((response) => {
            defaultApiErrorHandler(response);
            const ids = formatTracksToIds(response.data);
            return GET_AUDIO_FEATURES(authentication_key, ids)
        })
        .then((response) => {
            defaultApiErrorHandler(response);
            return formatAudioFeatures(response.data.audio_features);
        });
}


export async function getLastPlayedTracks(authentication_key) {
    const response = await GET_RECENTLY_PLAYED(authentication_key, 50);
    defaultApiErrorHandler(response);
    return response.data.items.map((e) => { return e.track });
}


export async function getLastPlayedTrack(authentication_key) {
    const response = await GET_RECENTLY_PLAYED(authentication_key, 1);
    defaultApiErrorHandler(response);
    return response.data.items[0].track;
}


export async function getCurrentlyPlayingTrack(authentication_key) {
    const response = await GET_QUEUE(authentication_key);
    defaultApiErrorHandler(response);
    return response.data.currently_playing;
}


export async function getCurrentPlayer(authentication_key) {
    const queue = await GET_QUEUE(authentication_key);
    defaultApiErrorHandler(queue);

    return GET_RECENTLY_PLAYED(authentication_key, 1)
        .then((response) => {
            defaultApiErrorHandler(response);
            const currentlyPlayingTrack = queue?.data.currently_playing
            const lastPlayedTrack = response?.data?.items[0]?.track;

            return {
                currentlyPlayingTrack: currentlyPlayingTrack,
                lastPlayedTrack: lastPlayedTrack
            }
        })
}


export async function getGenres(authentication_key, term) {
    return GET_ARTISTS(authentication_key, term, 50)
        .then((response) => {
            defaultApiErrorHandler(response);
            return formatGenres(response.data.items);
        })
}


export async function getVerdictGenre(authentication_key) {
    const tracks = await GET_TRACKS(authentication_key, "short_term", 50);
    defaultApiErrorHandler(tracks);
    return GET_ARTISTS(authentication_key, "short_term", 50)
        .then((response) => {
            defaultApiErrorHandler(response);
            if (response.data.items.length && tracks.data.items.length) {
                const formattedGenres = formatGenres(response.data.items);
                const verdict = formatVerdictForGenres(formattedGenres, response.data.items, tracks.data.items);

                return {
                    genre: formattedGenres[0].genre,
                    verdict: verdict.result ? verdict.artist : null,
                    result: true
                }
            } else
                return {
                    result: false
                }
        })
}


export async function getPrefferedGenre(authentication_key) {
    return GET_ARTISTS(authentication_key, "short_term", 50)
        .then((response) => {
            defaultApiErrorHandler(response);
            if (response.data.items.length)
                return { genre: formatGenres(response.data.items)[0].genre, result: true }
            else
                return { result: false }
        })
}


export async function getPlayslistDecade(authentication_key) {
    return GET_TRACKS(authentication_key, "short_term", 50)
        .then((response) => {
            defaultApiErrorHandler(response)
            if (response.data.items.length) {
                const res = formatPlaylistDecade(response.data.items)
                return res
            }
            return { result: false }
        })
}


export async function getAgesOfPlaylist(authentication_key, term) {
    return GET_TRACKS(authentication_key, term, 50)
        .then((response) => {
            defaultApiErrorHandler(response);

            return formatAgeOfPlaylist(response.data.items);
        })
}


export async function getIceberg(authentication_key, term) {
    return GET_ARTISTS(authentication_key, term, 50)
        .then((response) => {
            defaultApiErrorHandler(response);

            return formatIceberg(response.data.items);
        })
}


export async function getFestivalPoster(authentication_key, term) {
    return GET_ARTISTS(authentication_key, term, 50)
        .then((response) => {
            defaultApiErrorHandler(response);
            //return response.data.items;
            return formatFestivalPoster(response.data.items);
        })
}


export async function getLovedAlbum(authentication_key) {
    return GET_TRACKS(authentication_key, "short_term", 50)
        .then((response) => {
            defaultApiErrorHandler(response);

            return formatLovedAlbum(response.data.items);
        })
}


export async function getMoodSongs(authentication_key) {
    const tracks = await GET_TRACKS(authentication_key, "short_term", 50);
    defaultApiErrorHandler(tracks);
    if (tracks.data.items.length >= 2) {
        const ids = formatTracksToIds(tracks.data);

        return GET_AUDIO_FEATURES(authentication_key, ids)
            .then((response) => {
                defaultApiErrorHandler(response);
                return formatMoodSongs(response.data.audio_features, tracks.data.items);
            });
    } else {
        return { result: false }
    }
}


export async function getAudioFeaturesForMoodSongs(authentication_key, tracks) {
    const ids = formatTracksToIds(tracks);
    return GET_AUDIO_FEATURES(authentication_key, ids)
        .then((response) => {
            defaultApiErrorHandler(response);
            return response.data?.audio_features;
        })
}


export async function getVerdictAges(authentication_key) {
    return GET_TRACKS(authentication_key, "short_term", 50)
        .then((response) => {
            defaultApiErrorHandler(response);

            return formatVerdictAges(response.data.items);
        })
}


export async function getVerdictFestivalPoster(authentication_key) {
    return GET_ARTISTS(authentication_key, "short_term", 50)
        .then((response) => {
            defaultApiErrorHandler(response);

            return formatVerdictFestivalPoster(response.data.items);
        })
}

export async function getMoodColor(authentication_key, term) {
    const tracks = await GET_TRACKS(authentication_key, term, 50);
    defaultApiErrorHandler(tracks);
    const ids = formatTracksToIds(tracks.data);

    return GET_AUDIO_FEATURES(authentication_key, ids)
        .then((response) => {
            defaultApiErrorHandler(response);
            return formatMoodColor(formatAudioFeatures(response.data.audio_features));
        });
}


export async function getVerdictMoodColor(authentication_key) {
    const res = await getMoodColor(authentication_key, "short_term");
    if (res.result)
        return {
            color: res.mixedMethod.map((e) => { return e.color }),
            result: true
        }
    return {
        result: false
    }
}

export async function getRandomVerict(authentication_key) {
    const random = Math.ceil(Math.random() * 4);
    switch (random) {
        case 4: {
            const res = await getVerdictFestivalPoster(authentication_key);
            return {
                type: "FESTIVAL_POSTER",
                body: res
            }
        }
        case 3: {
            const res = await getVerdictAges(authentication_key);
            return {
                type: "AGES",
                body: res
            }
        }
        case 2: {
            const res = await getVerdictGenre(authentication_key);
            return {
                type: "GENRES",
                body: res
            }
        }
        case 1: {
            const res = await getVerdictMoodColor(authentication_key);
            return {
                type: "MOOD_COLOR",
                body: res
            }
        }
        default: {
            return {
                type: "NO_RESULTS",
                body: {
                    result: false
                }
            }
        }
    }
}


export async function getRecommendationss(authentication_key) {
    const tracks = await GET_TRACKS(authentication_key, "short_term", 50);
    defaultApiErrorHandler(tracks);

    const artists = await GET_ARTISTS(authentication_key, "short_term", 10);
    defaultApiErrorHandler(artists);

    const recentlyPlayed = await GET_RECENTLY_PLAYED(authentication_key, 1);
    defaultApiErrorHandler(recentlyPlayed);

    const dataForTrackBasedOnArtist = artists?.data?.items.length > 0 && tracks?.data?.items.length ? formatDataForRecommendationTrackBasedOnArtist(artists.data.items, tracks.data.items) : { result: false };
    const dataForTracksBasedOnTrack = recentlyPlayed?.data?.items[0]?.track ? formatDataForRecommendationTracksBasedOnTrack(recentlyPlayed.data.items[0].track) : { result: false };

    let formattedTrackBasedOnArtist;
    let formattedAlbumBasedOnTrack = tracks?.data?.items.length ? formatRecommendationAlbumBasedOnTrack(tracks.data.items) : { result: false };
    let formattedTracksBasedOnTrack;


    return GET_RECOMMENDATIONS(authentication_key, 10, dataForTrackBasedOnArtist)
        .then((TrackBasedOnArtist) => {
            defaultApiErrorHandler(TrackBasedOnArtist);
            if (TrackBasedOnArtist?.recommendationEmpty)
                formattedTrackBasedOnArtist = {
                    result: false
                }
            else
                formattedTrackBasedOnArtist = formatRecommendationTrackBasedOnArtist(TrackBasedOnArtist.data.tracks, dataForTrackBasedOnArtist.artist);

            return GET_RECOMMENDATIONS(authentication_key, 3, dataForTracksBasedOnTrack).then((TracksBasedOnTrack) => {
                defaultApiErrorHandler(TracksBasedOnTrack);
                if (TracksBasedOnTrack?.recommendationEmpty)
                    formattedTracksBasedOnTrack = {
                        result: false
                    }
                else
                    formattedTracksBasedOnTrack = {
                        track: recentlyPlayed?.data?.items[0]?.track ? recentlyPlayed.data.items[0].track : null,
                        tracks: TracksBasedOnTrack.data.tracks,
                        result: recentlyPlayed?.data?.items[0]?.track ? TracksBasedOnTrack.data.tracks ? true : false : false
                    };

                return {
                    trackBasedOnArtist: formattedTrackBasedOnArtist,
                    albumBasedOnTrack: formattedAlbumBasedOnTrack,
                    tracksBasedOnTrack: formattedTracksBasedOnTrack
                }
            })
        })
}

export async function getRecentlyPlayed(authentication_key) {
    const response = await GET_RECENTLY_PLAYED(authentication_key, 1);
    defaultApiErrorHandler(response);
    return response?.data;
}


export async function getRecommendations(authentication_key, count, data) {
    const response = await GET_RECOMMENDATIONS(authentication_key, count, data);
    defaultApiErrorHandler(response);
    return response;
}