//style
import "./LoginWindowStyles.scss"
//packages
import React from "react";
//components
import LoginButton from "../LoginButton/LoginButton";

function LoginWindow() {
    return (
        <div className="login-window">
            <LoginButton />
        </div>
    );
}

export default LoginWindow;