//style
import "./PatronsStyles.scss"
//packages
import React from "react";
//utils
import { metadataPatrons } from "../../../utils/pagesMetadata/metadataPatrons";
//components
import { SeoHelmet } from "../../general/SeoHelmet";
import { patreonSubscriptions } from "../../../utils/variables/patrons";

function Patrons() {
    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataPatrons

    return (
        <div className="patrons-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            <div className="patrons-block">
                <h1 className="page_title">This project is developed thanks to our sponsors</h1>
                <div className="patrons-wrapper">
                    {
                        patreonSubscriptions?.map((subscription) => {
                            const {
                                patrons,
                                color,
                                name
                            } = subscription
                            return (
                                <div className="patrons-subscription">
                                    <p style={{ color: color }} className="patrons-subscription-name">{name}s</p>
                                    {
                                        patrons?.length ?
                                            <div className="patrons-subscription-patrons_list">
                                                {
                                                    patrons.map((patron) => {
                                                        return (
                                                            <p>{patron}</p>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            <div className="patrons-subscription-get_status">
                                                <p className="patrons-subscription-get_status-header">You could be the first to support us as a {name?.toLowerCase()}, like no one did before!</p>
                                                <a
                                                    className="patrons-subscription-get_status-button"
                                                    href="https://patreon.com/melodystats"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    alt=""
                                                >
                                                    <div style={{ backgroundColor: color }}>
                                                        <p>Get {name} status</p>
                                                    </div>
                                                </a>
                                            </div>
                                    }
                                    {
                                        patrons?.length > 0 && patrons?.length < 10 ?
                                            <a
                                                className="patrons-subscription-join"
                                                href="https://patreon.com/melodystats"
                                                target="_blank"
                                                rel="noreferrer"
                                                alt=""
                                            >
                                                <div style={{ backgroundColor: color }}>
                                                    <p>Join {name}s</p>
                                                </div>
                                            </a>
                                            :
                                            null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default Patrons
