//style
import "./GenresStyles.scss"
//packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//utils
import { metadataGenres } from "../../../utils/pagesMetadata/metadataGenres";//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS, SET_TRACKS } from "../../../context/actions";
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import TermSwitcher from "../../common/TermSwitcher/TermSwitcher";
import Loading from "../../common/Loading/Loading";
import NotEnoughData from "../../common/NotEnoughData/NotEnoughData";
import { SeoHelmet } from "../../general/SeoHelmet";
import { useGenres } from "./hooks";


function Genres(props) {
    const [genresData, setGenresData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const { genres, loaded: genresLoaded, isNotEnoughData } = useGenres(props)
    useEffect(() => {
        if (!genresLoaded)
            setLoaded(false)
    }, [genresLoaded])

    useEffect(() => {
        if (genresLoaded && isNotEnoughData)
            setLoaded(true)
    }, [genresLoaded, isNotEnoughData])

    useEffect(() => {
        if (genres && genresLoaded && !isNotEnoughData) {
            let totalPopularityCount = 0
            genres.genres.forEach((e) => {
                totalPopularityCount += e.popularity
            })
            const genresFormatted = genres.genres.map((e) => {
                const popularityPercentage = e.popularity / totalPopularityCount * 100
                const result = {
                    ...e
                }
                if (popularityPercentage >= 0.1) {
                    const percentageNumber = Number.isInteger(popularityPercentage) ? popularityPercentage : parseFloat(popularityPercentage).toFixed(1)
                    result.percentageNumber = parseFloat(percentageNumber)
                    result.percentageNumberToShow = `${percentageNumber}`
                } else {
                    result.percentageNumber = 0.1
                    result.percentageNumberToShow = `< 0.1`
                }
                return result
            })
            //console.log('genresFormatted: ', genresFormatted)
            setGenresData(genresFormatted)
            setLoaded(true)
        }
    }, [genres, genresLoaded, isNotEnoughData])

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataGenres

    return (
        <div className="genres-page" >
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="genres-block">
                        <h1 className="page_title">Your top genres</h1>
                        <TermSwitcher />
                        {
                            loaded ?
                                <div className={`loading-able ${loaded ? "" : "loading-able-blur"}`}>
                                    {
                                        !isNotEnoughData ?
                                            props.windowWidth !== null && genresData ?
                                                <div className="wrapper">
                                                    <div className="genre-verdict">
                                                        <p>It seems that your mood genre is <span>{genres.genres[0].genre}</span>,<br /> with primary influence from
                                                            <a
                                                                href={`${genres.verdict?.external_urls?.spotify}?si`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <span>&nbsp;{genres.verdict?.name}</span>
                                                            </a>.</p>
                                                    </div>
                                                    {
                                                        genresData.map((e, i) => {
                                                            return (
                                                                <div key={i} className="genre-block">
                                                                    <p className="genre-name">
                                                                        {e.genre}
                                                                    </p>
                                                                    <div className="genre-bar-wrapper">
                                                                        <div className="genre-bar">
                                                                            <div className="genre-bar-filled" style={{ width: `${e.percentageNumber}%` }}>

                                                                            </div>
                                                                        </div>
                                                                        <p className="percentage">{`${e.percentageNumberToShow}%`}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <Loading />
                                            :
                                            <NotEnoughData />
                                    }
                                </div>
                                :
                                <Loading />
                        }
                    </div>
                    :
                    <LoginWindow />
            }
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth,
        artists: state.artists,
        tracks: state.tracks
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_ARTISTS, SET_TRACKS };
export default connect(mapStateToProps, mapDispatchToProps)(Genres);