//packages
import React, { useEffect, useRef, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { connect } from "react-redux";
//utils
import { removeHashFromURL } from "../../utils/hashRemover";
import { closeBlurBody, closeBlurMain } from "../../utils/contentBlur/contentBlur";
import { browserSupportSharingPngFile } from "../../utils/variables/navigator";
import { scopes } from "../../utils/variables/scopes";
//styles
import "./MenuSwitcherStyles.scss";
//store
import { MOBILE_MENU_SWITCH, MOBILE_MENU_RESET, KEY_NOT_NEED_TO_UPDATE, TERM_RESET, SET_AUTHENTICATION_KEY, SET_WINDOW_WIDTH, WINDOW_WIDTH_RESET, KEY_NEED_TO_UPDATE, CLOSE_BLUR, OPEN_BLUR, SET_CAN_BE_SHARED } from "../../context/actions";
//components
import Home from "../pages/Home/Home";
import Profile from "../pages/Profile/Profile";
import Artists from "../pages/Artists/Artists";
import Tracks from "../pages/Tracks/Tracks";
import AudioFeatures from "../pages/AudioFeatures/AudioFeatures";
import Poster from "../pages/Poster/Poster";
import Recommendations from "../pages/Recommendations/Recommendations";
import Palette from "../pages/Palette/Palette";
import Genres from "../pages/Genres/Genres";
import AgesOfPlaylist from "../pages/AgesOfPlaylist/AgesOfPlaylist";
import LastPlayedTracks from "../pages/LastPlayedTracks/LastPlayedTracks";
import Loading from "../common/Loading/Loading";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";
import MobileMenu from "../common/MobileMenu/MobileMenu";
import Iceberg from "../pages/Iceberg/Iceberg";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import YourMelodystats from "../pages/YourMelodystats/YourMelodystats";
import Patrons from "../pages/Patrons/Patrons";


function MenuSwitcher(props) {
    const windowLocation = window.location.origin

    // refresh user's auth token
    const [keyNeedToUpdateState, setKeyNeedToUpdateState] = useState(false);

    const CLIENT_ID = process.env.REACT_APP_API_KEY
    const REDIRECT_URI = window.location.pathname.split("/").pop() === "login" ? windowLocation : `${windowLocation}/` + window.location.pathname.split("/").pop();
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
    const RESPONSE_TYPE = "token";

    /* useEffect(() => {
        const hash = window.location.hash
        let token = props.authentication_key

        if (hash) {
            token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1];
            window.location.hash = "";
            //console.log(token);
            //props.KEY_NOT_NEED_TO_UPDATE()
            props.SET_AUTHENTICATION_KEY(token);
            removeHashFromURL();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.authentication_key])

    useEffect(() => {
        setKeyNeedToUpdateState(props.keyNeedToUpdate);
    }, [props.keyNeedToUpdate]);

    useEffect(() => {
        if (keyNeedToUpdateState === true)
            window.location.replace(`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=user-read-private%20user-read-email%20playlist-read-private%20user-read-playback-position%20user-read-recently-played%20user-library-read%20user-read-currently-playing%20user-read-playback-position%20user-top-read%20user-read-playback-state%20user-modify-playback-state`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyNeedToUpdateState]); */


    const updateToken = () => {
        const hash = window.location.hash
        if (hash) {
            const token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1];
            window.location.hash = "";
            props.SET_AUTHENTICATION_KEY(token);
        }
        removeHashFromURL();
    }

    useEffect(() => {
        updateToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.authentication_key, window.location.hash])

    useEffect(() => {
        setKeyNeedToUpdateState(props.keyNeedToUpdate);
    }, [props.keyNeedToUpdate]);

    useEffect(() => {
        if (keyNeedToUpdateState === true && !window.location.hash)
            window.location.replace(`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${scopes}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyNeedToUpdateState]);


    // handle prceses when tab closing
    useEffect(() => {
        const handleTabClose = () => {
            props.MOBILE_MENU_RESET();
            props.TERM_RESET();
            props.WINDOW_WIDTH_RESET();
            closeBlurMain();
            closeBlurBody();
        };

        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // set window width in store
    const updateDimensions = () => {
        props.SET_WINDOW_WIDTH(window.innerWidth);
        closeBlurMain()
        closeBlurBody()
    }

    useEffect(() => {
        updateDimensions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // handle proceses when URL changes
    const location = useLocation();
    useEffect(() => {
        props.MOBILE_MENU_RESET();
        //props.TERM_RESET();
        closeBlurMain();
        closeBlurBody();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const contentRef = useRef(null);

    const updateSupportSharing = (value) => {
        props.SET_CAN_BE_SHARED(value);
    }

    useEffect(() => {
        updateSupportSharing(browserSupportSharingPngFile());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { setPopupContent, setPopupType } = props

    return (
        <div className="main" ref={contentRef}>
            <div className="mobile_menu-wrapper" style={props.windowWidth < 1000 && props.mobileMenuOpen ? { "display": "flex" } : { "display": "none" }}>
                <MobileMenu />
            </div>
            <div>
                <div style={props.mobileMenuOpen ? { "display": "none" } : {}}>
                    {
                        !props.keyNeedToUpdate ?
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/artists" element={<Artists />} />
                                <Route path="/genres" element={<Genres />} />
                                <Route path="/tracks" element={<Tracks />} />
                                <Route path="/audio-features" element={<AudioFeatures setPopupContent={setPopupContent} setPopupType={setPopupType} />} />
                                <Route path="/last-played-tracks" element={<LastPlayedTracks />} />
                                <Route path="/ages-of-playlist" element={<AgesOfPlaylist setPopupContent={setPopupContent} setPopupType={setPopupType} />} />
                                <Route path="/iceberg" element={<Iceberg setPopupContent={setPopupContent} setPopupType={setPopupType} />} />
                                <Route path="/mood-palette" element={<Palette setPopupContent={setPopupContent} setPopupType={setPopupType} />} />
                                <Route path="/festival-poster" element={<Poster setPopupContent={setPopupContent} setPopupType={setPopupType} />} />
                                <Route path="/recommendations" element={<Recommendations setPopupContent={setPopupContent} setPopupType={setPopupType} />} />
                                <Route path="/your-melodystats" element={<YourMelodystats setPopupContent={setPopupContent} setPopupType={setPopupType} />} />
                                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                <Route path="/patrons" element={<Patrons />} />
                                <Route path="/404" element={<PageNotFound />} />
                                <Route path="*" element={<Navigate to="/404" />} />
                            </Routes>
                            :
                            <Loading />
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        mobileMenuOpen: state.mobileMenuOpen,
        keyNeedToUpdate: state.keyNeedToUpdate,
        authentication_key: state.authentication_key,
        windowWidth: state.windowWidth,
        openBlur: state.openBlur,
        canBeShared: state.canBeShared
    };
};

const mapDispatchToProps = { MOBILE_MENU_SWITCH, MOBILE_MENU_RESET, KEY_NOT_NEED_TO_UPDATE, TERM_RESET, SET_AUTHENTICATION_KEY, KEY_NEED_TO_UPDATE, SET_WINDOW_WIDTH, WINDOW_WIDTH_RESET, CLOSE_BLUR, OPEN_BLUR, SET_CAN_BE_SHARED };

export default connect(mapStateToProps, mapDispatchToProps)(MenuSwitcher);