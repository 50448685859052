export const supportSharing = navigator.canShare && navigator.share

export const browserSupportSharingPngFile = () => {
    if (!navigator.canShare)
        return false
    const imageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII'
    var byteString;
    if (imageBase64.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(imageBase64.split(',')[1]);
    else
        byteString = unescape(imageBase64.split(',')[1]);
    var mimeString = imageBase64.split(',')[0].split(':')[1].split(';')[0];
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const imageBlob = (new Blob([ia], { type: mimeString }))
    const imageFileToShare = new File([imageBlob], `test.png`, {
        type: "image/png",
    })
    const data = {
        files: [imageFileToShare]
    };

    if (navigator.canShare(data))
        return true
    else
        return false
}