export const SET_AUTHENTICATION_KEY = (key) => (dispatch) => {
    dispatch({ type: "SET_AUTHENTICATION_KEY", payload: key });
};

export const RESET = (item) => (dispatch) => {
    dispatch({ type: "RESET", payload: item });
};

export const LOGOUT = (item) => (dispatch) => {
    dispatch({ type: "LOGOUT", payload: item });
};

export const SWITCH_TERM = (term) => (dispatch) => {
    dispatch({ type: "SWITCH_TERM", payload: term });
};

export const MOBILE_MENU_SWITCH = () => (dispatch) => {
    dispatch({ type: "MOBILE_MENU_SWITCH", payload: null });
};

export const MOBILE_MENU_RESET = () => (dispatch) => {
    dispatch({ type: "MOBILE_MENU_RESET", payload: null });
};

export const KEY_NEED_TO_UPDATE = () => (dispatch) => {
    dispatch({ type: "KEY_NEED_TO_UPDATE", payload: null });
};

export const KEY_NOT_NEED_TO_UPDATE = () => (dispatch) => {
    dispatch({ type: "KEY_NOT_NEED_TO_UPDATE", payload: null });
};

export const TERM_RESET = () => (dispatch) => {
    dispatch({ type: "TERM_RESET", payload: null });
};

export const SET_WINDOW_WIDTH = (width) => (dispatch) => {
    dispatch({ type: "SET_WINDOW_WIDTH", payload: width });
};

export const WINDOW_WIDTH_RESET = () => (dispatch) => {
    dispatch({ type: "WINDOW_WIDTH_RESET", payload: null });
};

export const OPEN_BLUR = () => (dispatch) => {
    dispatch({ type: "OPEN_BLUR", payload: null });
};

export const CLOSE_BLUR = () => (dispatch) => {
    dispatch({ type: "CLOSE_BLUR", payload: null });
};

export const SET_CAN_BE_SHARED = (canBeShared) => (dispatch) => {
    dispatch({ type: "SET_CAN_BE_SHARED", payload: canBeShared });
};

export const PROMOTION_POPUP_SHOWED = () => (dispatch) => {
    dispatch({ type: "PROMOTION_POPUP_SHOWED", payload: null });
};

export const PROMOTION_POPUP_CLICKED = () => (dispatch) => {
    dispatch({ type: "PROMOTION_POPUP_CLICKED", payload: null });
};

export const RECOMMENDATION_POPUP_SHOWED = () => (dispatch) => {
    dispatch({ type: "RECOMMENDATION_POPUP_SHOWED", payload: null });
};

export const RECOMMENDATION_POPUP_CLICKED = () => (dispatch) => {
    dispatch({ type: "RECOMMENDATION_POPUP_CLICKED", payload: null });
};

export const SET_USER = (user) => (dispatch) => {
    dispatch({ type: "SET_USER", payload: user });
};

export const SET_ARTISTS = (artists) => (dispatch) => {
    dispatch({ type: "SET_ARTISTS", payload: artists });
};

export const SET_TRACKS = (tracks) => (dispatch) => {
    dispatch({ type: "SET_TRACKS", payload: tracks });
};

export const SET_AUDIO_FEATURES = (audioFeatures) => (dispatch) => {
    dispatch({ type: "SET_AUDIO_FEATURES", payload: audioFeatures });
};