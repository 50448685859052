//style
import "./PageNotFoundStyles.scss"
//packages
import React, { useEffect } from "react";
import { connect } from "react-redux";
// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT } from "../../../context/actions";
//utils
import { metadataPageNotFound } from "../../../utils/pagesMetadata/metadataPageNotFound";
//components
import { SeoHelmet } from "../../general/SeoHelmet";

export function PageNotFound(props) {

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataPageNotFound

    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerPageNotFound()
    }, [])

    return (
        <div className="not_found">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            <div className="not_enought_data-content">
                <p className="header">
                    404 Page not found
                </p>
                <Link to="/">
                    <p>
                        Navigate to the main page
                    </p>
                </Link>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.windowWidth
    };
};
const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT };
export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);