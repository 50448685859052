//style
import "./PopupStyles.scss"
//packages
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
//store
import { PROMOTION_POPUP_SHOWED, PROMOTION_POPUP_CLICKED, RECOMMENDATION_POPUP_SHOWED, RECOMMENDATION_POPUP_CLICKED } from "../../../context/actions";
//assets
import closeIcon from '../../../assets/icons/close-popup-icon.svg'
import handshakeEmoji from '../../../assets/emojis/64/handshake.png'
//utils
import { closeBlurBody, closeBlurMain, openBlurBody } from "../../../utils/contentBlur/contentBlur";


function Popup({ children, type, setPopupContent, setPopupType, infoHeading = "", PROMOTION_POPUP_SHOWED, PROMOTION_POPUP_CLICKED, RECOMMENDATION_POPUP_SHOWED, RECOMMENDATION_POPUP_CLICKED, promotionPopupShowedCount, promotionPopupClickedCount, promotionPopupLastTimeShowed, promotionPopupLastTimeClicked, recommendationPopupShowedCount, recommendationPopupClickedCount, recommendationPopupLastTimeShowed, recommendationPopupLastTimeClicked }) {
    const [closeImageLoaded, setCloseImageLoaded] = useState(false)
    const [popupVisible, setPopupVisible] = useState(false)
    const promotionPopupAllowed = !Boolean(process.env.REACT_APP_ENV === 'production')

    const handleOutsideClick = () => {
        setPopupVisible(false)
        closeBlurBody()
        setPopupContent(null)
        setPopupType("")
        closeBlurMain()
        setCloseImageLoaded(false)
    };
    function useClickOutside(handler) {
        const popupRef = useRef(null);
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (popupRef.current && !popupRef.current.contains(event.target)) {
                    handler();
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [handler]);

        return popupRef;
    }
    const popupRef = useClickOutside(handleOutsideClick);

    const handleGlobalClick = (event) => {
        const clickedElement = event.target;
        if (clickedElement.tagName === 'A') {
            handleOutsideClick()
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleGlobalClick);
        return () => {
            document.removeEventListener('click', handleGlobalClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        window.addEventListener('scroll', handleOutsideClick);
        return () => {
            window.removeEventListener('scroll', handleOutsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allowDisplay = () => {
        const currentTime = new Date().getTime()
        const lastRecord = Math.max(promotionPopupLastTimeShowed, recommendationPopupLastTimeShowed)
        const allowedTime = currentTime - 600000 //10min
        //const allowedTime = currentTime - 10000 //10sec
        const difference = allowedTime - lastRecord
        const timeElapsed = difference >= 0

        const lastTimeClicked = Math.max(promotionPopupLastTimeClicked, recommendationPopupLastTimeClicked)
        const allowedTimeClick = currentTime - 86400000 //day
        //const allowedTimeClick = currentTime - 30000 //30sec
        const differenceClick = allowedTimeClick - lastTimeClicked
        const timeElapsedClick = differenceClick >= 0

        let clicksAvailable = false
        if (type === 'promotion' && promotionPopupClickedCount < 2)
            clicksAvailable = true
        if (type === 'promotion_recommendation' && recommendationPopupClickedCount < 2)
            clicksAvailable = true

        return timeElapsed && timeElapsedClick && clicksAvailable
    }

    useEffect(() => {
        if (type?.length && children) {
            if (type === 'info') {
                openBlurBody()
                setPopupVisible(true)
            } else {
                if (allowDisplay() && promotionPopupAllowed) {
                    openBlurBody()
                    setPopupVisible(true)
                    if (type === 'promotion')
                        PROMOTION_POPUP_SHOWED()
                    if (type === 'promotion_recommendation')
                        RECOMMENDATION_POPUP_SHOWED()
                } else {
                    handleOutsideClick()
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, children])

    /*  useEffect(() => {
         if (popupVisible) {
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [popupVisible]) */

    const handleClickDonate = () => {
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerDonate()
    }

    const handlePromotionClick = () => {
        handleOutsideClick()
        PROMOTION_POPUP_CLICKED()
        handleClickDonate()
    }

    const handleRecommendationClick = () => {
        handleOutsideClick()
        RECOMMENDATION_POPUP_CLICKED()
        handleClickDonate()
    }


    return (
        popupVisible ?
            <div
                className="popup"
                ref={popupRef}
                style={closeImageLoaded ? {} : { opacity: 0, pointerEvents: 'none' }}
            >
                <div className="popup-wrapper">
                    <div className="close_button-zone">
                        <img src={closeIcon} alt="" onClick={() => handleOutsideClick()} onLoad={() => setCloseImageLoaded(true)} />
                    </div>
                    <div className="content-zone">
                        {
                            type === 'info' ?
                                <>
                                    <p className="primary-heading">Feature Info</p>
                                    <div className="description-block">
                                        {children}
                                    </div>
                                    <div className="okay_button" onClick={() => handleOutsideClick()}>
                                        <p>Got it!</p>
                                    </div>
                                </>
                                : null
                        }
                        {
                            type === 'promotion' ?
                                <>
                                    <p className="primary-heading">{children}</p>
                                    <div className="description-block">
                                        <p>Have some fun!</p>
                                        <p>Also, you can support us on patreon.</p>
                                    </div>
                                    <div className="promotion_button">
                                        <a onClick={() => handlePromotionClick()} href="https://patreon.com/melodystats" target="_blank" rel="noreferrer" alt="">
                                            <p>Support creators!</p>
                                            <img width="36px" height="36px" src={handshakeEmoji} alt="" />
                                        </a>
                                    </div>
                                </>
                                : null
                        }
                        {
                            type === 'promotion_recommendation' ?
                                <>
                                    <p className="primary-heading">{children}</p>
                                    <div className="promotion_button">
                                        <a onClick={() => handleRecommendationClick()} href="https://patreon.com/melodystats" target="_blank" rel="noreferrer" alt="">
                                            <p>Support creators!</p>
                                            <img width="36px" height="36px" src={handshakeEmoji} alt="" />
                                        </a>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                </div>
            </div>
            : null
    );
}


const mapStateToProps = (state) => {
    return {
        mobileMenuOpen: state.mobileMenuOpen,
        promotionPopupShowedCount: state.promotionPopupShowedCount,
        promotionPopupClickedCount: state.promotionPopupClickedCount,
        promotionPopupLastTimeShowed: state.promotionPopupLastTimeShowed,
        promotionPopupLastTimeClicked: state.promotionPopupLastTimeClicked,
        recommendationPopupShowedCount: state.recommendationPopupShowedCount,
        recommendationPopupClickedCount: state.recommendationPopupClickedCount,
        recommendationPopupLastTimeShowed: state.recommendationPopupLastTimeShowed,
        recommendationPopupLastTimeClicked: state.recommendationPopupLastTimeClicked,
    };
};

const mapDispatchToProps = { PROMOTION_POPUP_SHOWED, PROMOTION_POPUP_CLICKED, RECOMMENDATION_POPUP_SHOWED, RECOMMENDATION_POPUP_CLICKED };
export default connect(mapStateToProps, mapDispatchToProps)(Popup);