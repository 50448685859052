export function formatTracksToIds(tracks) {
    let ids = "";

    if (tracks?.items)
        tracks.items.forEach((track, key) => {
            if (key > 0) {
                ids = ids.concat("," + track.id);
            } else {
                ids = ids.concat(track.id);
            }
        })
    else
        tracks?.forEach((track, key) => {
            if (key > 0) {
                ids = ids.concat("," + track.id);
            } else {
                ids = ids.concat(track.id);
            }
        })
    return ids;
}


export function formatAudioFeatures(audioFeaturesArray) {
    const audioFeatures = audioFeaturesArray.filter((e) => e)
    if (audioFeatures.length === 0 || audioFeatures[0] === null)
        return []

    let allAudioFeatures = {
        acousticness: 0,
        danceability: 0,
        energy: 0,
        instrumentalness: 0,
        liveness: 0,
        loudness: 0,
        speechiness: 0,
        tempo: 0,
        valence: 0,
        itemCount: 0,
        mode: 0
    };

    audioFeatures.forEach((audioFeature, i) => {
        allAudioFeatures.acousticness += audioFeature.acousticness;
        allAudioFeatures.danceability += audioFeature.danceability;
        allAudioFeatures.energy += audioFeature.energy;
        allAudioFeatures.instrumentalness += audioFeature.instrumentalness;
        allAudioFeatures.liveness += audioFeature.liveness;
        allAudioFeatures.loudness += audioFeature.loudness;
        allAudioFeatures.speechiness += audioFeature.speechiness;
        allAudioFeatures.tempo += audioFeature.tempo;
        allAudioFeatures.valence += audioFeature.valence;
        allAudioFeatures.mode += audioFeature.mode;
        allAudioFeatures.itemCount++;
    });

    const round = (property) => {
        if (property / allAudioFeatures.itemCount >= 0.01 || property / allAudioFeatures.itemCount <= -0.01) {
            return Math.round(property / allAudioFeatures.itemCount * 100);
        }
        else if (property / allAudioFeatures.itemCount >= 0.001) {
            return 1;
        }
        else {
            return 0;
        }
    }


    var result = [
        {
            name: "Acousticness",
            val: round(allAudioFeatures.acousticness),
            min: 0,
            max: 100
        },

        {
            name: "Danceability",
            val: round(allAudioFeatures.danceability),
            min: 0,
            max: 100
        },

        {
            name: "Energy",
            val: round(allAudioFeatures.energy),
            min: 0,
            max: 100
        },

        {
            name: "Instrumentalness",
            val: round(allAudioFeatures.instrumentalness),
            min: 0,
            max: 100
        },

        {
            name: "Liveness",
            val: round(allAudioFeatures.liveness),
            min: 0,
            max: 100
        },

        {
            name: "Loudness",
            val: Math.ceil(Math.abs(round(allAudioFeatures.loudness) / 60)),
            min: 0,
            max: 100
        },

        {
            name: "Mode",
            val: round(allAudioFeatures.mode),
            min: 0,
            max: 100
        },

        {
            name: "Speechiness",
            val: round(allAudioFeatures.speechiness),
            min: 0,
            max: 100
        },

        {
            name: "Tempo",
            val: Math.round(allAudioFeatures.tempo / allAudioFeatures.itemCount),
            min: null,
            max: null
        },

        {
            name: "Valence",
            val: round(allAudioFeatures.valence),
            min: 0,
            max: 100
        }
    ]

    return result;
}

export function formatGenres(artists) {
    const items = JSON.parse(JSON.stringify(artists));
    let allGenres = [];

    items.forEach(item => {
        function foundGenre(genre) {
            let found = false;
            allGenres.forEach(element => {
                if (element.name === genre) {
                    found = true;
                    element.count++;
                }
            });
            return found;
        }

        item.genres.forEach(genre => {
            if (!foundGenre(genre)) {
                allGenres.push({
                    name: genre,
                    count: 1
                })
            }
        });
    });

    function compareCount(a, b) {
        if (a.count < b.count) {
            return 1;
        }
        if (a.count > b.count) {
            return -1;
        }
        return 0;
    }

    allGenres.sort(compareCount);

    let resultRank = [];

    allGenres.forEach((genre) => {
        const upperName = genre.name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        resultRank.push({
            genre: upperName,
            popularity: genre.count
        })
    })


    return resultRank;
}

export function formatVerdictForGenres(formattedGenres, artistsData, tracksData) {
    let filteredArtists = [];

    const artists = JSON.parse(JSON.stringify(artistsData))
    const tracks = JSON.parse(JSON.stringify(tracksData))

    artists.forEach(artist => {
        if (artist.genres.includes(formattedGenres[0].genre.toLowerCase())) {
            artist.score = 0;
            filteredArtists.push(artist);
        }
    });

    tracks.forEach(track => {
        track.artists.forEach(artist => {
            filteredArtists.forEach(filteredArtist => {
                if (filteredArtist.id === artist.id) {
                    filteredArtist.score += 1;
                }
            });
        });
    });

    function compareScore(a, b) {
        if (a.score < b.score) {
            return 1;
        }
        if (a.score > b.score) {
            return -1;
        }
        return 0;
    }

    filteredArtists.sort(compareScore);

    const result = filteredArtists[0]?.name ? { artist: filteredArtists[0], result: true } : { result: false }
    return result;
}


function getYear(date) {
    const year = new Date(date).getFullYear();
    return year;
}

function floorYearToDecade(date) {
    const year = getYear(date);
    const flooredYear = Math.floor(year / 10) * 10;
    return flooredYear;
}

const groupArrayElementsByValue = (inputArray) => {
    const groupedElements = {};
    for (const element of inputArray) {
        if (!groupedElements[element]) {
            groupedElements[element] = [];
        }
        groupedElements[element].push(element);
    }
    return Object.values(groupedElements);
}

export function formatPlaylistDecade(tracks) {
    const allDecades = [];

    tracks.forEach(track => {
        const decade = floorYearToDecade(track.album.release_date);
        allDecades.push(decade);
    });

    const groupedDecades = groupArrayElementsByValue(allDecades)

    function compareDecade(a, b) {
        if (a.length < b.length) {
            return 1;
        }
        if (a.length > b.length) {
            return -1;
        }
        return 0;
    }

    groupedDecades.sort(compareDecade);

    const result = groupedDecades[0][0] ? { decade: groupedDecades[0][0], result: true } : { result: false };
    return result;
}


export function formatAgeOfPlaylist(tracksData) {
    const allDecades = [];
    const tracks = JSON.parse(JSON.stringify(tracksData))

    tracks.forEach(track => {
        const decade = floorYearToDecade(track.album.release_date);
        allDecades.push(decade);
    });

    const groupedDecades = groupArrayElementsByValue(allDecades)

    let decadeCount = [];
    let totalCount = 0;

    groupedDecades.forEach(decade => {
        if (decade.length > 0) {
            decadeCount.push({
                decade: decade[0],
                count: decade.length
            });
            totalCount += decade.length;
        }
    });

    function compareDecade(a, b) {
        if (a.count < b.count) {
            return 1;
        }
        if (a.count > b.count) {
            return -1;
        }
        return 0;
    }

    groupedDecades.sort(compareDecade);

    decadeCount.forEach(decade => {
        decade["percentage"] = Math.floor(decade.count / totalCount * 100);
    });

    function compareTracksByPopularity(a, b) {
        if (a.popularity < b.popularity) {
            return 1;
        }
        if (a.popularity > b.popularity) {
            return -1;
        }
        return 0;
    }

    decadeCount.forEach(decade => {
        let decadeTracks = [];
        tracks.forEach(track => {
            if (floorYearToDecade(track.album.release_date) === decade.decade) {
                decadeTracks.push(track);
            }
        });
        decadeTracks.sort(compareTracksByPopularity);
        decade.track = decadeTracks[0];
    });

    return decadeCount.reverse();
}


export function formatIceberg(artistsData) {
    let sortedArtists = [[], [], [], [], [], [], []];
    const artists = JSON.parse(JSON.stringify(artistsData))

    artists.forEach(artist => {
        if (artist.popularity <= 15) {
            if (sortedArtists[0].length < 5) {
                sortedArtists[0].push(artist);
            }
        } else if (artist.popularity <= 30) {
            if (sortedArtists[1].length < 5) {
                sortedArtists[1].push(artist);
            }
        } else if (artist.popularity <= 45) {
            if (sortedArtists[2].length < 5) {
                sortedArtists[2].push(artist);
            }
        } else if (artist.popularity <= 60) {
            if (sortedArtists[3].length < 5) {
                sortedArtists[3].push(artist);
            }
        } else if (artist.popularity <= 75) {
            if (sortedArtists[4].length < 5) {
                sortedArtists[4].push(artist);
            }
        } else if (artist.popularity <= 90) {
            if (sortedArtists[5].length < 5) {
                sortedArtists[5].push(artist);
            }
        } else {
            if (sortedArtists[6].length < 5) {
                sortedArtists[6].push(artist);
            }
        }
    });

    return sortedArtists.reverse();
}

export function formatFestivalPoster(artistsData) {
    //let sortedArtists = [...artists.slice(0, 14)];
    const artists = JSON.parse(JSON.stringify(artistsData))
    let sortedArtists = [...artists];

    let formattedPoster = {
        rows: [],
        specialGuest: null,
        result: true
    };

    function compareArtistsByPopularity(a, b) {
        if (a.popularity < b.popularity) {
            return 1;
        }
        if (a.popularity > b.popularity) {
            return -1;
        }
        return 0;
    }

    sortedArtists.sort(compareArtistsByPopularity);

    if (sortedArtists.length < 8) {
        formattedPoster.result = false;
    } else if (sortedArtists.length < 15) {
        formattedPoster.rows.push(
            {
                headliner: sortedArtists[0],
                artists: sortedArtists.slice(2)
            }
        )
        formattedPoster.specialGuest = sortedArtists[1];
    } else if (sortedArtists.length < 22) {
        const halfCount = Math.round((sortedArtists.length - 3) / 2);

        formattedPoster.rows.push(
            {
                headliner: sortedArtists[0],
                artists: sortedArtists.slice(3, 3 + halfCount)
            },
            {
                headliner: sortedArtists[1],
                artists: sortedArtists.slice(3 + halfCount)
            }
        )
        formattedPoster.specialGuest = sortedArtists[2];
    } else {
        const thirdCount = Math.round((sortedArtists.length - 3) / 3);

        formattedPoster.rows.push(
            {
                headliner: sortedArtists[0],
                artists: sortedArtists.slice(4, 4 + thirdCount)
            },
            {
                headliner: sortedArtists[1],
                artists: sortedArtists.slice(4 + thirdCount, 4 + 2 * thirdCount)
            },
            {
                headliner: sortedArtists[2],
                artists: sortedArtists.slice(4 + 2 * thirdCount)
            }
        )
        formattedPoster.specialGuest = sortedArtists[3];
    }

    return formattedPoster;
}

export function formatLovedAlbum(tracks) {
    const allIds = [];

    tracks.forEach(track => {
        allIds.push(track.album.id);
    });

    const groupedIds = groupArrayElementsByValue(allIds);


    function compareSize(a, b) {
        if (a.length < b.length) {
            return 1;
        }
        if (a.length > b.length) {
            return -1;
        }
        return 0;
    }

    groupedIds.sort(compareSize);

    let result = {
        album: undefined,
        result: false
    };

    for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].album.id === groupedIds[0][0]) {
            if (tracks[i].album?.images[0]) {
                result.album = tracks[i].album;
                result.result = true;
                break;
            }
        }
    }
    return result;
}


export function formatMoodSongs(audioFeaturesArray, tracks) {
    const audioFeatures = audioFeaturesArray.filter((e) => e)
    function compareValence(a, b) {
        if (a.valence < b.valence) {
            return 1;
        }
        if (a.valence > b.valence) {
            return -1;
        }
        return 0;
    }

    audioFeatures.sort(compareValence);

    const happiestSong = tracks.find(track => track.id === audioFeatures[0].id);
    const saddestSong = tracks.find(track => track.id === audioFeatures[audioFeatures.length - 1].id);

    const result = {
        happiestSong: happiestSong,
        saddestSong: saddestSong,
        result: true
    }

    return result;
}


export function formatVerdictAges(tracks) {
    if (tracks.length) {
        const formatted = formatAgeOfPlaylist(tracks);
        const { decade, percentage } = formatted[formatted.length - 1];
        return {
            decade: decade,
            percentage: percentage,
            result: true
        }
    }
    return {
        result: false
    }
}

export function formatVerdictFestivalPoster(artistsData) {
    const artists = JSON.parse(JSON.stringify(artistsData))

    let sortedArtists = [...artists];

    let formattedPosterVerdict = {
        headliners: [],
        result: true
    };

    function compareArtistsByPopularity(a, b) {
        if (a.popularity < b.popularity) {
            return 1;
        }
        if (a.popularity > b.popularity) {
            return -1;
        }
        return 0;
    }

    sortedArtists.sort(compareArtistsByPopularity);

    if (sortedArtists.length < 8) {
        formattedPosterVerdict.result = false;
    } else if (sortedArtists.length < 15) {
        formattedPosterVerdict.headliners = (sortedArtists.slice(0, 1));
    } else if (sortedArtists.length < 22) {
        formattedPosterVerdict.headliners = (sortedArtists.slice(0, 2));
    } else {
        formattedPosterVerdict.headliners = (sortedArtists.slice(0, 3));
    }

    return formattedPosterVerdict;
}

export function formatVerdictMoodColor() {

}


export function formatDataForRecommendationTrackBasedOnArtist(artistsData, tracks) {
    const artists = JSON.parse(JSON.stringify(artistsData))?.slice(0, 10)

    let track = "";
    let artist = null;
    let artistId = null;

    let genre = null;

    for (let i = 5; i < artists.length; i++) {
        const id = artists[i].id;
        artist = artists[i]
        let trackCount = 0;

        for (let j = 0; j < tracks.length; j++) {
            if (tracks[j].artists.find(e => e.id === id) && trackCount < 3) {
                trackCount++;
                track += tracks[j].id + "%2C";
            }
        }

        if (track.split("").length > 0) {
            artistId = id;
            genre = artists[i].genres[0];
            track = track.split("").slice(0, track.length - 3).join("");
            break;
        }
    }

    const result = {
        seed_artists: artistId,
        seed_tracks: track,
        seed_genres: genre,
        artist: artist,
        result: true
    }

    return result;
}


export function formatRecommendationTrackBasedOnArtist(tracks, artist) {
    let result = {
        result: false,
        track: [],
        artist: artist
    }

    for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].artists.find(e => e.id === artist.id)) {
            result.result = true;
            result.track = tracks[i];
            break;
        }
    }

    return result;
}



export function formatRecommendationAlbumBasedOnTrack(tracks) {
    let result = {
        result: false,
        track: {},
        album: {}
    }

    for (let i = 5; i < tracks.length; i++) {
        if (tracks[i].album.album_type === "ALBUM") {
            result.result = true;
            result.track = tracks[i];
            result.album = tracks[i].album;
            break;
        }
    }

    return result;
}


export function formatDataForRecommendationTracksBasedOnTrack(track) {


    const result = {
        seed_artists: track?.artists[0].id,
        seed_tracks: track?.id,
        seed_genres: null,
        track: track,
        result: true
    }

    return result;
}


export function formatMoodColor(audioFeaturesData) {
    const audioFeatures = JSON.parse(JSON.stringify(audioFeaturesData))

    if (audioFeatures.length === 0)
        return {
            result: false
        }

    let formattedFeaturesToObject = {};
    audioFeatures.forEach((feature) => { formattedFeaturesToObject[feature.name] = feature.val; })

    const directScoreMethod = () => {
        const equalScore = () => {
            let colors = {
                Purple: 0,
                Green: 0,
                Pink: 0,
                Orange: 0,
                Yellow: 0,
                Blue: 0,
                Red: 0
            }
            const featureColorCreator = (name, low, medium, high, ultra, prior = false) => {
                return {
                    name: name,
                    low: low,
                    medium: medium,
                    high: high,
                    ultra: ultra,
                }
            }
            const featureColors = [
                featureColorCreator('Acousticness', 'Green', 'Yellow', 'Orange', 'Red'),
                featureColorCreator('Danceability', 'Red', 'Orange', 'Yellow', 'Green'),
                featureColorCreator('Energy', 'Red', 'Orange', 'Yellow', 'Green'),
                featureColorCreator('Instrumentalness', 'Blue', 'Green', 'Yellow', 'Purple'),
                featureColorCreator('Liveness', 'Red', 'Orange', 'Yellow', 'Green'),
                featureColorCreator('Loudness', 'Red', 'Orange', 'Yellow', 'Green'),
                featureColorCreator('Speechiness', 'Red', 'Orange', 'Yellow', 'Green'),
                featureColorCreator('Tempo', 'Red', 'Orange', 'Yellow', 'Green'),
                featureColorCreator('Valence', 'Red', 'Yellow', 'Green', 'Pink'),
            ]
            function stepScorer({ name, low, medium, high, ultra }) {
                const value = formattedFeaturesToObject[name];
                if (value < 25)
                    colors[low] += 1;
                else if (value < 50)
                    colors[medium] += 1;
                else if (value < 75)
                    colors[high] += 1;
                else if (value <= 100)
                    colors[ultra] += 1;
            }
            featureColors.forEach((e) => { stepScorer(e) })
            return colors
        }

        const priorScore = () => {
            let colors = {
                Purple: 0,
                Green: 0,
                Pink: 0,
                Orange: 0,
                Yellow: 0,
                Blue: 0,
                Red: 0
            }
            const featureColorCreator = (name, low, medium, high, ultra, prior = false) => {
                return {
                    name: name,
                    low: low,
                    medium: medium,
                    high: high,
                    ultra: ultra,
                    prior: prior
                }
            }
            const featureColors = [
                featureColorCreator('Acousticness', 'Green', 'Yellow', 'Orange', 'Red', true),
                featureColorCreator('Danceability', 'Red', 'Orange', 'Yellow', 'Green'),
                featureColorCreator('Energy', 'Red', 'Orange', 'Yellow', 'Green'),
                featureColorCreator('Instrumentalness', 'Blue', 'Green', 'Yellow', 'Purple'),
                featureColorCreator('Liveness', 'Red', 'Orange', 'Yellow', 'Green'),
                featureColorCreator('Loudness', 'Red', 'Orange', 'Yellow', 'Green', true),
                featureColorCreator('Speechiness', 'Red', 'Orange', 'Yellow', 'Green'),
                featureColorCreator('Tempo', 'Red', 'Orange', 'Yellow', 'Green', true),
                featureColorCreator('Valence', 'Red', 'Yellow', 'Green', 'Pink', true),
            ]
            function stepScorer({ name, low, medium, high, ultra, prior }) {
                const value = formattedFeaturesToObject[name];
                const coef = prior ? 2 : 1;
                if (value < 25)
                    colors[low] += 1 * coef;
                else if (value < 50)
                    colors[medium] += 1 * coef;
                else if (value < 75)
                    colors[high] += 1 * coef;
                else if (value <= 100)
                    colors[ultra] += 1 * coef;
            }
            featureColors.forEach((e) => { stepScorer(e) })
            return colors
        }

        const purityEqualScore = () => {
            let colors = {
                Purple: 0,
                Green: 0,
                Pink: 0,
                Orange: 0,
                Yellow: 0,
                Blue: 0,
                Red: 0
            }
            const featureColorCreator = (name, low, lowPurity, medium, mediumPurity, high, highPurity, ultra, ultraPurity) => {
                return {
                    name: name,
                    low: low,
                    lowPurity: lowPurity,
                    medium: medium,
                    mediumPurity: mediumPurity,
                    high: high,
                    highPurity: highPurity,
                    ultra: ultra,
                    ultraPurity: ultraPurity,
                }
            }
            const featureColors = [
                featureColorCreator('Acousticness', 'Orange', 1, 'Orange', 3, 'Yellow', 2, 'Green', 1),
                featureColorCreator('Danceability', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1),
                featureColorCreator('Energy', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1),
                featureColorCreator('Instrumentalness', 'Blue', 1, 'Blue', 2, 'Blue', 3, 'Purple', 1),
                featureColorCreator('Liveness', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1),
                featureColorCreator('Loudness', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1),
                featureColorCreator('Speechiness', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1),
                featureColorCreator('Tempo', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1),
                featureColorCreator('Valence', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Pink', 2),
            ]
            function stepScorer({ name, low, lowPurity, medium, mediumPurity, high, highPurity, ultra, ultraPurity }) {
                const value = formattedFeaturesToObject[name];
                if (value < 25)
                    colors[low] += 1 * lowPurity;
                else if (value < 50)
                    colors[medium] += 1 * mediumPurity;
                else if (value < 75)
                    colors[high] += 1 * highPurity;
                else if (value <= 100)
                    colors[ultra] += 1 * ultraPurity;
            }
            featureColors.forEach((e) => { stepScorer(e) })
            return colors
        }

        const purityPriorScore = () => {
            let colors = {
                Purple: 0,
                Green: 0,
                Pink: 0,
                Orange: 0,
                Yellow: 0,
                Blue: 0,
                Red: 0
            }
            const featureColorCreator = (name, low, lowPurity, medium, mediumPurity, high, highPurity, ultra, ultraPurity, prior = false) => {
                return {
                    name: name,
                    low: low,
                    lowPurity: lowPurity,
                    medium: medium,
                    mediumPurity: mediumPurity,
                    high: high,
                    highPurity: highPurity,
                    ultra: ultra,
                    ultraPurity: ultraPurity,
                    prior: prior
                }
            }
            const featureColors = [
                featureColorCreator('Acousticness', 'Orange', 1, 'Orange', 3, 'Yellow', 2, 'Green', 1, true),
                featureColorCreator('Danceability', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1),
                featureColorCreator('Energy', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1),
                featureColorCreator('Instrumentalness', 'Blue', 1, 'Blue', 2, 'Blue', 3, 'Purple', 1),
                featureColorCreator('Liveness', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1),
                featureColorCreator('Loudness', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1, true),
                featureColorCreator('Speechiness', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1),
                featureColorCreator('Tempo', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Red', 1, true),
                featureColorCreator('Valence', 'Green', 2, 'Yellow', 2, 'Orange', 3, 'Pink', 2, true)
            ]
            function stepScorer({ name, low, lowPurity, medium, mediumPurity, high, highPurity, ultra, ultraPurity, prior }) {
                const value = formattedFeaturesToObject[name];
                const coef = prior ? 2 : 1;
                if (value < 25)
                    colors[low] += 1 * lowPurity * coef;
                else if (value < 50)
                    colors[medium] += 1 * mediumPurity * coef;
                else if (value < 75)
                    colors[high] += 1 * highPurity * coef;
                else if (value <= 100)
                    colors[ultra] += 1 * ultraPurity * coef;
            }
            featureColors.forEach((e) => { stepScorer(e) })
            return colors
        }

        function sumObjectsByKey(...objs) {
            return objs.reduce((a, b) => {
                for (let k in b) {
                    if (b.hasOwnProperty(k))
                        a[k] = (a[k] || 0) + b[k];
                }
                return a;
            }, {});
        }

        const equalScoreResult = equalScore()
        const priorScoreResult = priorScore()
        const purityEqualScoreResult = purityEqualScore()
        const purityPriorScoreResult = purityPriorScore()

        return {
            equalScore: equalScoreResult,
            priorScore: priorScoreResult,
            purityEqualScore: purityEqualScoreResult,
            purityPriorScore: purityPriorScoreResult,
            total: sumObjectsByKey(equalScoreResult, priorScoreResult, purityEqualScoreResult, purityPriorScoreResult)
        }
    }


    const sensitiveSelectionMethod = () => {

        const compare = ({ less, feature, more }) => { return formattedFeaturesToObject[feature] >= less && formattedFeaturesToObject[feature] <= more }
        const featureColorCreator = (feature, less, more) => { return { less: less, feature: feature, more: more } }
        const colorSettingsCreator = (color, features) => { return { color: color, features: features } }

        const featureColors = [
            colorSettingsCreator('Orange',
                [
                    featureColorCreator('Acousticness', 50, 75),
                    featureColorCreator('Danceability', 50, 100),
                    featureColorCreator('Energy', 50, 100),
                    featureColorCreator('Instrumentalness', 25, 50),
                    featureColorCreator('Loudness', 5, 100),
                    featureColorCreator('Speechiness', 50, 100),
                    featureColorCreator('Tempo', 85, 160),
                    featureColorCreator('Valence', 50, 100)
                ]
            ),
            colorSettingsCreator('Yellow',
                [
                    featureColorCreator('Acousticness', 25, 75),
                    featureColorCreator('Danceability', 25, 75),
                    featureColorCreator('Energy', 25, 90),
                    featureColorCreator('Mode', 50, 100),
                    featureColorCreator('Speechiness', 25, 80),
                    featureColorCreator('Tempo', 85, 140),
                    featureColorCreator('Valence', 25, 80)
                ]
            ),
            colorSettingsCreator('Green',
                [
                    featureColorCreator('Acousticness', 70, 100),
                    featureColorCreator('Danceability', 50, 100),
                    featureColorCreator('Energy', 40, 80),
                    featureColorCreator('Instrumentalness', 75, 100),
                    featureColorCreator('Liveness', 50, 100),
                    featureColorCreator('Loudness', 0, 70),
                    featureColorCreator('Mode', 0, 100),
                    featureColorCreator('Tempo', 80, 130),
                    featureColorCreator('Valence', 50, 100)
                ]
            ),
            colorSettingsCreator('Blue',
                [
                    featureColorCreator('Danceability', 0, 50),
                    featureColorCreator('Energy', 0, 70),
                    featureColorCreator('Instrumentalness', 0, 50),
                    featureColorCreator('Loudness', 5, 100),
                    featureColorCreator('Mode', 0, 70),
                    featureColorCreator('Speechiness', 0, 40),
                    featureColorCreator('Tempo', 70, 120),
                    featureColorCreator('Valence', 20, 80)
                ]
            ),
            colorSettingsCreator('Pink',
                [
                    featureColorCreator('Danceability', 40, 80),
                    featureColorCreator('Energy', 60, 100),
                    featureColorCreator('Liveness', 60, 100),
                    featureColorCreator('Mode', 50, 100),
                    featureColorCreator('Tempo', 60, 130),
                    featureColorCreator('Valence', 75, 100)
                ]
            ),
            colorSettingsCreator('Purple',
                [
                    featureColorCreator('Acousticness', 60, 100),
                    featureColorCreator('Energy', 75, 100),
                    featureColorCreator('Liveness', 0, 80),
                    featureColorCreator('Loudness', 20, 100),
                    featureColorCreator('Mode', 60, 100),
                    featureColorCreator('Speechiness', 0, 60),
                    featureColorCreator('Tempo', 120, 160),
                    featureColorCreator('Valence', 75, 100)
                ]
            ),
            colorSettingsCreator('Red',
                [
                    featureColorCreator('Danceability', 75, 100),
                    featureColorCreator('Energy', 75, 100),
                    featureColorCreator('Instrumentalness', 75, 100),
                    featureColorCreator('Liveness', 60, 100),
                    featureColorCreator('Mode', 60, 100),
                    featureColorCreator('Speechiness', 15, 100),
                    featureColorCreator('Valence', 40, 100)
                ]
            ),
        ]

        let colors = {
            Purple: { perfect: false, percentage: 0 },
            Green: { perfect: false, percentage: 0 },
            Pink: { perfect: false, percentage: 0 },
            Orange: { perfect: false, percentage: 0 },
            Yellow: { perfect: false, percentage: 0 },
            Blue: { perfect: false, percentage: 0 },
            Red: { perfect: false, percentage: 0 }
        }

        featureColors.forEach((e) => {
            const validationFeatures = e.features.map((e) => { return compare(e) })
            const countTrue = (arr) => { return Math.round((arr.reduce((a, c) => a + c, 0) / arr.length) * 100) }

            if (validationFeatures.every((e) => e === true))
                colors[e.color].perfect = true

            colors[e.color].percentage = countTrue(validationFeatures)
        })

        return colors
    }

    const directScoreMethodResult = directScoreMethod()
    const sensitiveSelectionMethodResult = sensitiveSelectionMethod()
    const mixedMethodResult = () => {
        let mix = []
        for (const [key, value] of Object.entries(directScoreMethodResult.total)) {
            mix.push({
                color: key,
                score: value * sensitiveSelectionMethodResult[key].percentage
            })
        }

        function compareScore(a, b) {
            if (a.score < b.score) {
                return 1;
            }
            if (a.score > b.score) {
                return -1;
            }
            return 0;
        }

        mix.sort(compareScore)
        mix = mix.filter((e) => e.score > 0).slice(0, 2)

        return mix
    }

    return {
        directScoreMethod: directScoreMethodResult,
        sensitiveSelectionMethod: sensitiveSelectionMethodResult,
        mixedMethod: mixedMethodResult(),
        /*
         mixedMethod: [
            { color: 'Pink', score: 1000 },
            { color: 'Blue', score: 1000 },
        ],
        */
        formattedFeaturesToObject: formattedFeaturesToObject,
        result: true
    }
}
