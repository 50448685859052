//style
import './ShareStyles.scss';
//packages
import { React } from 'react';
import { domToPng } from 'modern-screenshot'
import { connect } from "react-redux";
//hooks
import { useFilename } from './hooks';
//utils
import { downloadImage } from '../../../utils/shareHelpers/shareHelpers';
//assets
import ShareIcon from '../../../assets/icons/share-icon.svg'
//store
import { KEY_NEED_TO_UPDATE, LOGOUT } from "../../../context/actions";


function Share(props) {
    const { shareRef, location, ready = true, canBeShared, promotionPopupAfterEnabled = false, setPopupContent, setPopupType, eventName = '' } = props
    const { filename } = useFilename(location)

    const handlePromotionPopupOpen = (content) => {
        if (promotionPopupAfterEnabled) {
            setPopupContent(content)
            setPopupType("promotion")
        }
    }

    const transformToImage = async (handleUrl) => {
        const shareRefWidth = shareRef.current.offsetWidth
        const shareRefHeight = shareRef.current.offsetHeight
        console.log('shareRefWidth: ', shareRefWidth)
        console.log('shareRefHeight: ', shareRefHeight)

        const options = {
            scale: 4
        }
        if (shareRefWidth && shareRefHeight) {
            options.width = shareRefWidth
            options.height = shareRefHeight
        }

        await domToPng(shareRef.current, options)
            .then((dataUrl) => {
                handleUrl(dataUrl)
            })
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTrigger(eventName)
    }

    const handleOnShare = async () => {
        transformToImage((dataUrl) => {
            var byteString;
            if (dataUrl.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataUrl.split(',')[1]);
            else
                byteString = unescape(dataUrl.split(',')[1]);
            var mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            const pngBlobDownload = new Blob([ia], { type: mimeString })
            const pngFileDownload = new File([pngBlobDownload], `${filename}.png`, {
                type: "image/png",
            })
            const downloadData = {
                files: [pngFileDownload]
            };
            try {
                navigator.share(downloadData).then(() => {
                    handlePromotionPopupOpen("You successfully shared image")
                })
            } catch (err) {
                console.error(err.name, err.message);
            }
        })

        /* domToPng(shareRef.current, { scale: 4 })
            .then((dataUrl) => {
                var byteString;
                if (dataUrl.split(',')[0].indexOf('base64') >= 0)
                    byteString = atob(dataUrl.split(',')[1]);
                else
                    byteString = unescape(dataUrl.split(',')[1]);
                var mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
                var ia = new Uint8Array(byteString.length);
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                const pngBlobDownload = new Blob([ia], { type: mimeString })
                const pngFileDownload = new File([pngBlobDownload], `${filename}.png`, {
                    type: "image/png",
                })
                const downloadData = {
                    files: [pngFileDownload]
                };
                try {
                    navigator.share(downloadData).then(() => {
                        handlePromotionPopupOpen("You successfully shared image")
                    })
                } catch (err) {
                    console.error(err.name, err.message);
                }
            })
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTrigger(eventName) */
    }

    const handleOnDownload = () => {
        transformToImage((dataUrl) => {
            downloadImage(dataUrl, filename)
            handlePromotionPopupOpen("You successfully shared image")
        })

        /* domToPng(shareRef.current, { scale: 4 })
            .then((dataUrl) => {
                downloadImage(dataUrl, filename)
                handlePromotionPopupOpen("You successfully shared image")
            })
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTrigger(eventName) */
    }

    return (
        <div className='block'>
            {
                ready ?
                    canBeShared ?
                        <button onClick={(e) => { handleOnShare(e) }}>
                            <img src={ShareIcon} alt="" width="40px" height="40px" />
                            <p>Share</p>
                        </button>
                        :
                        <button onClick={() => { handleOnDownload() }}>
                            <img src={ShareIcon} alt="" width="40px" height="40px" />
                            <p>Share</p>
                        </button>
                    :
                    <div className='loader'>
                        <div className="lds-ripple"><div></div><div></div></div>
                        <p>Loading...</p>
                    </div>
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        canBeShared: state.canBeShared,
    };
};
const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT };
export default connect(mapStateToProps, mapDispatchToProps)(Share);