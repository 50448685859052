export const colorsDescription = (colors) => {
    let result = '';
    if (colors[0].score >= 500 && colors[1].score >= 500) {
        const twoColors = colors.map((e) => { return e.color }).sort();
        const checkColors = (twoColors, templateColors) => {
            const join = new Set([...templateColors.colors, ...twoColors])
            return Array.from(join).length === 2
        };
        let pickColors = [
            {
                colors: ['Pink', 'Yellow'],
                description: 'Your fashion sense is playful and vibrant, incorporating bright and cheerful colors. You radiate positivity and have a warm and welcoming personality.'
            },
            {
                colors: ['Pink', 'Green'],
                description: 'Your music is lively and energetic, often used for staying active and connected to nature. You have a strong sense of environmentalism and a desire to protect the planet.'
            },
            {
                colors: ['Pink', 'Orange'],
                description: 'Your music is raw and authentic, often used for appreciating the simplicity and beauty of acoustic sounds. You are down-to-earth and value authenticity in your life.'
            },
            {
                colors: ['Pink', 'Purple'],
                description: 'Your music is intricate and imaginative, often used for exploring your creativity and finding beauty in art. You may be introspective and value your alone time.'
            },
            {
                colors: ['Pink', 'Blue'],
                description: 'Your music is complex and detail-oriented, often used for analyzing and understanding new ideas. You have a passion for learning and a desire to explore the world around you.'
            },
            {
                colors: ['Yellow', 'Green'],
                description: 'Your taste in music is lively and energetic, with a focus on nature and the environment. You have an adventurous and active personality, always seeking new experiences and challenges.'
            },
            {
                colors: ['Yellow', 'Orange'],
                description: 'Your fashion sense is bold and adventurous, incorporating bright and eye-catching hues. You have a fearless and confident personality, unafraid to take risks and pursue your passions.'
            },
            {
                colors: ['Yellow', 'Blue'],
                description: 'Your fashion sense is stylish and chic, incorporating cool and calming hues. You have a composed and confident personality, with a strong sense of individuality and personal style.'
            },
            {
                colors: ['Green', 'Orange'],
                description: 'Your fashion sense is bold and vibrant, with a focus on natural and earthy tones. You have a down-to-earth and practical personality, with a strong connection to nature and the environment.'
            },
            {
                colors: ['Green', 'Blue'],
                description: 'Your fashion sense is cool and classic, incorporating natural and earthy tones. You have a composed and confident personality, with a strong sense of individuality and personal style.'
            },
            {
                colors: ['Orange', 'Purple'],
                description: 'Your taste in music is eclectic and artistic, with a focus on complex and intricate compositions. You have a creative and imaginative personality, with a deep appreciation for beauty and aesthetics.'
            },
            {
                colors: ['Orange', 'Blue'],
                description: 'Your fashion sense is bold and eye-catching, incorporating bright and vibrant hues. You have a confident and outgoing personality, unafraid to make a statement and express yourself.'
            },
            {
                colors: ['Purple', 'Blue'],
                description: 'Your taste in music is sophisticated and intricate, with a focus on creative and imaginative compositions. You have a refined and cultured personality, with a deep appreciation for art and aesthetics.'
            },
            {
                colors: ['Red', 'Pink'],
                description: 'Your fashion sense is passionate and intense, incorporating bold and vibrant hues. You have a strong emotional connection to fashion and seek to express yourself through your clothing choices. You may be driven and ambitious in pursuing your goals and desires.'
            },
            {
                colors: ['Red', 'Yellow'],
                description: 'Your taste in music is high-energy and upbeat, often used for energizing and motivating your days. You are an outgoing and optimistic person, with a passion for socializing and connecting with others.'
            },
            {
                colors: ['Red', 'Green'],
                description: 'Your music is intense and passionate, often used for expressing your emotions and desires. You are a fiery and expressive person, with a strong sense of ambition and drive.'
            },
            {
                colors: ['Red', 'Orange'],
                description: 'Your fashion sense is bold and eye-catching, incorporating vibrant and passionate hues. You are a confident and outgoing person, unafraid to make a statement and express yourself.'
            },
            {
                colors: ['Red', 'Blue'],
                description: 'Your taste in music is intense and emotional, often used for eliciting a strong response and connecting with your innermost feelings. You may be introspective and reflective, with a deep understanding of your own emotions.'
            },
            {
                colors: ['Red', 'Purple'],
                description: 'Your taste in music is complex and intense, with a focus on intricate and imaginative compositions. You have a creative and artistic personality, with a deep appreciation for beauty and aesthetics.'
            },
            {
                colors: ['Purple', 'Yellow'],
                description: 'Your taste in music is creative and imaginative, with a focus on intricate and complex compositions. You have a curious and analytical personality, with a passion for exploring new ideas and perspectives.'
            },
            {
                colors: ['Purple', 'Green'],
                description: 'Your music is intricate and imaginative, often used for exploring your creativity and finding beauty in art. You may be introspective and value your alone time.'
            }
        ];
        pickColors = pickColors.map((e) => { return { colors: e.colors.sort(), description: e.description } });

        pickColors.forEach((c) => {
            if (checkColors(twoColors, c)) {
                result = c.description
            }
        })
    } else {
        const oneColor = colors[0].color
        const pickColor = {
            Purple: 'This color is associated with people who enjoy music with a high degree of instrumental complexity and variation. Your taste in music is creative and imaginative, often used for finding beauty in art. You may be introspective and value your alone time.',
            Green: 'This color represents those who are drawn to music that is lively, energetic, and loud. Your taste in music is adventurous and exciting, often used for exploring the great outdoors. You may have a strong sense of environmentalism and a desire to protect the planet.',
            Pink: 'Those who are drawn to pink may have a preference for music with a positive and uplifting mood. Your taste in music is empathetic and compassionate, often used for helping others and forming deep emotional connections. You may also value intimacy and connection in your relationships.',
            Orange: 'This color represents people who appreciate the raw and authentic sound of acoustic music. Your taste in music is down-to-earth and simple, often used for appreciating the beauty of natural sounds. You may be introspective and have a strong connection to nature.',
            Yellow: 'Those associated with this color are likely outgoing and energetic, with a love for danceable music. Your taste in music is high-energy and lively, often used for socializing and having fun with others. You may have an optimistic and positive outlook on life.',
            Blue: 'Those who are drawn to blue are likely to appreciate the intricate and complex nature of instrumental music, as well as music with a consistent tempo. Your taste in music is analytical and detail-oriented, often used for learning and exploring new ideas.',
            Red: 'This color represents those who are drawn to music that is intense, energetic, and passionate. Your taste in music is fiery and expressive, often used for eliciting a strong emotional response. You may be driven and ambitious in pursuing your desires and goals.'
        };
        result = pickColor[oneColor];
    }
    return result;
}