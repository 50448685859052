//style
import "./PaletteStyles.scss"
//packages
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
//utils
import { metadataMoodColor } from "../../../utils/pagesMetadata/metadataMoodColor";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_AUDIO_FEATURES } from "../../../context/actions";
import { colorsDescription } from "../../../utils/variables/colorsDescription";
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import TermSwitcher from "../../common/TermSwitcher/TermSwitcher";
import Loading from "../../common/Loading/Loading";
import NotEnoughData from "../../common/NotEnoughData/NotEnoughData";
import { MelodystatsBrand } from "../../common/MelodystatsBrand";
import Share from "../../common/Share/Share";
import { InfoButton } from "../../common/InfoButton";
import { Link } from "react-router-dom";
import { SeoHelmet } from "../../general/SeoHelmet";
import { usePalette } from "./hooks";
import { SpotifyBrand } from "../../common/SpotifyBrand";

export const hex = {
    Purple: '#7F00FF',
    Green: '#7CFC00',
    Pink: '#FF10F0',
    Orange: '#FF5F15',
    Yellow: '#FFFF00',
    Blue: '#0066FF',
    Red: '#C40233'
}

function GradientColor({ colors }) {
    function randomInteger(min, max) { return Math.floor(Math.random() * (max - min + 1)) + min; }
    const randType = () => { return randomInteger(0, 1) }

    const style = (colors) => {
        const color1 = hex[colors[0].color]
        const color2 = colors[1]?.color ? hex[colors[1].color] : ''

        return randType() === 1 ?
            colors[1]?.score >= 500 ?
                `radial-gradient(farthest-corner at ${randomInteger(30, 270)}px ${randomInteger(30, 270)}px, ${color1} ${randomInteger(0, 15)}%, ${color2} ${randomInteger(60, 100)}%)`
                :
                `radial-gradient(farthest-corner at ${randomInteger(30, 270)}px ${randomInteger(30, 270)}px, ${color1} ${randomInteger(0, 15)}%, ${color1} ${randomInteger(60, 100)}%)`
            :
            colors[1]?.score >= 500 ?
                `linear-gradient( ${randomInteger(0, 359)}deg, ${color1} ${randomInteger(0, 30)}%, ${color2} ${randomInteger(70, 100)}%)`
                :
                `linear-gradient( ${randomInteger(0, 359)}deg, ${color1} ${randomInteger(0, 30)}%, ${color1} ${randomInteger(70, 100)}%)`
    }

    const [currentColors, setCurrentColors] = useState(null)
    useEffect(() => {
        if (currentColors !== colors) {
            setCurrentColors(colors)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colors])

    const [currentGradient, setCurrentGradient] = useState(null)
    useEffect(() => {
        if (currentColors) {
            setCurrentGradient(style(currentColors))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentColors])

    return <div className="gradient-zone" style={colors && currentGradient ? { background: currentGradient } : {}} ></div>
}

export function Palette(props) {
    const { palette, loaded, isNotEnoughData } = usePalette(props)

    const shareRef = useRef(null)

    const [fontsLoaded, setFontsLoaded] = useState(false);
    useEffect(() => {
        const checkFonts = async () => {
            await document.fonts.ready;
            setFontsLoaded(true);
        };
        checkFonts();
    }, []);

    const [readyToBeShared, setReadyToBeShared] = useState(false);
    useEffect(() => {
        if (loaded && fontsLoaded)
            setReadyToBeShared(true)
    }, [loaded, fontsLoaded])

    const InfoDescriptionContent = () => {
        return (
            <>
                <p>Offers a unique way to explore the emotional aspects of your music collection. By analyzing parameters like acousticness, danceability, energy, and <Link to="/audio-features">more</Link>, we generate a description of your music taste and personality. It's actually about understanding how different types of music make you feel, as well as the emotional essence of your music collection.</p>
            </>
        )
    }

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataMoodColor

    return (
        <div className="palette-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="palette-block">
                        <h1 className="page_title">Mood color <InfoButton visible={loaded && !isNotEnoughData} popupContent={<InfoDescriptionContent />} setPopupContent={props.setPopupContent} setPopupType={props.setPopupType} /></h1>
                        <TermSwitcher />
                        {
                            loaded ?
                                <div className={`loading-able ${loaded ? "" : "loading-able-blur"}`}>
                                    {
                                        !isNotEnoughData ?
                                            props.windowWidth !== null && palette ?
                                                <div className="palette-wrapper">
                                                    {
                                                        props.windowWidth < 1000 ?
                                                            <div className="mobile">
                                                                <GradientColor colors={palette.mixedMethod} />
                                                                <p className="heading">
                                                                    {
                                                                        palette.mixedMethod[1]?.score >= 500 ?
                                                                            <span><span style={{ color: hex[palette.mixedMethod[0].color] }}>{palette.mixedMethod[0].color}</span> and <span style={{ color: hex[palette.mixedMethod[1].color] }}>{palette.mixedMethod[1].color}</span> are your mood colors</span>
                                                                            :
                                                                            <span><span style={{ color: hex[palette.mixedMethod[0].color] }}>{palette.mixedMethod[0].color}</span> are your mood color</span>
                                                                    }
                                                                </p>
                                                                <p className="description">{colorsDescription(palette.mixedMethod)}</p>
                                                            </div>
                                                            :
                                                            <div className="desktop">
                                                                <GradientColor colors={palette.mixedMethod} />
                                                                <p className="heading">
                                                                    {
                                                                        palette.mixedMethod[1]?.score >= 500 ?
                                                                            <span><span style={{ color: hex[palette.mixedMethod[0].color] }}>{palette.mixedMethod[0].color}</span> and <span style={{ color: hex[palette.mixedMethod[1].color] }}>{palette.mixedMethod[1].color}</span> are your mood colors</span>
                                                                            :
                                                                            <span><span style={{ color: hex[palette.mixedMethod[0].color] }}>{palette.mixedMethod[0].color}</span> are your mood color</span>
                                                                    }
                                                                </p>
                                                                <p className="description">{colorsDescription(palette.mixedMethod)}</p>
                                                            </div>
                                                    }
                                                    <div className="hidden_for_share">
                                                        {
                                                            props.windowWidth < 1000 ?
                                                                <div
                                                                    className="mobile sharable_content"
                                                                    ref={shareRef}
                                                                >
                                                                    <SpotifyBrand />
                                                                    <GradientColor colors={palette.mixedMethod} />
                                                                    <p className="heading">
                                                                        {
                                                                            palette.mixedMethod[1]?.score >= 500 ?
                                                                                <span><span style={{ color: hex[palette.mixedMethod[0].color] }}>{palette.mixedMethod[0].color}</span> and <span style={{ color: hex[palette.mixedMethod[1].color] }}>{palette.mixedMethod[1].color}</span> are your mood colors</span>
                                                                                :
                                                                                <span><span style={{ color: hex[palette.mixedMethod[0].color] }}>{palette.mixedMethod[0].color}</span> are your mood color</span>
                                                                        }
                                                                    </p>
                                                                    <p className="description">{colorsDescription(palette.mixedMethod)}</p>
                                                                    <MelodystatsBrand />
                                                                </div>
                                                                :
                                                                <div
                                                                    className="desktop sharable_content"
                                                                    ref={shareRef}
                                                                >
                                                                    <SpotifyBrand />
                                                                    <GradientColor colors={palette.mixedMethod} />
                                                                    <p className="heading">
                                                                        {
                                                                            palette.mixedMethod[1]?.score >= 500 ?
                                                                                <span><span style={{ color: hex[palette.mixedMethod[0].color] }}>{palette.mixedMethod[0].color}</span> and <span style={{ color: hex[palette.mixedMethod[1].color] }}>{palette.mixedMethod[1].color}</span> are your mood colors</span>
                                                                                :
                                                                                <span><span style={{ color: hex[palette.mixedMethod[0].color] }}>{palette.mixedMethod[0].color}</span> are your mood color</span>
                                                                        }
                                                                    </p>
                                                                    <p className="description">{colorsDescription(palette.mixedMethod)}</p>
                                                                    <MelodystatsBrand />
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="download-block ">
                                                        <Share
                                                            shareRef={shareRef}
                                                            location='Mood_Palette'
                                                            ready={readyToBeShared}
                                                            promotionPopupAfterEnabled={true}
                                                            setPopupContent={props.setPopupContent}
                                                            setPopupType={props.setPopupType}
                                                            eventName='share_palette'
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <Loading />
                                            :
                                            <NotEnoughData />
                                    }
                                </div>
                                :
                                <Loading />
                        }
                    </div>
                    :
                    <LoginWindow />
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth,
        audioFeatures: state.audioFeatures
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_AUDIO_FEATURES };

export default connect(mapStateToProps, mapDispatchToProps)(Palette);
