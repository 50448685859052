import { React } from 'react';
import './MelodystatsBrandStyles.scss'
import melodystatsLogoPNG from "../../../assets/branding/melodystats-logo-round.png"

export function MelodystatsBrand() {
    return (
        <div className="melodystats_branding">
            <p>Spotify stats and insights at <span>melodystats.me</span></p>
            <div className="logo">
                <div className="logo-image">
                    <img
                        src={melodystatsLogoPNG}
                        alt=""
                        width="30px"
                        height="30px"
                    />
                </div>
                <div className="naming">
                    <span>Melodystats</span>
                </div>
            </div>
        </div>
    );
}