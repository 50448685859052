const initialState = {
    authentication_key: null,
    keyNeedToUpdate: false,
    term: "short_term",
    mobileMenuOpen: false,
    windowWidth: null,
    openBlur: false,
    canBeShared: false,
    //popup algorithm
    promotionPopupShowedCount: 0,
    promotionPopupClickedCount: 0,
    promotionPopupLastTimeShowed: 0,
    promotionPopupLastTimeClicked: 0,
    recommendationPopupShowedCount: 0,
    recommendationPopupClickedCount: 0,
    recommendationPopupLastTimeShowed: 0,
    recommendationPopupLastTimeClicked: 0,
    //backup data
    user: null,
    userUpdatedTime: null,
    artists: { short_term: { value: null, updated: null }, medium_term: { value: null, updated: null }, long_term: { value: null, updated: null } },
    tracks: { short_term: { value: null, updated: null }, medium_term: { value: null, updated: null }, long_term: { value: null, updated: null } },
    audioFeatures: { short_term: { value: null, updated: null }, medium_term: { value: null, updated: null }, long_term: { value: null, updated: null } },
};

const getCurrentTimeStamp = () => new Date().getTime()

export const storeReducer = (state = initialState, action) => {
    switch (action.type) {

        case "SET_AUTHENTICATION_KEY":
            {
                return {
                    ...state,
                    keyNeedToUpdate: false,
                    authentication_key: action.payload
                };
            }

        case "RESET":
            {
                return {
                    ...state,
                    authentication_key: null
                };
            }

        case "LOGOUT":
            {
                return {};
            }

        case "KEY_NEED_TO_UPDATE":
            {
                console.log("KEY_NEED_TO_UPDATE");
                return {
                    ...state,
                    keyNeedToUpdate: true,
                };
            }

        case "KEY_NOT_NEED_TO_UPDATE":
            {
                let result;
                if (state.keyNeedToUpdate === true) {
                    result = {
                        ...state,
                        keyNeedToUpdate: false,
                    }
                } else {
                    result = {
                        ...state
                    }
                }
                return result;
            }

        case "SWITCH_TERM":
            {
                return {
                    ...state,
                    term: action.payload
                };
            }

        case "TERM_RESET":
            {
                return {
                    ...state,
                    term: "short_term"
                };
            }

        case "MOBILE_MENU_SWITCH":
            {
                const switched = !JSON.parse(JSON.stringify(state.mobileMenuOpen));

                return {
                    ...state,
                    mobileMenuOpen: switched
                };
            }

        case "MOBILE_MENU_RESET":
            {
                return {
                    ...state,
                    mobileMenuOpen: false
                };
            }

        case "SET_WINDOW_WIDTH":
            {
                return {
                    ...state,
                    windowWidth: action.payload
                };
            }

        case "WINDOW_WIDTH_RESET":
            {
                return {
                    ...state,
                    windowWidth: null
                };
            }

        case "OPEN_BLUR":
            {
                return {
                    ...state,
                    openBlur: true
                };
            }

        case "CLOSE_BLUR":
            {
                return {
                    ...state,
                    openBlur: false
                };
            }

        case "SET_CAN_BE_SHARED":
            {
                return {
                    ...state,
                    canBeShared: action.payload
                };
            }

        case "PROMOTION_POPUP_SHOWED":
            {
                const count = JSON.parse(JSON.stringify(state.promotionPopupShowedCount)) + 1;
                const timestamp = new Date().getTime()
                return {
                    ...state,
                    promotionPopupShowedCount: count,
                    promotionPopupLastTimeShowed: timestamp
                };
            }

        case "PROMOTION_POPUP_CLICKED":
            {
                const count = JSON.parse(JSON.stringify(state.promotionPopupClickedCount)) + 1;
                const timestamp = new Date().getTime()
                return {
                    ...state,
                    promotionPopupClickedCount: count,
                    promotionPopupLastTimeClicked: timestamp
                };
            }

        case "RECOMMENDATION_POPUP_SHOWED":
            {
                const count = JSON.parse(JSON.stringify(state.recommendationPopupShowedCount)) + 1;
                const timestamp = new Date().getTime()
                return {
                    ...state,
                    recommendationPopupShowedCount: count,
                    recommendationPopupLastTimeShowed: timestamp
                };
            }

        case "RECOMMENDATION_POPUP_CLICKED":
            {
                const count = JSON.parse(JSON.stringify(state.recommendationPopupClickedCount)) + 1;
                const timestamp = new Date().getTime()
                return {
                    ...state,
                    recommendationPopupClickedCount: count,
                    recommendationPopupLastTimeClicked: timestamp
                };
            }

        case "SET_USER":
            {
                const timestamp = getCurrentTimeStamp()
                return {
                    ...state,
                    user: action.payload,
                    userUpdatedTime: timestamp
                };
            }

        case "SET_ARTISTS":
            {
                const artists = JSON.parse(JSON.stringify(state.artists));
                artists[state.term] = { value: action.payload, updated: getCurrentTimeStamp() }

                return {
                    ...state,
                    artists: artists,
                };
            }

        case "SET_TRACKS":
            {
                const tracks = JSON.parse(JSON.stringify(state.tracks));
                tracks[state.term] = { value: action.payload, updated: getCurrentTimeStamp() }

                return {
                    ...state,
                    tracks: tracks,
                };
            }

        case "SET_AUDIO_FEATURES":
            {
                const audioFeatures = JSON.parse(JSON.stringify(state.audioFeatures));
                audioFeatures[state.term] = { value: action.payload, updated: getCurrentTimeStamp() }

                return {
                    ...state,
                    audioFeatures: audioFeatures,
                };
            }

        default:
            {
                return state;
            }
    }
};
