//style
import "./LoginButtonStyles.scss"
//packages
import React, { useEffect } from "react";
import { connect } from "react-redux";
//store
import { SET_AUTHENTICATION_KEY, RESET } from "../../../context/actions";
//assets
import Spotify_Icon_RGB_Black from "../../../assets/brands/Spotify_Icon_RGB_Black.png"
//utils
import { scopes } from "../../../utils/variables/scopes";


function LoginButton(props) {

    const windowLocation = window.location.origin

    const CLIENT_ID = process.env.REACT_APP_API_KEY
    const REDIRECT_URI = window.location.pathname.split("/").pop() === "login" ? windowLocation : `${windowLocation}/` + window.location.pathname.split("/").pop();
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
    const RESPONSE_TYPE = "token";

    useEffect(() => {
        const hash = window.location.hash
        let token = props.authentication_key

        if (hash) {
            token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1];
            window.location.hash = "";
            props.SET_AUTHENTICATION_KEY(token);
        }
    }, [props, props.authentication_key])

    const handleLogin = () => {
        if (process.env.REACT_APP_ENV === 'production')
            window?.eventTriggerLogin()
    }

    return (
        <div className="login-button">
            <div>
                <a
                    href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${scopes}`}
                    onClick={() => handleLogin()}
                >
                    <div className="login-link">
                        <p>Login with Spotify</p>
                        <img
                            src={Spotify_Icon_RGB_Black}
                            alt=""
                            width="30px"
                            height="30px"
                        />
                    </div>
                </a>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key
    };
};

const mapDispatchToProps = { SET_AUTHENTICATION_KEY, RESET };

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);