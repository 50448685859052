//packages
import { useEffect, useState } from "react";
//utils
import { printData } from "../../../utils/consoleHelpers";
import { handleErrorMessage } from "../../../utils/errorMessageHandler";
import { refreshPage, useNavigateHome } from "../../../utils/pageHandlers";
//services
import { getArtists } from "../../../services/appService";
import { formatIceberg } from "../../../services/dataFormatters";

export const useIceberg = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        term,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        artists,
        SET_ARTISTS
    } = props

    const [icebergDesktop, setIcebergDesktop] = useState(null);
    const [icebergMobile, setIcebergMobile] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const [artistsArray, setArtistsArray] = useState(null);
    const [artistsLoaded, setArtistsLoaded] = useState(false);
    const [isNotEnoughData, setNotEnoughData] = useState(false);
    const navigateHome = useNavigateHome();

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = artists[term]?.updated
            const recordValue = artists[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setLoaded(false)
                setArtistsLoaded(false)
                getArtists(authentication_key, term, 50)
                    .then(response => {
                        printData("Iceberg", response.items)
                        if (response.items.length) {
                            setArtistsArray(response.items)
                            SET_ARTISTS(response.items)
                        } else
                            setNotEnoughData(true)
                        setArtistsLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue.length)
                    setArtistsArray(JSON.parse(JSON.stringify(recordValue)));
                else
                    setNotEnoughData(true)
                setArtistsLoaded(true)
            }
        }
        return () => {
            setLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, term])

    useEffect(() => {
        if (artistsLoaded) {
            if (artistsArray?.length) {
                const formattedIceberg = formatIceberg(artistsArray)
                let i = 0
                for (const key in formattedIceberg) {
                    i += formattedIceberg[key].length
                }
                if (i) {
                    const desktop_array = [formattedIceberg[0]?.slice(0, 3), formattedIceberg[1]?.slice(0, 4), formattedIceberg[2], formattedIceberg[3], formattedIceberg[4]?.slice(0, 4), formattedIceberg[5]?.slice(0, 3), formattedIceberg[6]?.slice(0, 1)]
                    const mobile_array = [formattedIceberg[0]?.slice(0, 3), formattedIceberg[1]?.slice(0, 3), formattedIceberg[2]?.slice(0, 3), formattedIceberg[3]?.slice(0, 3), formattedIceberg[4]?.slice(0, 3), formattedIceberg[5]?.slice(0, 2), formattedIceberg[6]?.slice(0, 1)]
                    setIcebergDesktop(desktop_array)
                    setIcebergMobile(mobile_array)
                }
                else
                    setNotEnoughData(true)
            } else
                setNotEnoughData(true)
            setLoaded(true)
        }
    }, [artistsArray, artistsLoaded])

    return { icebergDesktop, icebergMobile, loaded, isNotEnoughData }
}