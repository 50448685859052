// user-read-playback-state
// user-read-currently-playing
// user-top-read
// user-read-recently-played
// user-read-email
// user-read-private


//export const restScopes = 'playlist-read-private%20user-read-playback-position%20user-library-read%20user-modify-playback-state'

const defaultScopes = 'user-read-playback-state%20user-read-currently-playing%20user-top-read%20user-read-recently-played%20user-read-email%20user-read-private%20'

export const scopes = defaultScopes