//style
import "./MainStyles.scss";
//packages
import React from "react";
import { connect } from "react-redux";
//components
import Header from "../common/Header/Header";
import MenuSwitcher from "./MenuSwitcher";
import { ScrollToTop } from "../general/ScrollToTop";
//import UpperModal from "../common/UpperModal/UpperModal";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT } from "../../context/actions";

function Main(props) {
    return (
        <div className="body">
            <ScrollToTop />
            {/* {
                process.env.REACT_APP_PROJECT_STAGE === 'testing' &&
                <UpperModal
                    text='Project on testing stage, you can not login without previous agreement!'
                    routes={['/']}
                    type='warn_testing'
                    hide={props.authentication_key ? true : false}
                />
            } */}
            <Header />
            <MenuSwitcher setPopupContent={props.setPopupContent} setPopupType={props.setPopupType} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT };

export default connect(mapStateToProps, mapDispatchToProps)(Main);
