//packages
import { useEffect, useState } from "react";
//utils
import { printData } from "../../../utils/consoleHelpers";
import { handleErrorMessage } from "../../../utils/errorMessageHandler";
import { refreshPage, useNavigateHome } from "../../../utils/pageHandlers";
//services
import { getArtists, getRecentlyPlayed, getRecommendations, getTracks } from "../../../services/appService";
import { formatDataForRecommendationTrackBasedOnArtist, formatDataForRecommendationTracksBasedOnTrack, formatRecommendationAlbumBasedOnTrack, formatRecommendationTrackBasedOnArtist } from "../../../services/dataFormatters";

export const useRecommendations = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        artists,
        SET_ARTISTS,
        tracks,
        SET_TRACKS,
    } = props

    const term = "short_term"
    const navigateHome = useNavigateHome();
    const [loaded, setLoaded] = useState(false);

    const [artistsArray, setArtistsArray] = useState(null);
    const [artistsLoaded, setArtistsLoaded] = useState(false);
    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = artists[term]?.updated
            const recordValue = artists[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setLoaded(false)
                setArtistsLoaded(false)
                getArtists(authentication_key, term, 50)
                    .then(response => {
                        printData("Recommendations.artists", response.items)
                        if (response.items.length) {
                            setArtistsArray(response.items)
                            SET_ARTISTS(response.items)
                        } else
                            setArtistsArray(null)
                        setArtistsLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue.length)
                    setArtistsArray(JSON.parse(JSON.stringify(recordValue)));
                else
                    setArtistsArray(null)
                setArtistsLoaded(true)
            }
        }
        return () => {
            setArtistsArray(null)
            setArtistsLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, term])

    const [tracksArray, setTracksArray] = useState(null);
    const [tracksLoaded, setTracksLoaded] = useState(false);
    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = tracks[term]?.updated
            const recordValue = tracks[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setLoaded(false)
                setTracksLoaded(false)
                getTracks(authentication_key, term, 50)
                    .then(response => {
                        printData("Recommendations.tracks", response.items)
                        if (response.items.length) {
                            setTracksArray(response.items)
                            SET_TRACKS(response.items)
                        } else
                            setTracksArray(null)
                        setTracksLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue.length)
                    setTracksArray(JSON.parse(JSON.stringify(recordValue)));
                else
                    setTracksArray(null)
                setTracksLoaded(true)
            }
        }
        return () => {
            setTracksArray(null)
            setTracksLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, term])

    const [recentlyPlayed, setRecentlyPlayed] = useState(null);
    const [recentlyPlayedLoaded, setRecentlyPlayedLoaded] = useState(false);
    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            setLoaded(false)
            setRecentlyPlayedLoaded(false)
            getRecentlyPlayed(authentication_key, term, 50)
                .then(response => {
                    printData("Recommendations.recentlyPlayed", response.items)
                    if (response?.items?.length && response?.items[0]?.track)
                        setRecentlyPlayed(response?.items[0]?.track)
                    else
                        setRecentlyPlayed(null)
                    setRecentlyPlayedLoaded(true)
                }).catch((error) => {
                    error.message = handleErrorMessage(error)
                    switch (error.message) {
                        case 401: {
                            KEY_NEED_TO_UPDATE();
                            break;
                        }
                        case 403: {
                            LOGOUT();
                            break;
                        }
                        case 429: {
                            refreshPage()
                            break;
                        }
                        default: {
                            navigateHome()
                            break;
                        }
                    }
                })
        }
        return () => {
            setRecentlyPlayed(null)
            setRecentlyPlayedLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, term])

    const [formattedTracksBasedOnTrack, setFormattedTracksBasedOnTrack] = useState(null)
    const [formattedTracksBasedOnTrackLoaded, setFormattedTracksBasedOnTrackLoaded] = useState(false)

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate && recentlyPlayed && recentlyPlayedLoaded) {
            const dataForTracksBasedOnTrack = recentlyPlayed ? formatDataForRecommendationTracksBasedOnTrack(recentlyPlayed) : { result: false };
            setLoaded(false)
            setFormattedTracksBasedOnTrackLoaded(false)
            getRecommendations(authentication_key, 3, dataForTracksBasedOnTrack)
                .then(response => {
                    printData("Recommendations.recommendations.tracksBasedOnTrack", response)
                    if (response?.recommendationEmpty)
                        setFormattedTracksBasedOnTrack({
                            result: false
                        })
                    else
                        setFormattedTracksBasedOnTrack({
                            track: recentlyPlayed ? recentlyPlayed : null,
                            tracks: response.data.tracks,
                            result: recentlyPlayed ? response.data.tracks ? true : false : false
                        })
                    setFormattedTracksBasedOnTrackLoaded(true)
                }).catch((error) => {
                    error.message = handleErrorMessage(error)
                    switch (error.message) {
                        case 401: {
                            KEY_NEED_TO_UPDATE();
                            break;
                        }
                        case 403: {
                            LOGOUT();
                            break;
                        }
                        case 429: {
                            refreshPage()
                            break;
                        }
                        default: {
                            navigateHome()
                            break;
                        }
                    }
                })
        }
        return () => {
            setFormattedTracksBasedOnTrack(null)
            setFormattedTracksBasedOnTrackLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentlyPlayed, recentlyPlayedLoaded, authentication_key])

    const [formattedTrackBasedOnArtist, setFormattedTrackBasedOnArtist] = useState(null)
    const [formattedTrackBasedOnArtistLoaded, setFormattedTrackBasedOnArtistLoaded] = useState(false)

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate && artistsArray && artistsLoaded && tracksArray && tracksLoaded) {
            const dataForTrackBasedOnArtist = artistsArray?.length > 0 && tracksArray.length ? formatDataForRecommendationTrackBasedOnArtist(artistsArray, tracksArray) : { result: false };

            setLoaded(false)
            setFormattedTrackBasedOnArtistLoaded(false)
            getRecommendations(authentication_key, 10, dataForTrackBasedOnArtist)
                .then(response => {
                    printData("Recommendations.recommendations.trackBasedOnArtist", response)
                    if (response?.recommendationEmpty)
                        setFormattedTrackBasedOnArtist({
                            result: false
                        })
                    else
                        setFormattedTrackBasedOnArtist(formatRecommendationTrackBasedOnArtist(response.data.tracks, dataForTrackBasedOnArtist.artist))
                    setFormattedTrackBasedOnArtistLoaded(true)
                }).catch((error) => {
                    error.message = handleErrorMessage(error)
                    switch (error.message) {
                        case 401: {
                            KEY_NEED_TO_UPDATE();
                            break;
                        }
                        case 403: {
                            LOGOUT();
                            break;
                        }
                        case 429: {
                            refreshPage()
                            break;
                        }
                        default: {
                            navigateHome()
                            break;
                        }
                    }
                })
        }
        return () => {
            setFormattedTrackBasedOnArtist(null)
            setFormattedTrackBasedOnArtistLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, artistsArray, artistsLoaded, tracksArray, tracksLoaded])

    const [formattedAlbumBasedOnTrack, setFormattedAlbumBasedOnTrack] = useState(null)
    const [formattedAlbumBasedOnTrackLoaded, setFormattedAlbumBasedOnTrackLoaded] = useState(false)

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate && tracksArray && tracksLoaded) {
            setFormattedAlbumBasedOnTrack(tracksArray.length ? formatRecommendationAlbumBasedOnTrack(tracksArray) : { result: false })
            setFormattedAlbumBasedOnTrackLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, tracksArray, tracksLoaded])

    const [recommendations, setRecommendations] = useState(null)
    const [isNotEnoughData, setNotEnoughData] = useState(false);

    useEffect(() => {
        if (artistsLoaded && tracksLoaded && recentlyPlayedLoaded && formattedTracksBasedOnTrackLoaded && formattedTrackBasedOnArtistLoaded && formattedAlbumBasedOnTrackLoaded) {
            const resultRecommendations = {
                trackBasedOnArtist: formattedTrackBasedOnArtist,
                albumBasedOnTrack: formattedAlbumBasedOnTrack,
                tracksBasedOnTrack: formattedTracksBasedOnTrack
            }

            let i = false
            for (const key in resultRecommendations) {
                if (resultRecommendations[key]?.result)
                    i = true
            }
            if (i)
                setRecommendations(resultRecommendations);
            else
                setNotEnoughData(true)
            setLoaded(true)
        } else if (artistsLoaded && tracksLoaded && recentlyPlayedLoaded) {
            if (!(artistsArray?.length || tracksArray?.length || recentlyPlayed)) {
                setNotEnoughData(true)
            }
            setLoaded(true)
        }
        return () => {
            setRecommendations(null)
            setLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [artistsLoaded, tracksLoaded, recentlyPlayedLoaded, formattedTracksBasedOnTrackLoaded, formattedTrackBasedOnArtistLoaded, formattedAlbumBasedOnTrackLoaded, formattedTrackBasedOnArtist, formattedAlbumBasedOnTrack, formattedTracksBasedOnTrack])

    return { recommendations, loaded, isNotEnoughData }
}