//packages
import { useEffect, useState } from "react";
//utils
import { printData } from "../../../utils/consoleHelpers";
import { handleErrorMessage } from "../../../utils/errorMessageHandler";
import { refreshPage, useNavigateHome } from "../../../utils/pageHandlers";
//services
import { getAudioFeatures } from "../../../services/appService";
import { formatMoodColor } from "../../../services/dataFormatters";

export const usePalette = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        term,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        audioFeatures,
        SET_AUDIO_FEATURES,
    } = props

    const [palette, setPalette] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [isNotEnoughData, setNotEnoughData] = useState(false);

    const [audioFeaturesArray, setAudioFeaturesArray] = useState(null);
    const [audioFeaturesLoaded, setAudioFeaturesLoaded] = useState(false);
    const navigateHome = useNavigateHome();

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = audioFeatures[term]?.updated
            const recordValue = audioFeatures[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setAudioFeaturesLoaded(false)
                getAudioFeatures(authentication_key, term)
                    .then(response => {
                        printData("Audio Features", response.items)
                        if (response.length) {
                            setAudioFeaturesArray(response)
                            SET_AUDIO_FEATURES(response)
                        } else
                            setNotEnoughData(true)
                        setAudioFeaturesLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue.length)
                    setAudioFeaturesArray(JSON.parse(JSON.stringify(recordValue)));
                else
                    setNotEnoughData(true)
                setAudioFeaturesLoaded(true)
            }
        }
        return () => {
            setAudioFeaturesArray(null)
            setAudioFeaturesLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, term])

    useEffect(() => {
        if (audioFeaturesLoaded) {
            if (audioFeaturesArray?.length) {
                const formattedPalette = formatMoodColor(audioFeaturesArray)
                printData("Palette", formattedPalette)
                if (formattedPalette.result)
                    setPalette(formattedPalette);
                else
                    setNotEnoughData(true)
            } else {
                setNotEnoughData(true)
            }
            setLoaded(true)
        }
    }, [audioFeaturesArray, audioFeaturesLoaded])

    return { palette, loaded, isNotEnoughData }
}