import archivePNG from '../../assets/emojis/32/archive.png'
import housePNG from '../../assets/emojis/32/house.png'
import icePNG from '../../assets/emojis/32/ice.png'
import microphonePNG from '../../assets/emojis/32/microphone.png'
import newsPNG from '../../assets/emojis/32/news.png'
import picturePNG from '../../assets/emojis/32/picture.png'
import profilePNG from '../../assets/emojis/32/profile.png'
import statsPNG from '../../assets/emojis/32/stats.png'

import archiveWEBP from '../../assets/emojis/32/archive.webp'
import houseWEBP from '../../assets/emojis/32/house.webp'
import iceWEBP from '../../assets/emojis/32/ice.webp'
import microphoneWEBP from '../../assets/emojis/32/microphone.webp'
import newsWEBP from '../../assets/emojis/32/news.webp'
import pictureWEBP from '../../assets/emojis/32/picture.webp'
import profileWEBP from '../../assets/emojis/32/profile.webp'
import statsWEBP from '../../assets/emojis/32/stats.webp'


export const navigationOptionsMobile = [
    {
        name: "Home",
        route: "/",
        isDropdown: false,
        emojiWEBP: houseWEBP,
        emojiPNG: housePNG,
        options: []
    },
    {
        name: "Your Melodystats",
        route: "/your-melodystats",
        isDropdown: false,
        emojiWEBP: archiveWEBP,
        emojiPNG: archivePNG,
        options: []
    },
    {
        name: "Statistics",
        route: "",
        isDropdown: true,
        emojiWEBP: statsWEBP,
        emojiPNG: statsPNG,
        options: [
            {
                name: "Artists",
                route: "/artists",
            },
            {
                name: "Tracks",
                route: "/tracks",
            },
            {
                name: "Genres",
                route: "/genres",
            },
            {
                name: "Audio features",
                route: "/audio-features",
            },
            {
                name: "Last played tracks",
                route: "/last-played-tracks",
            },
            {
                name: "Ages of playlist",
                route: "/ages-of-playlist",
            },
        ]
    },
    {
        name: "Recommendations",
        route: "/recommendations",
        isDropdown: false,
        emojiWEBP: newsWEBP,
        emojiPNG: newsPNG,
        options: []
    },
    {
        name: "Your Iceberg",
        route: "/iceberg",
        isDropdown: false,
        emojiWEBP: iceWEBP,
        emojiPNG: icePNG,
        options: []
    },
    {
        name: "Mood palette",
        route: "/mood-palette",
        isDropdown: false,
        emojiWEBP: pictureWEBP,
        emojiPNG: picturePNG,
        options: []
    },
    {
        name: "Festival poster",
        route: "/festival-poster",
        isDropdown: false,
        emojiWEBP: microphoneWEBP,
        emojiPNG: microphonePNG,
        options: []
    },
    {
        name: "Your profile",
        route: "/profile",
        isDropdown: false,
        emojiWEBP: profileWEBP,
        emojiPNG: profilePNG,
        options: []
    }
]

export const navigationOptionsDesktop = [
    {
        name: "Your Melody",
        route: "/your-melodystats",
        isDropdown: false,
        options: []
    },
    {
        name: "Statistics",
        route: "statistics",
        isDropdown: true,
        options: [
            {
                name: "Artists",
                route: "/artists",
            },
            {
                name: "Tracks",
                route: "/tracks",
            },
            {
                name: "Genres",
                route: "/genres",
            },
            {
                name: "Audio features",
                route: "/audio-features",
            },
            {
                name: "Last played tracks",
                route: "/last-played-tracks",
            },
            {
                name: "Ages of playlist",
                route: "/ages-of-playlist",
            },
        ]
    },
    {
        name: "Recommendations",
        route: "/recommendations",
        isDropdown: false,
        options: []
    },
    {
        name: "Iceberg",
        route: "/iceberg",
        isDropdown: false,
        options: []
    },
    {
        name: "Palette",
        route: "/mood-palette",
        isDropdown: false,
        options: []
    },
    {
        name: "Poster",
        route: "/festival-poster",
        isDropdown: false,
        options: []
    }
]

