//style
import "./PrivacyPolicyStyles.scss"
//packages
import React from "react";
import { connect } from "react-redux";
//utils
import { metadataPrivacyPolicy } from "../../../utils/pagesMetadata/metadataPrivacyPolicy";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT } from "../../../context/actions";
//components
import { Link } from "react-router-dom";
import { SeoHelmet } from "../../general/SeoHelmet";


export function PrivacyPolicy(props) {
    function PrivacyContent() {

        const handleContactUsClick = () => {
            if (process.env.REACT_APP_ENV === 'production')
                window?.eventTriggerContactUs()
        }

        return (
            <div className="privacy-content">
                <b>
                    Effective Date: {new Date().toISOString().split('T')[0].slice(-10).replace('-', '/')}
                </b>
                <p>
                    This Privacy Policy ("Policy") describes how Melodystats ("Company," "we," "us," or "our") collects, uses, and protects the personal information of users ("you" or "your") when you access and use the Melodystats website ("Service"). We are committed to protecting your privacy and ensuring the security of your personal information. By using the Service, you consent to the practices described in this Policy.
                </p>

                <div className="heading">
                    1. Information We Collect
                </div>
                <p>
                    a. Personal Information: When you use the Service, we may collect personal information that you voluntarily provide, such as your name, email address, and any other information you choose to provide.
                </p>
                <p>
                    b. Usage Information: We may collect information about your interactions with the Service, including your IP address, browser type, operating system, referring URLs, and pages visited. This information is collected through cookies and similar technologies.
                </p>
                <p>
                    c. Spotify Data: In order to provide you with statistical information and analysis related to music on the Spotify platform, we may collect and process publicly available data from Spotify's APIs. This data may include information about your music preferences, playlists, and other relevant information associated with your Spotify account.
                </p>

                <div className="heading">
                    2. Use of Information
                </div>
                <p>
                    a. Providing and Improving the Service: We use the collected information to provide and improve the Service, including generating insights and reports based on the Spotify data. We may also use the information to personalize your experience, troubleshoot issues, and respond to your inquiries.
                </p>
                <p>
                    b. Communication: We may use your email address to send you service-related notifications, updates, and promotional materials. You can opt-out of receiving promotional emails by following the instructions in the email or contacting us directly.
                </p>
                <p>
                    c. Aggregated Data: We may aggregate and anonymize the collected information to create statistical data and insights. This aggregated data does not identify you personally and may be used for various purposes, such as research, analytics, and marketing.
                </p>

                <div className="heading">
                    3. Information Sharing
                </div>
                <p>
                    a. Third-Party Service Providers: We may engage third-party service providers to perform certain functions on our behalf, such as hosting, data analysis, and customer support. These service providers have access to your personal information only to the extent necessary to perform their services and are obligated to maintain its confidentiality.
                </p>
                <p>
                    b. Legal Compliance: We may disclose your personal information if required by law, regulation, legal process, or governmental request. We may also disclose information to protect the rights, property, and safety of Melodystats, its users, or others.
                </p>

                <div className="heading">
                    4. Data Security
                </div>
                <p>
                    We implement reasonable security measures to protect the personal information we collect from unauthorized access, disclosure, alteration, and destruction. However, please be aware that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                </p>

                <div className="heading">
                    5. Third-Party Links
                </div>
                <p>
                    The Service may contain links to third-party websites or services that are not controlled or operated by Melodystats. This Policy does not apply to such third-party websites or services. We encourage you to review the privacy policies of those third parties before providing any personal information.
                </p>

                <div className="heading">
                    6. Children's Privacy
                </div>
                <p>
                    The Service is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13 without parental consent, we will take steps to delete that information.
                </p>

                <div className="heading">
                    7. Changes to the Privacy Policy
                </div>
                <p>
                    We reserve the right to update or modify this Policy at any time. Any changes will be effective upon posting the updated Policy on the Service. Your continued use of the Service after the posting of any modifications constitutes your acceptance of the revised Policy.
                </p>

                <div className="heading">
                    8. Contact Us
                </div>
                <p>
                    If you have any questions, concerns, or requests regarding this Privacy Policy or the privacy practices of Melodystats, please contact us at <a onClick={() => handleContactUsClick()} href="mailto:contact@melodystats.me" target="_blank" rel="noopener noreferrer">contact@melodystats.me</a>.
                </p>
                <p>
                    By using the Melodystats website, you acknowledge that you have read, understood, and agreed to be bound by this Privacy Policy.
                </p>
                <b>
                    Last updated: {new Date('05/25/2023').toISOString().split('T')[0].slice(-10).replace('-', '/')}
                </b>
            </div>
        )
    }

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataPrivacyPolicy

    return (
        <div className="privacy-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            <div className="privacy-block">
                <h1 className="page_title">Privacy Policy</h1>
                <Link to='/terms-and-conditions' className="default-link">
                    <p>Back to Terms & Conditions</p>
                </Link>
                <div>
                    {
                        props.windowWidth !== null ?
                            <div>
                                {
                                    props.windowWidth < 1000 ?
                                        <div className="mobile">
                                            <PrivacyContent />
                                        </div>
                                        :
                                        <div className="desktop">
                                            <PrivacyContent />
                                        </div>
                                }
                            </div>
                            :
                            null
                    }
                </div>

            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT };

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
