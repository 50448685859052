//style
import "./TracksStyles.scss"
//packages
import React from "react";
import { connect } from "react-redux";
//utils
import { metadataTracks } from "../../../utils/pagesMetadata/metadataTracks";
//store
import { KEY_NEED_TO_UPDATE, LOGOUT, SET_TRACKS } from "../../../context/actions";
//components
import LoginWindow from "../../common/LoginWindow/LoginWindow";
import TermSwitcher from "../../common/TermSwitcher/TermSwitcher";
import Loading from "../../common/Loading/Loading";
import NotEnoughData from "../../common/NotEnoughData/NotEnoughData";
import { SeoHelmet } from "../../general/SeoHelmet";
import { useTracks } from "./hooks";
import { TracksTable } from "../../components/TracksTable";

export function Tracks(props) {
    const { tracksArray: tracks, loaded, isNotEnoughData } = useTracks(props)

    const { title: seoTitle, description: seoDescription, schema: seoSchema } = metadataTracks

    return (
        <div className="tracks-page">
            <SeoHelmet
                title={seoTitle}
                description={seoDescription}
                schema={seoSchema}
            />
            {
                props.authentication_key ?
                    <div className="tracks-block">
                        <h1 className="page_title">Your top tracks</h1>
                        <TermSwitcher />
                        {
                            !isNotEnoughData ?
                                loaded ?
                                    <div className={`loading-able ${loaded ? "" : "loading-able-blur"}`}>
                                        <TracksTable tracksArray={tracks} />
                                    </div>
                                    :
                                    <Loading />
                                :
                                <NotEnoughData />
                        }
                    </div>
                    :
                    <LoginWindow />
            }
        </div>
    );
}



const mapStateToProps = (state) => {
    return {
        authentication_key: state.authentication_key,
        keyNeedToUpdate: state.keyNeedToUpdate,
        term: state.term,
        windowWidth: state.windowWidth,
        tracks: state.tracks
    };
};

const mapDispatchToProps = { KEY_NEED_TO_UPDATE, LOGOUT, SET_TRACKS };

export default connect(mapStateToProps, mapDispatchToProps)(Tracks);
