//packages
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
//utils
import { printData } from "../../../utils/consoleHelpers";
import { handleErrorMessage } from "../../../utils/errorMessageHandler";
import { refreshPage, useNavigateHome } from "../../../utils/pageHandlers";
//services
import { getTracks } from "../../../services/appService";

export const useTracks = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        term,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        tracks,
        SET_TRACKS
    } = props

    const [tracksArray, setTracksArray] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [isNotEnoughData, setNotEnoughData] = useState(false);
    const navigateHome = useNavigateHome();

    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = tracks[term]?.updated
            const recordValue = tracks[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setLoaded(false)
                getTracks(authentication_key, term, 50)
                    .then(response => {
                        printData("Tracks", response.items)
                        if (response.items.length) {
                            setTracksArray(response.items)
                            SET_TRACKS(response.items)
                        } else
                            setNotEnoughData(true)
                        setLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue.length)
                    setTracksArray(JSON.parse(JSON.stringify(recordValue)));
                else
                    setNotEnoughData(true)
                setLoaded(true)
            }
        }
        return () => {
            setTracksArray(null)
            setLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, term])

    return { tracksArray, loaded, isNotEnoughData }
}