//packages
import { useEffect, useState } from "react";
//utils
import { printData } from "../../../utils/consoleHelpers";
import { handleErrorMessage } from "../../../utils/errorMessageHandler";
import { refreshPage, useNavigateHome } from "../../../utils/pageHandlers";
//services
import { getArtists, getTracks } from "../../../services/appService";
import { formatGenres, formatVerdictForGenres } from "../../../services/dataFormatters";

export const useGenres = (props) => {
    const {
        authentication_key,
        keyNeedToUpdate,
        term,
        KEY_NEED_TO_UPDATE,
        LOGOUT,
        artists,
        SET_ARTISTS,
        tracks,
        SET_TRACKS,
    } = props

    const [isNotEnoughData, setNotEnoughData] = useState(false);
    const navigateHome = useNavigateHome();
    const [genres, setGenres] = useState(null)
    const [loaded, setLoaded] = useState(false);

    const [artistsArray, setArtistsArray] = useState(null);
    const [artistsLoaded, setArtistsLoaded] = useState(false);
    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = artists[term]?.updated
            const recordValue = artists[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setLoaded(false)
                setArtistsLoaded(false)
                getArtists(authentication_key, term, 50)
                    .then(response => {
                        printData("Genres.artists", response.items)
                        if (response.items.length) {
                            setArtistsArray(response.items)
                            SET_ARTISTS(response.items)
                        } else {
                            setNotEnoughData(true)
                            setLoaded(true)
                        }
                        setArtistsLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue.length)
                    setArtistsArray(JSON.parse(JSON.stringify(recordValue)));
                else
                    setNotEnoughData(true)
                setArtistsLoaded(true)
            }
        }
        return () => {
            setArtistsArray(null)
            setArtistsLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, term])

    const [tracksArray, setTracksArray] = useState(null);
    const [tracksLoaded, setTracksLoaded] = useState(false);
    useEffect(() => {
        if (authentication_key && !keyNeedToUpdate) {
            const currentTime = new Date().getTime()
            const allowedTime = currentTime - 600000 //10min
            const lastTimeUpdated = tracks[term]?.updated
            const recordValue = tracks[term]?.value
            const recordValid = recordValue?.length
            const difference = allowedTime - lastTimeUpdated
            const timeElapsed = difference >= 0
            const needToUpdate = !lastTimeUpdated || !recordValid || timeElapsed

            if (needToUpdate) {
                setLoaded(false)
                setTracksLoaded(false)
                getTracks(authentication_key, term, 50)
                    .then(response => {
                        printData("Genres.tracks", response.items)
                        if (response.items.length) {
                            setTracksArray(response.items)
                            SET_TRACKS(response.items)
                        } else {
                            setNotEnoughData(true)
                            setLoaded(true)
                        }
                        setTracksLoaded(true)
                    }).catch((error) => {
                        error.message = handleErrorMessage(error)
                        switch (error.message) {
                            case 401: {
                                KEY_NEED_TO_UPDATE();
                                break;
                            }
                            case 403: {
                                LOGOUT();
                                break;
                            }
                            case 429: {
                                refreshPage()
                                break;
                            }
                            default: {
                                navigateHome()
                                break;
                            }
                        }
                    })
            } else {
                if (recordValue.length)
                    setTracksArray(JSON.parse(JSON.stringify(recordValue)));
                else
                    setNotEnoughData(true)
                setTracksLoaded(true)
            }
        }
        return () => {
            setTracksArray(null)
            setTracksLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication_key, term])

    useEffect(() => {
        if (artistsLoaded && tracksLoaded) {
            if (tracksArray?.length && artistsArray?.length) {
                const formattedGenres = formatGenres(artistsArray);
                const verdict = formatVerdictForGenres(formattedGenres, artistsArray, tracksArray);
                setGenres({
                    genres: formattedGenres,
                    verdict: verdict.result ? verdict.artist : null,
                    result: true
                })
            } else {
                setNotEnoughData(true)
                setGenres({
                    result: false
                })
            }
            setLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [artistsArray, artistsLoaded, tracksArray, tracksLoaded])

    return { genres, loaded, isNotEnoughData }
}