//style
import "./LoadingStyles.scss"
//packages
import React from "react"

export default function Loading() {
    return (
        <div className="loading">
            <div className="loading-content">
                <p>Loading...</p>
            </div>
        </div>
    );
}
